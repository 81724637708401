import { FormControlLabel, Switch } from '@material-ui/core';
import { Edit, SupervisedUserCircle, PersonAdd } from '@material-ui/icons';
import BlockIcon from '@material-ui/icons/Block';
import { MaterialTableProps } from 'material-table';
import React, { Suspense, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import Submenu from '../../../../components/Layout/Menus/Submenu/Submenu';
import TableLayout from '../../../../components/Layout/TableLayout';
import TableWithEdit from '../../../../components/Tables/TableWithEdit';
import { permissionType } from '../../../../constants/permissions_definitions';
import colorPalette from '../../../../helpers/colorPalette';
import { exportToExcel } from '../../../../helpers/exportToXslx';
import { tKey } from '../../../../helpers/translate';
import { AuthUserData } from '../../../../redux/auth/definitions';
import { selectIsNousCims, selectUserInfo } from '../../../../redux/auth/selectors';
import { UserStates } from '../../../../redux/common/definitions';
import { showSnackbar } from '../../../../redux/FeedbackAPI/actions';
import { SeveritySnackbar } from '../../../../redux/FeedbackAPI/definitions';
import { selectPermissionsList } from '../../../../redux/permissions/selectors';
import { AppState } from '../../../../redux/root-reducer';
import { disableUserAction, enableUserAction, setUserCreated } from '../../../../redux/user/actions';
import { RoleGroups, UserItemList, UserType } from '../../../../redux/user/definitions';
import { selectUserCreated, selectUsersListReload } from '../../../../redux/user/selectors';
import UserRolesModal from '../../UserRoles/UserRolesModal';
import { userOptionsSubmenu } from '../userOptionsMenu';
import styles from './usersList.module.scss';
import { getUsersByTypeFromApi } from '../../../../api/user';
import FormContainer from '../../../../components/Forms/FormContainer';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

interface UsersStaffProps extends RouteComponentProps<{ type?: UserType }> {
  permissions: permissionType[];
  userInfo: AuthUserData;
  isNousCims: boolean;
  reload: boolean;
  disableUser: (id: number) => void;
  enableUser: (id: number) => void;
  setUserCreated: (userCreated: boolean) => void;
  showSnackbar: (message: string, severity: SeveritySnackbar, route?: string, time?: number) => void;
}

const actionIcons = {
  BlockIcon: () => <BlockIcon style={{ color: colorPalette.secondary80 }} />,
  Roles: () => <SupervisedUserCircle style={{ color: colorPalette.secondary80 }} />,
  Edit: () => <Edit style={{ color: colorPalette.secondary80 }} />,
  EnableIcon: () => <PersonAdd style={{ color: colorPalette.secondary80 }} />,
};

const UsersStaff: React.FC<UsersStaffProps> = ({
  permissions,
  userInfo,
  isNousCims,
  reload,
  disableUser,
  enableUser,
  showSnackbar,
  setUserCreated,
}) => {
  const groupType = RoleGroups.NOUSCIMS;
  const history = useHistory();
  const [userRolesConfig, setUserRolesConfig] = useState({ view: false, user_id: 0 });
  const [loading, setLoading] = useState(false);
  const [showDisabled, setShowDisabled] = useState(false);
  const [userListData, setUserListData] = useState<UserItemList[]>([]);
  const canConfigUserRoles = permissions.includes('allow_config_user_roles');

  const onEditOne = (rowData: UserItemList) => {
    if (!Array.isArray(rowData) && rowData) {
      history.push({
        pathname: `/usuario/editar/${rowData.id}`,
        state: { id: rowData.id, type: groupType },
      });
    }
  };

  const showRoles = (rowData: UserItemList) => {
    setUserRolesConfig({ view: true, user_id: rowData.id });
  };

  const onDisableOne = (rowData: UserItemList) => {
    if (!Array.isArray(rowData) && rowData) {
      if (userInfo.user_id === rowData.id) {
        return showSnackbar(tKey('No puedes deshabilitar tu usuario'), 'warning', undefined, 1500);
      }
      disableUser(rowData.id);
    }
  };

  const onEnableUser = (rowData: UserItemList) => {
    enableUser(rowData.id);
  };

  const staffUsersList = (): UserItemList[] => {
    if (showDisabled) {
      return userListData;
    }
    return userListData.filter((userItemList: UserItemList) => userItemList.state !== UserStates.DISABLED);
  };

  const selectDisabledCellCSS = (rowData: UserItemList): CSSProperties => {
    if (rowData.state === UserStates.DISABLED) {
      return {
        color: '#00000074',
      } as CSSProperties;
    }
    return {} as CSSProperties;
  };

  const tableColumns = [
    {
      title: tKey('Nombre'),
      field: 'name',
      width: '200px',
      cellStyle: (data: UserItemList[], rowData: UserItemList) => selectDisabledCellCSS(rowData),
    },
    {
      title: tKey('Apellidos'),
      field: 'surname',
      cellStyle: (data: UserItemList[], rowData: UserItemList) => selectDisabledCellCSS(rowData),
    },
    {
      title: tKey('ip.rol'),
      field: 'role',
      width: '250px',
      cellStyle: (data: UserItemList[], rowData: UserItemList) => selectDisabledCellCSS(rowData),
    },
    {
      title: tKey('Correo electrónico'),
      field: 'email',
      cellStyle: (data: UserItemList[], rowData: UserItemList) => selectDisabledCellCSS(rowData),
    },
    {
      title: tKey('Teléfono'),
      field: 'phone',
      width: '150px',
      cellStyle: (data: UserItemList[], rowData: UserItemList) => selectDisabledCellCSS(rowData),
    },
    {
      title: tKey('users.document_number'),
      field: 'document_number',
      width: '150px',
      cellStyle: (data: UserItemList[], rowData: UserItemList) => selectDisabledCellCSS(rowData),
    },
  ];

  const tableData: MaterialTableProps<UserItemList> = {
    title: tKey('users.staff'),
    columns: tableColumns,
    options: {
      tableLayout: 'auto',
      pageSize: 100,
      pageSizeOptions: [20, 50, 100],
      exportButton: true,
      exportCsv: (columns, data) => {
        exportToExcel(columns, data, `Usuarios_Staff`);
      },
    },
    data: staffUsersList(),
    isLoading: loading,
    actions: [
      (rowData: UserItemList) => ({
        icon: actionIcons.Edit,
        tooltip: tKey('Editar'),
        onClick: () => onEditOne(rowData),
        hidden: rowData.state === UserStates.DISABLED,
      }),
      (rowData: UserItemList) => ({
        icon: actionIcons.Roles,
        tooltip: tKey('Roles'),
        onClick: () => showRoles(rowData),
        hidden: !canConfigUserRoles || rowData.state === UserStates.DISABLED,
      }),
      (rowData: UserItemList) =>
        rowData.state === UserStates.DISABLED
          ? {
              icon: actionIcons.EnableIcon,
              tooltip: tKey('Habilitar'),
              onClick: () => onEnableUser(rowData),
            }
          : {
              icon: actionIcons.BlockIcon,
              tooltip: tKey('Deshabilitar'),
              onClick: () => onDisableOne(rowData),
            },
    ],
  };

  const optionsSubmenu = userOptionsSubmenu({
    currentType: 'staff',
    permissions,
    isNousCims,
  });

  useEffect(() => {
    if (reload) {
      (async function fetchData() {
        setLoading(true);
        setUserListData([]);
        try {
          const { data } = await getUsersByTypeFromApi('staff');
          setUserListData(data);
        } catch (e) {
          showSnackbar(tKey('No se han podido traer los usuarios'), 'error');
        }
        setLoading(false);
      })();
    }
  }, [reload, showSnackbar]);

  const goToNewUser = () => {
    setUserCreated(false);
    history.push({ pathname: '/usuario/crear', state: { type: groupType } });
  };

  const details = (rowData: UserItemList) => {
    history.push({
      pathname: `/usuario/detalles/${rowData.id}`,
      state: { id: rowData.id, type: groupType },
    });
  };

  return (
    <>
      <TableLayout leftSubmenu={<Submenu titleMenu={tKey('Usuarios')} optionsMenu={optionsSubmenu} defaultOpen />}>
        <FormContainer title="" className={styles.switch}>
          <FormControlLabel
            control={
              <Switch
                checked={showDisabled}
                onChange={event => setShowDisabled(event.target.checked)}
                color="primary"
              />
            }
            labelPlacement="start"
            label={'Mostrar deshabilitados'}
          />
        </FormContainer>
        <FormContainer title="" className={styles.usersTable}>
          <TableWithEdit
            tableData={tableData}
            exportButton
            filtering
            onRowClick={(rowData: UserItemList) => details(rowData)}
            key={JSON.stringify(tableData.data)}
            permission={true}
            optionsNew={{ newButtonText: tKey('NUEVO USUARIO'), onNew: goToNewUser }}
          />
        </FormContainer>
      </TableLayout>
      <Suspense fallback={<></>}>
        {userRolesConfig.view && (
          <UserRolesModal
            view={userRolesConfig.view}
            user_id={userRolesConfig.user_id}
            handleClose={() => {
              setUserRolesConfig({ view: false, user_id: 0 });
            }}
          />
        )}
      </Suspense>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  permissions: selectPermissionsList(state),
  userInfo: selectUserInfo(state),
  userCreated: selectUserCreated(state),
  isNousCims: selectIsNousCims(state),
  reload: selectUsersListReload(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  disableUser: (id: number): void => dispatch(disableUserAction(id)),
  enableUser: (id: number): void => dispatch(enableUserAction(id)),
  setUserCreated: (userCreated: boolean): void => dispatch(setUserCreated(userCreated)),
  showSnackbar: (message: string, severity: SeveritySnackbar, route?: string, time?: number): void =>
    dispatch(showSnackbar(message, severity, route, time)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersStaff);
