import { ButtonProps, InputAdornment } from '@material-ui/core';
import { ArtTrack as Report, DeleteOutline, Description as Download, Edit } from '@material-ui/icons';
import { MaterialTableProps } from 'material-table';
import moment from 'moment';
import React, { CSSProperties, Fragment, Suspense, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { downloadPaymentFileFromApi, editProject as editProjectApi } from '../../../../api/Projects/projects';
import KeyboardDatePickerController from '../../../../components/DatePicker/KeyboardDatePickerController';
import TextInput from '../../../../components/Inputs/TextInput';
import LayoutForm from '../../../../components/Layout/LayoutForm';
import Loading from '../../../../components/Loading/Loading';
import TableWithEdit from '../../../../components/Tables/TableWithEdit';
import Title from '../../../../components/Title/Title';
import * as DateConstants from '../../../../constants/date';
import { useHookMethod } from '../../../../helpers/customHooks/useHookMethod';
import errorMessage from '../../../../helpers/errorMessage';
import { formatNumber } from '../../../../helpers/formatNumber';
import { getDocs } from '../../../../helpers/getDocs';
import { tFormKey, tProjectKey } from '../../../../helpers/translate';
import { selectIsNousCims, selectUserInfo } from '../../../../redux/auth/selectors';
import { KeyButton } from '../../../../redux/common/definitions';
import { showSnackbar } from '../../../../redux/FeedbackAPI/actions';
import { SeveritySnackbar } from '../../../../redux/FeedbackAPI/definitions';
import { selectUserCan } from '../../../../redux/permissions/selectors';
import {
  changeState,
  deleteProjectSchedule,
  getOneProject,
  projectScheduleSetData,
  updateProjectCalendar,
} from '../../../../redux/project/action';
import {
  buttonsDatas,
  ProjectChangeStateKo,
  ProjectChangeStateOk,
  ProjectData,
  ScheduledAppointmentItem,
  ScheduledAppointmentState,
  StateProject,
} from '../../../../redux/project/definitions';
import {
  selectLoading,
  selectLoadingSchedules,
  selectPendingAmount,
  selectProjectAmount,
  selectProjectData,
  selectProjectEndDate,
  selectProjectStartDate,
  selectScheduledAmount,
} from '../../../../redux/project/selectors';
import { AppState } from '../../../../redux/root-reducer';
import { tracingSchema } from '../../../../validations/tracingProjectSchema';
import ProjectMenuRight from '../../ProjectMenuRight/ProjectMenuRight';
import SubmenuProject from '../../SubmenuProject/SubmenuProject';
import styles from './calendar.module.scss';

const ProjectScheduleItem = React.lazy(() => import('./ProjectScheduleItem'));
const ModifyScheduleItem = React.lazy(() => import('./ModifyScheduleItem'));

interface CalendarProps {
  buttons: ButtonProps[];
  moreInfoButtons: ButtonProps[];
  project: ProjectData;
  loading: boolean;
  disabled?: boolean;
  projectAmount: number;
  scheduledAmount: number;
  pendingAmount: number;
  trackingSchedulePermission: boolean;
  allowEditProjectSchedules: boolean;
  projectStartDate: string | null | undefined;
  projectEndDate: string | null | undefined;
  loadingSchedules: boolean;
  isNouscims: boolean;
  getProjectData: (project_id: string) => void;
  updateProjectCalendar: (project_id: number, schedules: ScheduledAppointmentItem[]) => void;
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number) => void;
  changeState: (id: number, states: ProjectChangeStateOk | ProjectChangeStateKo, comments?: string) => void;
  setScheduleData: (key: string, value: string | number) => void;
  deleteProjectSchedule: (project_id: number, id: number) => void;
}

const actionIcons = {
  Delete: () => <DeleteOutline style={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
  DeleteDis: () => <DeleteOutline style={{ color: 'rgba(0, 0, 0, 0.2)' }} />,
  Edit: () => <Edit style={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
  EditDis: () => <Edit style={{ color: 'rgba(0, 0, 0, 0.2)' }} />,
  Download: () => <Download style={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
  Report: () => <Report style={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
};

const Calendar: React.FC<CalendarProps> = ({
  buttons,
  moreInfoButtons,
  project,
  loading,
  disabled,
  projectAmount,
  scheduledAmount,
  pendingAmount,
  trackingSchedulePermission,
  allowEditProjectSchedules,
  projectStartDate,
  projectEndDate,
  loadingSchedules,
  isNouscims,
  getProjectData,
  updateProjectCalendar,
  showSnackbar,
  changeState,
  setScheduleData,
  deleteProjectSchedule,
}) => {
  const history = useHistory();
  const { scheduled_appointments, active_schedule_item_id, id } = project;
  let showBaseLine = false;
  if (scheduled_appointments) {
    showBaseLine =
      scheduled_appointments.findIndex(
        (item: ScheduledAppointmentItem) => item.state === ScheduledAppointmentState.BASE_LINE,
      ) === -1;
  }

  //datePicker
  const [openModal, setOpenModal] = useState({
    view: false,
    id: 0,
    message: null as null | string,
    showBaseLine: true,
  });

  const [modifyView, setModifyView] = useState(false);
  const { errors, control, handleSubmit, clearError } = useForm<{
    kick_off: string | null;
    date_end_project: string | null;
  }>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: tracingSchema,
  });

  useEffect(() => {
    !modifyView && id && getProjectData(id.toString());
  }, [modifyView, id]); // eslint-disable-line react-hooks/exhaustive-deps

  const changeDate = (name: string, date: moment.Moment | null) => {
    if (date && date.isValid()) {
      setScheduleData(name, date.format(DateConstants.DATE_FORMAT));
    }
  };

  const setDateValue = (name: string, date: string | null) => {
    if (date === null || date.trim() === '') {
      return;
    }
    const dateMoment = moment(date, DateConstants.DATE_FORMAT);
    if (dateMoment.isValid()) {
      setScheduleData(name, dateMoment.format(DateConstants.DATE_FORMAT));
    }
  };

  const asingDateValue = (date: string | number | null) => {
    return date ? moment(date, DateConstants.DATE_FORMAT) : null;
  };

  const canAlterScheduleItem = (rowData: ScheduledAppointmentItem): boolean => {
    if (allowEditProjectSchedules && !rowData.complete) return true;
    return (
      (active_schedule_item_id !== rowData.id && !rowData.complete && !rowData.kpis_id) ||
      (active_schedule_item_id === rowData.id && project.state_code === StateProject.TRACKING_FINISHED)
    );
  };

  const handleDeleteScheduleItem = (rowData: ScheduledAppointmentItem) => {
    if (canAlterScheduleItem(rowData)) {
      deleteProjectSchedule(rowData.id, project.id);
    }
  };

  const handleEditScheduleItem = (rowData: ScheduledAppointmentItem) => {
    if (canAlterScheduleItem(rowData)) {
      setOpenModal({
        view: true,
        id: rowData.id,
        message: rowData?.tracking_message || null,
        showBaseLine: showBaseLine || rowData.state === ScheduledAppointmentState.BASE_LINE,
      });
    }
  };

  const handleAddScheduleItem = () => {
    setOpenModal({ view: true, id: 0, message: null, showBaseLine });
  };

  const handleDownloadInvoiceDocument = (document_id: number) => {
    downloadPaymentFileFromApi(document_id)
      .then(data => {
        getDocs(data, true);
      })
      .catch(error => showSnackbar((error as Error).message, 'error', undefined, 1500));
  };
  const handleRedirect = (id: number, projectID: number | null) => {
    history.push(`/proyecto/detalle/${id}/informe_seguimiento/${projectID}`);
  };

  const activeSchedule: CSSProperties = {
    color: '#21610f',
  };

  const normalCell: CSSProperties = {
    color: '#000000',
  };

  const columnHeader: CSSProperties = {
    color: '#21610f',
  };

  const selectCSSCell = (active: number) => (active === active_schedule_item_id ? activeSchedule : normalCell);

  const tableData: MaterialTableProps<ScheduledAppointmentItem> = {
    options: {
      actionsColumnIndex: -1,
      pageSize: 100,
      paging: false,
      tableLayout: 'auto',
    },
    title: tProjectKey('Seguimientos'),
    columns: [
      {
        field: 'tracking_date',
        title: tProjectKey('Fecha'),
        cellStyle: (data: ScheduledAppointmentItem[], rowData: ScheduledAppointmentItem) => selectCSSCell(rowData.id),
        //@ts-ignore
        width: '100px',
      },
      {
        title: tProjectKey('Tipo'),
        field: 'state',
        render: (rowData: ScheduledAppointmentItem): any => {
          return tProjectKey(rowData.state);
        },
        cellStyle: (data: ScheduledAppointmentItem[], rowData: ScheduledAppointmentItem) => selectCSSCell(rowData.id),
        //@ts-ignore
        width: '150px',
      },
      {
        title: tProjectKey('Seguimiento de pagos'),
        field: 'has_payment',
        headerStyle: columnHeader,
        cellStyle: (data: ScheduledAppointmentItem[], rowData: ScheduledAppointmentItem) => selectCSSCell(rowData.id),
        render: (rowData: ScheduledAppointmentItem) => (
          <div style={{ textAlign: 'center' }}>{rowData.has_payment ? tFormKey('Sí') : tFormKey('No')}</div>
        ),
        //@ts-ignore
        width: '220px',
      },
      {
        title: tProjectKey('Completado'),
        field: 'complete',
        headerStyle: {
          textAlign: 'center',
        },
        cellStyle: (data: ScheduledAppointmentItem[], rowData: ScheduledAppointmentItem) => selectCSSCell(rowData.id),
        render: (rowData: ScheduledAppointmentItem) => (
          <div style={{ textAlign: 'center' }}>{rowData.complete ? tFormKey('Sí') : tFormKey('No')}</div>
        ),
        //@ts-ignore
        width: '100px',
      },
      {
        title: tProjectKey('Periodo'),
        field: 'period',
        cellStyle: (data: ScheduledAppointmentItem[], rowData: ScheduledAppointmentItem) => selectCSSCell(rowData.id),
        render: (rowData: ScheduledAppointmentItem): string => {
          if (rowData.state === ScheduledAppointmentState.BASE_LINE) {
            return tProjectKey(rowData.state);
          } else {
            return tProjectKey('periodo_'.concat(rowData.period));
          }
        },
        //@ts-ignore
        width: '150px',
      },
      {
        field: 'partial_amount',
        title: tProjectKey('Pago'),
        type: 'numeric',
        headerStyle: {
          textAlign: 'center',
        },
        cellStyle: (data: ScheduledAppointmentItem[], rowData: ScheduledAppointmentItem) => selectCSSCell(rowData.id),
        render: (rowData: ScheduledAppointmentItem) => (
          <div style={{ textAlign: 'right' }}>
            {rowData.state === ScheduledAppointmentState.PAYMENT &&
              formatNumber({ value: rowData.partial_amount }) + ' €'}
          </div>
        ),
        //@ts-ignore
        width: '150px',
      },
    ],
    actions: isNouscims
      ? [
          (rowData: ScheduledAppointmentItem) => ({
            icon: canAlterScheduleItem(rowData) ? actionIcons.Edit : actionIcons.EditDis,
            tooltip: tProjectKey('Editar'),
            onClick: () => handleEditScheduleItem(rowData),
            hidden:
              rowData.state === ScheduledAppointmentState.KICK_OFF ||
              rowData.state === ScheduledAppointmentState.FINISH,
          }),
          (rowData: ScheduledAppointmentItem) => ({
            icon: canAlterScheduleItem(rowData) ? actionIcons.Delete : actionIcons.DeleteDis,
            tooltip: tProjectKey('Eliminar seguimiento'),
            onClick: () => handleDeleteScheduleItem(rowData),
            hidden:
              rowData.state === ScheduledAppointmentState.KICK_OFF ||
              rowData.state === ScheduledAppointmentState.FINISH,
          }),
          (rowData: ScheduledAppointmentItem) => ({
            icon: actionIcons.Download,
            tooltip: tProjectKey('Factura'),
            onClick: () => handleDownloadInvoiceDocument(Number(rowData.document_id)),
            hidden: rowData.document_id === null,
          }),
          (rowData: ScheduledAppointmentItem) => ({
            icon: actionIcons.Report,
            tooltip: tProjectKey(`Informe de seguimiento`),
            onClick: () => handleRedirect(project.id, rowData.id),
            hidden: !(
              (rowData.state === ScheduledAppointmentState.CHECK ||
                rowData.state === ScheduledAppointmentState.BASE_LINE) &&
              rowData.complete === 1
            ),
          }),
        ]
      : undefined,
    data: scheduled_appointments,
  };

  const handleSave = (): void => {
    clearError();
    updateProjectCalendar(project.id, scheduled_appointments);
  };

  const handleSend = async () => {
    try {
      // project.scheduled_appointments = backTable;
      // if (requires_follow_up) project.requires_follow_up = requires_follow_up.toString();
      await editProjectApi(project, project.id);
      /*       changeState(project.id, changeStateButtons(project.state_code, KeyButton.SEND)); */
      changeState(project.id, buttonsDatas.getTransition(project.state_code, 'send'));
    } catch (error) {
      showSnackbar(errorMessage(error), 'error', undefined, 1500);
    }
  };

  const modifyProject = () => {
    setModifyView(true);
  };

  useHookMethod(
    buttons,
    [KeyButton.SAVE, KeyButton.SEND, KeyButton.MODIFY],
    [handleSubmit(handleSave), handleSubmit(handleSend), modifyProject],
  );

  const handleClickrow = (rowData: ScheduledAppointmentItem) => {
    if (
      (rowData.state === ScheduledAppointmentState.CHECK && rowData.kpis_id) ||
      (rowData.state === ScheduledAppointmentState.BASE_LINE && rowData.kpis_id) ||
      (rowData.state === ScheduledAppointmentState.FNC_CHECK && isNouscims && rowData.complete)
    ) {
      history.push(`/proyecto/detalle/${project.id}/kpis_rvw/${rowData.id}`);
    }
  };

  tableData.isLoading = loadingSchedules;

  return (
    <LayoutForm
      leftSubmenu={
        <SubmenuProject
          current_section="calendario"
          project={project}
          buttons={buttons}
          onClickOption={handleSubmit(handleSave)}
        />
      }
      rightSubmenu={<ProjectMenuRight actionButtons={buttons} moreInfoButtons={moreInfoButtons} project={project} />}
    >
      {loading ? (
        <Loading big />
      ) : (
        <Fragment>
          <section className={styles.containerAbout}>
            <Title>{tProjectKey('Calendario')}</Title>
            <div className={styles.info}>
              <KeyboardDatePickerController
                className={styles.size_50}
                label={tProjectKey('Fecha inicio proyecto')}
                name="kick_off"
                control={control}
                schema={tracingSchema}
                defaultValue={projectStartDate}
                maxDate={projectEndDate ? asingDateValue(projectEndDate) : undefined}
                errors={errors}
                disabled={disabled}
                onAccept={date => {
                  date && changeDate('kick_off', date);
                }}
                onBlur={e => {
                  setDateValue('kick_off', e.target.value);
                }}
              />
              <KeyboardDatePickerController
                className={styles.size_50}
                label={tProjectKey('Fecha fin proyecto')}
                name={loadingSchedules ? 'date_end_project_loading' : 'date_end_project'}
                control={control}
                schema={tracingSchema}
                defaultValue={projectEndDate}
                minDate={projectStartDate ? asingDateValue(projectStartDate) : undefined}
                errors={errors}
                disabled={disabled}
                onAccept={date => {
                  date && changeDate('date_end_project', date);
                }}
                onBlur={e => {
                  setDateValue('date_end_project', e.target.value);
                }}
              />
            </div>
            {isNouscims && (
              <>
                <div className={styles.secondTitle}>
                  <Title>
                    {project.untracked_project ? tProjectKey('Pagos') : tProjectKey('Seguimientos y Pagos')}
                  </Title>
                </div>
                <div className={styles.addItem}>
                  <TextInput
                    type="number"
                    disabled
                    label={tProjectKey('Importe total del convenio')}
                    size="30"
                    name="projectAmount"
                    value={projectAmount}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">€</InputAdornment>,
                    }}
                  />
                  <TextInput
                    type="number"
                    disabled
                    label={tProjectKey('Importe asignado')}
                    size="30"
                    name="scheduledAmount"
                    value={scheduledAmount}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">€</InputAdornment>,
                    }}
                  />
                  <TextInput
                    type="number"
                    disabled
                    label={tProjectKey('Importe pendiente asignar')}
                    size="30"
                    name="pendingAmount"
                    value={pendingAmount}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">€</InputAdornment>,
                    }}
                  />
                </div>
              </>
            )}

            <div className={styles.table_assing}>
              <TableWithEdit
                tableData={tableData}
                key={JSON.stringify(tableData.data)}
                onRowClick={(rowData: ScheduledAppointmentItem) => handleClickrow(rowData)}
                optionsNew={
                  isNouscims
                    ? {
                        newButtonText: project.untracked_project
                          ? tProjectKey('Nuevo pago')
                          : tProjectKey('Nuevo seguimiento'),
                        onNew: handleAddScheduleItem,
                      }
                    : undefined
                }
                permission={
                  (trackingSchedulePermission || project.state_code === StateProject.AGREEMENT) &&
                  !!projectStartDate &&
                  !!projectEndDate
                }
              />
            </div>
          </section>
        </Fragment>
      )}
      <Suspense fallback={<></>}>
        {openModal.view ? (
          <ProjectScheduleItem
            showModal={openModal.view}
            schedule_id={openModal.id}
            showBaseLine={openModal.showBaseLine}
            code={project.area_code}
            closeModal={() => setOpenModal({ view: false, id: 0, message: null, showBaseLine: false })}
          />
        ) : (
          <Fragment />
        )}
      </Suspense>
      <Suspense fallback={<></>}>
        {modifyView ? (
          <ModifyScheduleItem
            title={tProjectKey('Modificar fechas del proyecto')}
            view={modifyView}
            handleClose={() => {
              setModifyView(false);
            }}
            projectId={project.id}
            projectAmount={projectAmount}
          />
        ) : (
          <Fragment />
        )}
      </Suspense>
    </LayoutForm>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectLoading(state),
  loadingSchedules: selectLoadingSchedules(state),
  user_id: selectUserInfo(state).user_id,
  trackingSchedulePermission: selectUserCan(state)('modify_tracking_schedule'),
  allowEditProjectSchedules: selectUserCan(state)('allow_edit_project_schedules'),
  project: selectProjectData(state),
  projectAmount: selectProjectAmount(state),
  scheduledAmount: selectScheduledAmount(state),
  pendingAmount: selectPendingAmount(state),
  projectStartDate: selectProjectStartDate(state),
  projectEndDate: selectProjectEndDate(state),
  isNouscims: selectIsNousCims(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  setScheduleData: (key: string, value: string | number) => dispatch(projectScheduleSetData(key, value)),
  getProjectData: (project_id: string) => dispatch(getOneProject(project_id)),
  deleteProjectSchedule: (id: number, project_id: number) => dispatch(deleteProjectSchedule(id, project_id)),
  updateProjectCalendar: (project_id: number, schedules: ScheduledAppointmentItem[]) =>
    dispatch(updateProjectCalendar(project_id, schedules)),
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number): void =>
    dispatch(showSnackbar(message, severity, route, time)),
  changeState: (id: number, states: ProjectChangeStateOk | ProjectChangeStateKo, comments?: string): void =>
    dispatch(changeState(id, states, comments)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
