import { SupervisedUserCircle } from '@material-ui/icons';
import BlockIcon from '@material-ui/icons/Block';
import { MaterialTableProps } from 'material-table';
import React, { Suspense, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { getUsersByTypeFromApi } from '../../../../api/user';
import Submenu from '../../../../components/Layout/Menus/Submenu/Submenu';
import TableLayout from '../../../../components/Layout/TableLayout';
import MessageInfo from '../../../../components/MessageInfo/MessageInfo';
import TableWithEdit from '../../../../components/Tables/TableWithEdit';
import { permissionType } from '../../../../constants/permissions_definitions';
import colorPalette from '../../../../helpers/colorPalette';
import { exportToExcel } from '../../../../helpers/exportToXslx';
import { tKey } from '../../../../helpers/translate';
import { AuthUserData } from '../../../../redux/auth/definitions';
import { selectIsNousCims, selectUserInfo } from '../../../../redux/auth/selectors';
import { showSnackbar } from '../../../../redux/FeedbackAPI/actions';
import { SeveritySnackbar } from '../../../../redux/FeedbackAPI/definitions';
import { selectPermissionsList } from '../../../../redux/permissions/selectors';
import { AppState } from '../../../../redux/root-reducer';
import { disableUserAction, setUserCreated } from '../../../../redux/user/actions';
import { RoleGroups, UserItemList, UserType } from '../../../../redux/user/definitions';
import { selectUserCreated } from '../../../../redux/user/selectors';
import UserRolesModal from '../../UserRoles/UserRolesModal';
import { userOptionsSubmenu } from '../userOptionsMenu';
import styles from './entityUsers.module.scss';

interface EntityUsersProps extends RouteComponentProps<{ type?: UserType }> {
  permissions: permissionType[];
  isNousCims: boolean;
  userInfo: AuthUserData;
  disableUser: (id: number) => void;
  setUserCreated: (userCreated: boolean) => void;
  showSnackbar: (message: string, severity: SeveritySnackbar, route?: string, time?: number) => void;
}

const actionIcons = {
  BlockIcon: () => <BlockIcon style={{ color: colorPalette.secondary80 }} />,
  Roles: () => <SupervisedUserCircle style={{ color: colorPalette.secondary80 }} />,
};

const EntityUsers: React.FC<EntityUsersProps> = ({
  permissions,
  isNousCims,
  userInfo,
  disableUser,
  setUserCreated,
  showSnackbar,
}) => {
  const usersType = 'staff';
  const groupType = RoleGroups.ENTITAT;
  const history = useHistory();
  const [userRolesConfig, setUserRolesConfig] = useState({ view: false, user_id: 0 });
  const canConfigUserRoles = permissions.includes('allow_config_user_roles');
  const disabledState = tKey(`user.states.DISABLED`);
  const [refresh, setRefresh] = useState(false);

  const tableColumns = [
    { title: tKey('Nombre'), field: 'name', width: '200px' },
    { title: tKey('Apellidos'), field: 'surname' },
    { title: tKey('ip.rol'), field: 'role', width: '250px' },
    { title: tKey('Correo electrónico'), field: 'email' },
    { title: tKey('Teléfono'), field: 'phone', width: '150px' },
    { title: tKey('users.document_number'), field: 'document_number', width: '150px' },
    { title: tKey('Estado'), field: 'state' },
  ];

  const showRoles = (rowData: UserItemList) => {
    setUserRolesConfig({ view: true, user_id: rowData.id });
  };

  const onDisableOne = (rowData: UserItemList) => {
    if (!Array.isArray(rowData) && rowData) {
      if (userInfo.user_id === rowData.id) {
        return showSnackbar(tKey('No puedes deshabilitar tu usuario'), 'warning', undefined, 1500);
      }
      disableUser(rowData.id);
      setRefresh(!refresh);
    }
  };

  const [table, setTable] = useState<MaterialTableProps<UserItemList>>({
    title: tKey('users.staff'),
    columns: tableColumns,
    options: {
      tableLayout: 'fixed',
      pageSize: 100,
      pageSizeOptions: [20, 50, 100],
      exportButton: true,
      exportCsv: (columns, data) => {
        exportToExcel(columns, data, `Usuarios`);
      },
    },
    data: [],
    actions: [
      (rowData: UserItemList) => ({
        icon: actionIcons.Roles,
        tooltip: tKey('Roles'),
        onClick: () => showRoles(rowData),
        hidden: !canConfigUserRoles,
      }),
      (rowData: UserItemList) => ({
        icon: actionIcons.BlockIcon,
        tooltip: tKey('Deshabilitar'),
        onClick: () => onDisableOne(rowData),
        hidden: rowData.state === disabledState,
      }),
    ],
  });

  const optionsSubmenu = userOptionsSubmenu({
    currentType: usersType,
    permissions,
    isNousCims,
  });

  useEffect(() => {
    (async function fetchData() {
      setTable(previousData => ({ ...previousData, isLoading: true }));
      try {
        const { data } = await getUsersByTypeFromApi(usersType);
        data.forEach(element => {
          element.state = tKey(`user.states.${element.state}`);
        });
        setTable(previousData => ({
          ...previousData,
          data,
          isLoading: false,
        }));
      } catch (e) {
        setTable(previousData => ({
          ...previousData,
          data: [],
          isLoading: false,
        }));
        showSnackbar(tKey('No se han podido traer los usuarios'), 'error');
      }
    })();
  }, [showSnackbar]);

  const details = (rowData: UserItemList) => {
    switch (rowData.state) {
      case tKey(`user.states.CREATED`):
        history.push({
          pathname: `/usuario/detalles/${rowData.id}`,
          state: { id: rowData.id, type: groupType },
        });
        break;
      case tKey(`user.states.CREATING_REGULAR`):
        history.push({
          pathname: `/usuario/editar/${rowData.id}`,
          state: { id: rowData.id, type: groupType },
        });
        break;
      case tKey(`user.states.CREATING_EDUCATOR`):
        history.push({
          pathname: `/usuario/detalles/${rowData.id}`,
          state: { id: rowData.id, type: groupType, needsValidation: true },
        });
        break;
      case disabledState:
        history.push({
          pathname: `/usuario/detalles/${rowData.id}`,
          state: { id: rowData.id, type: groupType, needsValidation: true },
        });
        break;
      default:
        history.push({
          pathname: `/usuario/detalles/${rowData.id}`,
          state: { id: rowData.id, type: groupType },
        });
    }
  };

  const goToNewUser = () => {
    setUserCreated(false);
    history.push({ pathname: '/usuario/crear', state: { type: groupType } });
  };

  return (
    <>
      <TableLayout leftSubmenu={<Submenu titleMenu={tKey('Usuarios')} optionsMenu={optionsSubmenu} defaultOpen />}>
        <div className={styles.messageInfo_wrapper}>
          <MessageInfo>{tKey('mensaje responsable entidad')}</MessageInfo>
        </div>
        <TableWithEdit
          tableData={table}
          exportButton
          filtering
          permission={true}
          optionsNew={{ newButtonText: tKey('NUEVO USUARIO'), onNew: goToNewUser }}
          onRowClick={(rowData: UserItemList) => details(rowData)}
          key={JSON.stringify(table.data)}
        />
      </TableLayout>
      <Suspense fallback={<></>}>
        {userRolesConfig.view && (
          <UserRolesModal
            view={userRolesConfig.view}
            user_id={userRolesConfig.user_id}
            handleClose={() => {
              setUserRolesConfig({ view: false, user_id: 0 });
              setRefresh(!refresh);
            }}
          />
        )}
      </Suspense>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  permissions: selectPermissionsList(state),
  userCreated: selectUserCreated(state),
  isNousCims: selectIsNousCims(state),
  userInfo: selectUserInfo(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  disableUser: (id: number): void => dispatch(disableUserAction(id)),
  setUserCreated: (userCreated: boolean): void => dispatch(setUserCreated(userCreated)),
  showSnackbar: (message: string, severity: SeveritySnackbar, route?: string, time?: number): void =>
    dispatch(showSnackbar(message, severity, route, time)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EntityUsers);
