import { APIValidationError } from '../../api/api';
import { ColumnOrderData, DocumentationItem, IdCodeNamePair, IdNamePair, ResponseData } from '../common/definitions';
import { AppState } from '../root-reducer';
import { LangCodes } from '../translations/definitions';
import { Role, RoleGroups, UpdateUserFormFields, UserData, UserItemList, UserRole } from './definitions';
import { UserDataState } from './reducers';

export const selectUser = (state: AppState): UserDataState => state.userReducer;
export const selectIsLoading = (state: AppState): boolean => selectUser(state).loading;
export const selectError = (state: AppState) => selectUser(state).error;
export const selectUserValidationErrors = (state: AppState): APIValidationError | null =>
  selectUser(state).validationErrors;
export const selectProfile = (state: AppState): UserData => selectUser(state).profileData;
export const selectUsersList = (state: AppState): UserItemList[] => selectUser(state).usersList;
export const selectDocumentation = (state: AppState): DocumentationItem[] => selectUser(state).documentation;
export const selectSearchText = (state: AppState): string | null => selectUser(state).searchText;
export const selectPageSize = (state: AppState): number => selectUser(state).pageSize;
export const selectColumnOrder = (state: AppState): ColumnOrderData => selectUser(state).columnOrder;
export const selectResponseData = (state: AppState): ResponseData | null => selectUser(state).responseData;
export const selectIsAllSuccess = (state: AppState): boolean => selectUser(state).isAllSuccess;
export const selectUserCreated = (state: AppState): boolean => selectUser(state).userCreated;
export const selectUserAvatar = (state: AppState): string => selectUser(state).userAvatar;
export const selectUserLanguage = (state: AppState): string => selectUser(state).profileData.language || LangCodes.ES;
export const selectMetadataEntity = (state: AppState): IdNamePair[] => selectUser(state).entitiesMetadata;
export const selectUsersByRole = (state: AppState, role_code: string[]): UserItemList[] =>
  state.userReducer.usersList.filter(user => role_code.includes(user.role_code));
export const selectUsersListReload = (state: AppState): boolean => selectUser(state).usersListReload;
export const selectUserByRoleGroup = (state: AppState, group?: string): IdCodeNamePair[] => {
  return group
    ? state.authReducer.userInfo.userAllowedRoles.filter(role => {
        if (group === RoleGroups.EXTERNOS) {
          return role.group === RoleGroups.EXTERNOS || role.group === RoleGroups.SHERPA;
        } else {
          return role.group === group;
        }
      })
    : state.authReducer.userInfo.userAllowedRoles;
};

export const selectRoleActive = (state: AppState): UserRole | undefined => {
  const { user_roles } = state.userReducer.userRolesConfig;

  if (user_roles) {
    const active_roles = user_roles.filter(role => role.is_active);
    if (active_roles && active_roles.length) {
      return active_roles[0];
    }
  }
  return undefined;
};

export const selectAvailableRoles = (state: AppState): Role[] => {
  const { userAllowedRoles } = state.authReducer.userInfo;
  const { user_roles } = state.userReducer.userRolesConfig;

  if (user_roles) {
    return userAllowedRoles.filter(role => !user_roles.find(userRole => userRole.id === role.id));
  }
  return [];
};

export const selectUpdateUserFromFileds = (state: AppState): UpdateUserFormFields => {
  const profileData = selectProfile(state);
  return {
    document_type: profileData.document_type,
    academy_code: profileData.academy_code,
    role_id: profileData.role_id,
    zing_network: profileData.zing_network,
    gender: profileData.gender,
    birthdate: profileData.birthdate,
    address_type: profileData.address_type,
    city: profileData.city,
    entity_id: profileData.entity_id,
    province: profileData.province,
    country: profileData.country,
    email: profileData.email,
    created_at: profileData.created_at,
    document_number: profileData.document_number,
    name: profileData.name,
    surname: profileData.surname,
    surname2: profileData.surname2,
    phone: profileData.phone,
    address: profileData.address,
    flat: profileData.flat,
    floor: profileData.floor,
    room: profileData.room,
    staircase: profileData.staircase,
    zip_code: profileData.zip_code,
    account_number: profileData.account_number,
    phone2: profileData.phone2,
    email2: profileData.email2,
    documents: profileData.documents,
    cv_agreement: profileData.cv_agreement,
    privacy_policy: profileData.privacy_policy,
    language: profileData.language,
  };
};
