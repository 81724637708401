import { ThemeProvider } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from '@material-ui/icons';
import DraftsIcon from '@material-ui/icons/Drafts';
import EmailIcon from '@material-ui/icons/Email';
import MaterialTable, { Action, MaterialTableProps, MTableToolbar } from 'material-table';
import React, { forwardRef, ReactElement } from 'react';
import SubTitle from '../../components/SubTitle/Subtitle';
import { ColumnOrderData, OrderDirection } from '../../redux/common/definitions';
import { tableTheme } from '../../ui/muiCustomTheme';
import ExportList, { Exports } from '../ExportList/ExportList';
import styles from './tableWithEdit.module.scss';
import webStyle from '../../helpers/webStyle';
import colorPalette from '../../helpers/colorPalette';
import { tKey } from '../../helpers/translate';

const tableIcons = {
  Add: forwardRef<SVGSVGElement>((props, ref) => <AddBox {...props} ref={ref} style={{ color: 'blue' }} />),
  Check: forwardRef<SVGSVGElement>((props, ref) => <Check {...props} ref={ref} style={{ color: 'green' }} />),
  Clear: forwardRef<SVGSVGElement>((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef<SVGSVGElement>((props, ref) => (
    <DeleteOutline {...props} ref={ref} style={{ color: colorPalette.secondary80 }} />
  )),
  DetailPanel: forwardRef<SVGSVGElement>((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef<SVGSVGElement>((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef<SVGSVGElement>((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef<SVGSVGElement>((props, ref) => (
    <Search {...props} ref={ref} style={{ color: colorPalette.secondary20 }} />
  )),
  FirstPage: forwardRef<SVGSVGElement>((props, ref) => (
    <FirstPage {...props} ref={ref} style={{ color: colorPalette.primary80 }} />
  )),
  LastPage: forwardRef<SVGSVGElement>((props, ref) => (
    <LastPage {...props} ref={ref} style={{ color: colorPalette.primary80 }} />
  )),
  NextPage: forwardRef<SVGSVGElement>((props, ref) => (
    <ChevronRight {...props} ref={ref} style={{ color: colorPalette.primary80 }} />
  )),
  PreviousPage: forwardRef<SVGSVGElement>((props, ref) => (
    <ChevronLeft {...props} ref={ref} style={{ color: colorPalette.primary80 }} />
  )),
  ResetSearch: forwardRef<SVGSVGElement>((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef<SVGSVGElement>((props, ref) => <Search {...props} ref={ref} />),
  ThirdStateCheck: forwardRef<SVGSVGElement>((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef<SVGSVGElement>((props, ref) => <ViewColumn {...props} ref={ref} />),
  Drafts: forwardRef<SVGSVGElement>((props, ref) => <DraftsIcon {...props} ref={ref} />),
  Email: forwardRef<SVGSVGElement>((props, ref) => <EmailIcon {...props} ref={ref} />),
  SortArrow: forwardRef<SVGSVGElement>((props, ref) => <ArrowDownward {...props} ref={ref} />),
};

const actionIcons = {
  Add: () => <AddBox style={{ color: 'blue' }} />,
  Check: () => <Check style={{ color: 'green' }} />,
  Delete: () => <DeleteOutline style={{ color: colorPalette.secondary80 }} />,
  Edit: () => <Edit style={{ color: colorPalette.secondary80 }} />,
  Email: () => <EmailIcon />,
  Drafts: () => <DraftsIcon />,
};

interface Props<T extends object> {
  tableData: MaterialTableProps<T>;
  columnOrder?: ColumnOrderData;
  exportButton?: boolean;
  filtering?: boolean;
  title?: string;
  rowStyle?: CSSProperties | ((rowData: T) => CSSProperties);
  permission?: boolean | undefined;
  permissionOthers?: boolean | undefined;
  optionsNew?: {
    onNew: () => void;
    newButtonText: string;
  };
  optionsHelp?: {
    help: string;
    onHelp: () => void;
  };
  optionsExport?: ReactElement;
  exportAllData?: boolean;
  noSearch?: boolean;
  exportList?: Exports[];
  customContainer?: boolean;
  onEditOne?: (event: React.MouseEvent, rowData: T | T[]) => void;
  onSelectionChange?: (data: T[], rowData?: T | undefined) => void;
  onRowClick?: (rowData: T, toggleDetailPanel?: ((panelIndex?: number | undefined) => void) | undefined) => void;
  markAsReadBulk?: (data: T | T[], read: boolean) => void;
  markAsUnReadBulk?: (data: T | T[], read: boolean) => void;
  deleteFunc?: (rowData: T) => void;
  deleteBulk?: (rowData: T[]) => void;
  getTextSearch?: (searchText: string) => void;
  getPageSize?: (pageSize: number) => void;
  getOrderColumns?: (orderedColumnId: number, orderDirection: OrderDirection) => void;
  setPage?: (page: number) => void;
}

const TableWithEdit = <T extends object>({
  tableData,
  exportButton,
  filtering,
  title,
  permission,
  permissionOthers,
  optionsNew,
  optionsHelp,
  optionsExport,
  noSearch,
  exportAllData = true,
  columnOrder,
  exportList,
  customContainer,
  onSelectionChange,
  onEditOne,
  setPage,
  onRowClick,
  markAsReadBulk,
  markAsUnReadBulk,
  deleteFunc,
  deleteBulk,
  getTextSearch,
  getPageSize,
  getOrderColumns,
}: Props<T>): ReactElement => {
  const checkActions = () => {
    const actions: (Action<T> | ((rowData: T) => Action<T>))[] = [];

    if (exportList) {
      actions.push({
        icon: () => <ExportList exportsList={exportList} />,
        tooltip: tKey('Exportar'),
        isFreeAction: true,
        onClick: e => e.preventDefault(),
      });
    }
    if (optionsHelp) {
      actions.push({
        icon: () => <div className={styles[`secondaryButton_${webStyle}`]}>{optionsHelp.help}</div>,
        isFreeAction: true,
        onClick: () => optionsHelp.onHelp(),
      });
    }
    if (optionsNew && permission) {
      actions.push({
        icon: () => <div className={styles[`newButton_${webStyle}`]}>{optionsNew.newButtonText}</div>,
        isFreeAction: true,
        onClick: () => optionsNew.onNew(),
      });
    }
    if (markAsReadBulk) {
      actions.push({
        icon: actionIcons.Drafts,
        tooltip: tKey('Marcar como leída'),
        onClick: (event: React.MouseEvent, rowData: T | T[]) => markAsReadBulk(rowData, true),
      });
    }

    if (markAsUnReadBulk) {
      actions.push({
        icon: actionIcons.Email,
        tooltip: tKey('Marcar como no leída'),
        onClick: (event: React.MouseEvent, rowData: T | T[]) => markAsUnReadBulk(rowData, false),
      });
    }

    if (onEditOne) {
      actions.push({
        icon: actionIcons.Edit,
        tooltip: tKey('Editar'),
        position: 'row',
        onClick: (event: React.MouseEvent, rowData: T | T[]) => onEditOne(event, rowData),
      });
    }
    if (deleteFunc) {
      actions.push({
        icon: actionIcons.Delete,
        tooltip: tKey('Eliminar'),
        position: 'row',
        onClick: (event: React.MouseEvent, rowData: T | T[]) =>
          Array.isArray(rowData) ? deleteBulk && deleteBulk(rowData) : deleteFunc && deleteFunc(rowData),
      });
    }

    if (deleteBulk) {
      actions.push({
        icon: actionIcons.Delete,
        tooltip: tKey('Eliminar'),
        onClick: (event: React.MouseEvent, rowData: T | T[]) =>
          Array.isArray(rowData) ? deleteBulk && deleteBulk(rowData) : deleteFunc && deleteFunc(rowData),
      });
    }
    return tableData.actions ? [...tableData.actions, ...actions] : actions;
  };

  return (
    <div className={`${styles.root} ${customContainer ? styles.customContainer : styles.container}`}>
      <ThemeProvider theme={tableTheme}>
        {title && <SubTitle>{title}</SubTitle>}
        <MaterialTable
          onSelectionChange={onSelectionChange}
          isLoading={tableData.isLoading}
          icons={tableIcons}
          title={tableData.title}
          onRowClick={(e, row) => onRowClick && row && onRowClick(row)}
          columns={tableData.columns.map((column, index) => ({
            ...column,
            emptyValue: tKey('No hay datos'),
            defaultSort: index === columnOrder?.orderedColumnId ? columnOrder?.orderDirection : undefined,
          }))}
          data={tableData.data}
          detailPanel={tableData.detailPanel}
          onChangeRowsPerPage={pageSize => getPageSize?.(pageSize)}
          onSearchChange={searchText => getTextSearch?.(searchText)}
          onOrderChange={(orderedColumnId, orderDirection) => getOrderColumns?.(orderedColumnId, orderDirection)}
          options={{
            emptyRowsWhenPaging: false,
            tableLayout: 'fixed',
            actionsColumnIndex: -1,
            selection: tableData.options?.selection,
            pageSize: tableData.options?.pageSize || 5,
            searchText: tableData.options?.searchText,
            debounceInterval: 200,
            filtering: !!filtering,
            filterCellStyle: {
              padding: '10px 10px',
            },
            exportButton: exportButton,
            exportAllData,
            exportDelimiter: '\u0009',
            //rowStyle: rowStyle ? rowStyle : undefined,
            headerStyle: {
              borderTop: '1px solid rgba(224, 224, 224, 1)',
            },
            search: noSearch ? false : true,
            ...tableData.options,
          }}
          onChangePage={page => setPage && setPage(page)}
          localization={{
            body: {
              emptyDataSourceMessage: tableData?.isLoading ? '' : tKey('No hay resultados'),
              ...tableData?.localization?.body,
            },
            header: {
              actions: tKey('Opciones'),
            },
            toolbar: {
              searchPlaceholder: tKey('Buscar'),
              nRowsSelected: tKey(`{0} Fila/s selecionada/s`),
              exportName: tKey('Exportar a Excel'),
              exportTitle: tKey('Descargar'),
            },
            pagination: {
              labelRowsSelect: tKey('Filas'),
              firstAriaLabel: tKey('Primera Página'),
              firstTooltip: tKey('Primera Página'),
              nextAriaLabel: tKey('Siguiente Página'),
              nextTooltip: tKey('Siguiente Página'),
              lastAriaLabel: tKey('Última Página'),
              lastTooltip: tKey('Última Página'),
              previousAriaLabel: tKey('Anterior Página'),
              previousTooltip: tKey('Anterior Página'),
              labelDisplayedRows: tKey('{from}-{to} de {count}'),
            },
          }}
          editable={{ ...tableData.editable }}
          actions={checkActions()}
          components={{
            Toolbar: props =>
              noSearch ? (
                <React.Fragment></React.Fragment>
              ) : (
                <div className={styles.tableOverride}>
                  <MTableToolbar classes={{ searchField: styles.toolbar }} {...props} />
                </div>
              ),
          }}
        />
        {permissionOthers && optionsExport}
      </ThemeProvider>
    </div>
  );
};

export default TableWithEdit;
