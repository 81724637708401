import { ScholarshipPaymentsConst } from './action_types';
import {
  ChangeScholarshipPaymentDateAction,
  ChangeScholarshipPaymentDateActionKo,
  ChangeScholarshipPaymentDateActionOk,
  EntityScholarshipPaymentSendSageAction,
  EntityScholarshipPaymentSendSageKoAction,
  EntityScholarshipPaymentSendSageOkAction,
  GetEntityScholarshipPaymentsAction,
  GetEntityScholarshipPaymentsKoAction,
  GetEntityScholarshipPaymentsOkAction,
  GetInstituteScholarshipPaymentsAction,
  GetInstituteScholarshipPaymentsKoAction,
  GetInstituteScholarshipPaymentsOkAction,
  ReplaceScholarshipPaymentDatesAction,
  ReplaceScholarshipPaymentDatesKoAction,
  ReplaceScholarshipPaymentDatesOkAction,
  ScholarshipPaymentList,
  SetScholarshipPaymensReloadAction,
  SetSelectedAcademicYearAction,
  SetSelectedEntityIdAction,
  SetSelectedPaymentDateAction,
  SetSelectedPaymentStatusAction,
} from './definitions';
import { EntityPaymentSendSageData, PaymentDatesReplace, PaymentId } from './Payment';

export function getInstituteScholarshipPaymentsAction(
  academic_year: string,
  status: string,
): GetInstituteScholarshipPaymentsAction {
  return {
    type: ScholarshipPaymentsConst.GET_INSTITUTE_SCHOLARSHIP_PAYMENTS,
    academic_year,
    status,
  };
}

export function getInstituteScholarshipPaymentsOkAction(
  scholarshipPaymentList: ScholarshipPaymentList,
): GetInstituteScholarshipPaymentsOkAction {
  return {
    type: ScholarshipPaymentsConst.GET_INSTITUTE_SCHOLARSHIP_PAYMENTS_OK,
    scholarshipPaymentList,
  };
}

export function getInstituteScholarshipPaymentsKoAction(error: string): GetInstituteScholarshipPaymentsKoAction {
  return {
    type: ScholarshipPaymentsConst.GET_INSTITUTE_SCHOLARSHIP_PAYMENTS_KO,
    error,
  };
}

export function getEntityScholarshipPaymentsAction(
  academic_year: string,
  entity_id: number | null,
  status: string,
): GetEntityScholarshipPaymentsAction {
  return {
    type: ScholarshipPaymentsConst.GET_ENTITY_SCHOLARSHIP_PAYMENTS,
    academic_year,
    entity_id,
    status,
  };
}

export function getEntityScholarshipPaymentsOkAction(
  scholarshipPaymentList: ScholarshipPaymentList,
): GetEntityScholarshipPaymentsOkAction {
  return {
    type: ScholarshipPaymentsConst.GET_ENTITY_SCHOLARSHIP_PAYMENTS_OK,
    scholarshipPaymentList,
  };
}

export function getEntityScholarshipPaymentsKoAction(error: string): GetEntityScholarshipPaymentsKoAction {
  return {
    type: ScholarshipPaymentsConst.GET_ENTITY_SCHOLARSHIP_PAYMENTS_KO,
    error,
  };
}

export function setAcademicYearAction(academic_year: string): SetSelectedAcademicYearAction {
  return {
    type: ScholarshipPaymentsConst.SET_PAYMENTS_SELECTED_ACADEMIC_YEAR,
    academic_year,
  };
}

export function setPaymentStatusAction(payment_status: string): SetSelectedPaymentStatusAction {
  return {
    type: ScholarshipPaymentsConst.SET_PAYMENTS_SELECTED_STATUS,
    payment_status,
  };
}

export function setPaymentDateAction(payment_date: string | null): SetSelectedPaymentDateAction {
  return {
    type: ScholarshipPaymentsConst.SET_PAYMENTS_PAYMENT_DATE,
    payment_date,
  };
}

export function setPaymentEntityIdAction(entity_id: number | null): SetSelectedEntityIdAction {
  return {
    type: ScholarshipPaymentsConst.SET_PAYMENTS_SELECTED_ENTITY_ID,
    entity_id,
  };
}

export function changeScholarshipPaymentDateAction(
  payment_id: PaymentId,
  payment_date: string,
  is_entity: boolean,
): ChangeScholarshipPaymentDateAction {
  return {
    type: ScholarshipPaymentsConst.CHANGE_SCHOLARSHIP_PAYMENT_DATE,
    payment_id,
    payment_date,
    is_entity,
  };
}

export function changeScholarshipPaymentDateActionOk(
  payment_id: PaymentId,
  payment_date: string,
  is_entity: boolean,
): ChangeScholarshipPaymentDateActionOk {
  return {
    type: ScholarshipPaymentsConst.CHANGE_SCHOLARSHIP_PAYMENT_DATE_OK,
    payment_id,
    payment_date,
    is_entity,
  };
}

export function changeScholarshipPaymentDateActionKo(error: string): ChangeScholarshipPaymentDateActionKo {
  return {
    type: ScholarshipPaymentsConst.CHANGE_SCHOLARSHIP_PAYMENT_DATE_KO,
    error,
  };
}

export function replaceScholarshipPaymentDatesAction(
  paymentDates: PaymentDatesReplace,
): ReplaceScholarshipPaymentDatesAction {
  return {
    type: ScholarshipPaymentsConst.REPLACE_SCHOLARSHIPS_PAYMENT_DATES,
    paymentDates,
  };
}

export function replaceScholarshipPaymentDatesOkAction(): ReplaceScholarshipPaymentDatesOkAction {
  return {
    type: ScholarshipPaymentsConst.REPLACE_SCHOLARSHIPS_PAYMENT_DATES_OK,
  };
}

export function replaceScholarshipPaymentDatesKoAction(error: string): ReplaceScholarshipPaymentDatesKoAction {
  return {
    type: ScholarshipPaymentsConst.REPLACE_SCHOLARSHIPS_PAYMENT_DATES_KO,
    error,
  };
}

export function entityScholarshipPaymentSendSageAction(
  entity_id: number,
  entityPaymentSendSageData: EntityPaymentSendSageData,
): EntityScholarshipPaymentSendSageAction {
  return {
    type: ScholarshipPaymentsConst.ENTITY_SCHOLARSHIPS_PAYMENT_SEND_SAGE,
    entity_id,
    entityPaymentSendSageData,
  };
}

export function entityScholarshipPaymentSendSageOkAction(): EntityScholarshipPaymentSendSageOkAction {
  return {
    type: ScholarshipPaymentsConst.ENTITY_SCHOLARSHIPS_PAYMENT_SEND_SAGE_OK,
  };
}

export function entityScholarshipPaymentSendSageKoAction(error: string): EntityScholarshipPaymentSendSageKoAction {
  return {
    type: ScholarshipPaymentsConst.ENTITY_SCHOLARSHIPS_PAYMENT_SEND_SAGE_KO,
    error,
  };
}

export function setScholarshipPaymensReloadAction(): SetScholarshipPaymensReloadAction {
  return {
    type: ScholarshipPaymentsConst.SET_SCHOLARSHIPS_PAYMENTS_RELOAD,
  };
}
