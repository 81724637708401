import { ButtonProps, MenuItem } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import React, { FocusEvent, useEffect, useState } from 'react';
import { ManualFieldError, ValidateResult, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { APIValidationError } from '../../../../api/api';
import Address from '../../../../components/Address/Address';
import FormContainer from '../../../../components/Forms/FormContainer';
import TextInputController from '../../../../components/Inputs/TextInputController/TextInputController';
import { yesNoOptions } from '../../../../components/RadioButtons/RadioButtonOptions';
import RadioButtons from '../../../../components/RadioButtons/RadioButtons';
import SelectController from '../../../../components/Select/SelectController';
import Title from '../../../../components/Title/Title';
import FncAddDocuments from '../../../../components/UploadFile/FncAddDocuments';
import { optionalNames } from '../../../../constants/commons';
import * as DateConstants from '../../../../constants/date';
import { DATE_FORMAT } from '../../../../constants/date';
import { useButtonAction } from '../../../../helpers/customHooks/useHookMethod';
import useValidatorAPI from '../../../../helpers/customHooks/useValidatorAPI';
import { documentationFilter } from '../../../../helpers/docFilter';
import { tScholarshipKey } from '../../../../helpers/translate';
import { validateFields } from '../../../../helpers/validator';
import requiredStyle from '../../../../helpers/validator.module.scss';
import { selectIsNousCims } from '../../../../redux/auth/selectors';
import {
  CodeNamePair,
  DocType,
  DocumentationItem,
  IdNamePair,
  docTypesArray,
} from '../../../../redux/common/definitions';
import { GenericMetadata } from '../../../../redux/metadata/definitions';
import { selectGenericMetadata } from '../../../../redux/metadata/selectors';
import { AppState } from '../../../../redux/root-reducer';
import { scholarshipSetPersonalInfoData, scholarshipSubmitInformation } from '../../../../redux/scholarship/actions';
import {
  PersonalInformationFormType,
  ScholarshipData,
  ScholarshipMetadata,
  ScholarshipStateCode,
  ScholarshipSteps,
} from '../../../../redux/scholarship/definitions';
import {
  selectGetScholarshipDocumentation,
  selectGetScholarshipMetadata,
  selectScholarshipValidationErrors,
} from '../../../../redux/scholarship/selectors';
import Gender from '../../../../types/Shared/Gender';
import { entityPersonalInformation, schoolPersonalInformation } from '../../../../validations/scholarshipSchema';
import { validateIdentificationCard } from '../../../../validations/validatorUser';
import RenounceViewForm from './RenonuceViewForm';
import styles from './requestForms.module.scss';

interface PersonalInformationFormProps {
  scholarshipData: ScholarshipData;
  genericMetadata: GenericMetadata;
  scholarshipMetadata: ScholarshipMetadata;
  documentation: DocumentationItem[];
  readOnly: boolean;
  buttonActionSave: ButtonProps;
  validationErrors: APIValidationError | null;
  isNousCims: boolean;
  uploadDocument: (documentation_id: number, data: File, scholarshipId: number) => void;
  downloadDocument: (documentation_id: number, name: string, scholarship_id: number) => void;
  getFile: (documentation_id: number, name: string, scholarship_id: number) => void;
  deleteDocument: (documentation_id: number, scholarship_id: number) => void;
  addDoc: (document: File, description: string, genericId?: number, documentation_id?: number) => void;
  setData: (key: string, value: string | number) => void;
  saveData: (data: ScholarshipData) => void;
}

const PersonalInformationForm: React.FC<PersonalInformationFormProps> = ({
  scholarshipData,
  genericMetadata,
  scholarshipMetadata,
  documentation,
  readOnly,
  validationErrors,
  buttonActionSave,
  isNousCims,
  uploadDocument,
  downloadDocument,
  getFile,
  deleteDocument,
  addDoc,
  setData,
  saveData,
}) => {
  const { t } = useTranslation();

  const {
    state_code,
    student_name,
    student_surname,
    student_surname2,
    student_document_type,
    student_document_number,
    student_document_expedition_date,
    student_document_expedition_place,
    student_document_expiration_date,
    student_gender,
    student_birthdate,
    student_nationality,
    student_country_origin,
    student_address_type,
    student_address,
    student_flat,
    student_floor,
    student_room,
    student_staircase,
    student_zip_code,
    student_city,
    student_country,
    student_province,
    student_phone,
    student_phone2,
    student_email,
    student_email2,
    interview_entity,
    entity_name,
    educator_name,
    educator_surname,
    educator_email,
    educator_phone,
    renounce_comment,
    renounce_date,
    origin_academy_code,
    finished_educational_type_id,
    tutor_name,
    tutor_phone,
    tutor_email,
    tutor_document_number,
    tutor_document_type,
    isEntity,
    scholarshipType,
  } = scholarshipData;

  const { countries } = genericMetadata;
  const { educationalTypes, fnc_academies } = scholarshipMetadata;
  const doctTypesFiltered = docTypesArray.filter(dt => dt !== 'NIF');
  const addressData = {
    address_type: student_address_type,
    address: student_address,
    flat: student_flat,
    floor: student_floor,
    room: student_room,
    stairscase: student_staircase,
    zip_code: student_zip_code,
    city: student_city,
    country: student_country,
    province: student_province,
  };

  const needs_tutor = () => {
    const dateValue = student_birthdate ? moment.unix(student_birthdate) : null;
    return dateValue ? moment().diff(dateValue, 'years') < 18 : false;
  };

  const schemaPersonalInformation = isEntity ? entityPersonalInformation : schoolPersonalInformation(needs_tutor());

  const { handleSubmit, setValue, errors, setError, triggerValidation, clearError, control } = useForm<
    PersonalInformationFormType
  >({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: schemaPersonalInformation,
  });

  useEffect(() => {
    if (!isEntity) {
      const index = documentation?.findIndex(docu => docu.code === 'TNF');
      if (!index || !documentation || !documentation[index]) return;
      documentation[index].mandatory = needs_tutor();
    }
  }, [documentation, isEntity, student_birthdate]); // eslint-disable-line react-hooks/exhaustive-deps

  useValidatorAPI(validationErrors, setError);

  const savePersonalInfo = () => {
    saveData(scholarshipData);
  };

  useButtonAction(buttonActionSave, handleSubmit(savePersonalInfo));

  const [open, setOpen] = useState(false);
  const [openExpeditionDate, setOpenExpeditionDate] = useState(false);
  const [openExpirationDate, setOpenExpirationDate] = useState(false);

  const changeDate = (date: any) => {
    setData('student_birthdate', moment.utc(date || '0', DateConstants.DATE_FORMAT).format('X'));
  };

  const asingDateValue = (date: any) => {
    return date ? moment.unix(date) : null;
  };

  const handleSelectChange = (target: any) => {
    if (target.name) {
      setData(target.name, target.value);
    }
  };

  const validatorInfo = {
    data: scholarshipData,
    step: 'personalInformation' as keyof ScholarshipSteps,
  };

  const personalData = (
    <>
      <TextInputController
        control={control}
        errors={errors}
        defaultValue={student_name}
        schema={schemaPersonalInformation}
        validator={validateFields(scholarshipData, 'student_name', validatorInfo.step)}
        label={tScholarshipKey('Nombre')}
        name="student_name"
        size="50"
        disabled={readOnly}
        onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('student_name', e.target.value)}
      />
      <TextInputController
        control={control}
        errors={errors}
        defaultValue={student_surname}
        schema={schemaPersonalInformation}
        validator={validateFields(scholarshipData, 'student_surname', validatorInfo.step)}
        label={tScholarshipKey('Primer apellido')}
        name="student_surname"
        size="50"
        disabled={readOnly}
        onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('student_surname', e.target.value)}
      />

      <TextInputController
        control={control}
        errors={errors}
        defaultValue={student_surname2}
        schema={schemaPersonalInformation}
        validator={validateFields(scholarshipData, 'student_surname2', validatorInfo.step)}
        label={tScholarshipKey('Segundo apellido')}
        name="student_surname2"
        size="50"
        disabled={readOnly}
        onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('student_surname2', e.target.value)}
      />
      <TextInputController
        control={control}
        errors={errors}
        defaultValue={student_email}
        schema={schemaPersonalInformation}
        validator={validateFields(scholarshipData, 'student_email', validatorInfo.step)}
        label={tScholarshipKey('Email de contacto 1')}
        name="student_email"
        size="50"
        disabled={readOnly || !isEntity}
        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
          setData('student_email', e.target.value);
        }}
      />
      <SelectController
        control={control}
        name="student_document_type"
        label={tScholarshipKey('Tipo de documento')}
        defaultValue={student_document_type}
        size="50"
        schema={schemaPersonalInformation}
        validator={validateFields(scholarshipData, 'student_document_type', validatorInfo.step)}
        errors={errors}
        disabled={readOnly}
        onClick={e => handleSelectChange(e.target)}
        onBlur={(_e: FocusEvent<HTMLInputElement>) =>
          validateIdentificationCard(
            _e.target.value,
            student_document_number || '',
            'student_document_number',
            setError,
            clearError,
          )
        }
      >
        {doctTypesFiltered.map((element: DocType) => (
          <MenuItem key={element} value={element}>
            {element}
          </MenuItem>
        ))}
      </SelectController>
      <TextInputController
        control={control}
        errors={errors}
        defaultValue={student_document_number}
        schema={schemaPersonalInformation}
        validator={validateFields(scholarshipData, 'student_document_number', validatorInfo.step)}
        label={tScholarshipKey('Número de documento')}
        name="student_document_number"
        size="50"
        disabled={readOnly}
        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
          setData('student_document_number', e.target.value);
          validateIdentificationCard(
            student_document_type,
            e.target.value,
            'student_document_number',
            setError,
            clearError,
          );
        }}
      />

      {!isEntity ? (
        <>
          <KeyboardDatePicker
            error={!!errors.student_document_expiration_date}
            className={
              !!validateFields(scholarshipData, 'student_document_expiration_date', validatorInfo.step)
                ? requiredStyle.required_20
                : styles.size_20
            }
            variant="inline"
            onAccept={() => (!readOnly ? setOpenExpirationDate(false) : null)}
            onClick={() => (!readOnly ? setOpenExpirationDate(true) : null)}
            open={openExpirationDate}
            inputVariant="outlined"
            name="student_document_expiration_date"
            disabled={readOnly}
            label={tScholarshipKey('Fecha de expiración')}
            value={asingDateValue(student_document_expiration_date)}
            minDate={moment.now()}
            size="small"
            onChange={date => {
              date &&
                setData(
                  'student_document_expiration_date',
                  moment.utc(date || '0', DateConstants.DATE_FORMAT).format('X'),
                );
            }}
            format={DATE_FORMAT}
          />
          <KeyboardDatePicker
            error={!!errors.student_document_expedition_date}
            className={
              !!validateFields(scholarshipData, 'student_document_expedition_date', validatorInfo.step)
                ? requiredStyle.required_20
                : styles.size_20
            }
            variant="inline"
            inputVariant="outlined"
            onAccept={() => (!readOnly ? setOpenExpeditionDate(false) : null)}
            onClick={() => (!readOnly ? setOpenExpeditionDate(true) : null)}
            open={openExpeditionDate}
            label={tScholarshipKey('Fecha de expedición')}
            name="student_document_expedition_date"
            value={asingDateValue(student_document_expedition_date)}
            size="small"
            maxDate={moment.now()}
            disabled={readOnly}
            onChange={date => {
              date &&
                setData(
                  'student_document_expedition_date',
                  moment.utc(date || '0', DateConstants.DATE_FORMAT).format('X'),
                );
            }}
            format={DATE_FORMAT}
          />

          <TextInputController
            control={control}
            errors={errors}
            defaultValue={student_document_expedition_place}
            schema={schemaPersonalInformation}
            validator={validateFields(scholarshipData, 'student_document_expedition_place', validatorInfo.step)}
            label={tScholarshipKey('Lugar de expedición')}
            name="student_document_expedition_place"
            size="50"
            disabled={readOnly}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
              setData('student_document_expedition_place', e.target.value);
            }}
          />
        </>
      ) : null}

      <KeyboardDatePicker
        error={!!errors.student_birthdate}
        className={
          !!validateFields(scholarshipData, 'student_birthdate', validatorInfo.step)
            ? requiredStyle.required_50
            : styles.size_50
        }
        variant="inline"
        onAccept={() => (!readOnly ? setOpen(false) : null)}
        onClick={() => (!readOnly ? setOpen(true) : null)}
        open={open}
        inputVariant="outlined"
        name="student_birthdate"
        disabled={readOnly}
        label={tScholarshipKey('Fecha de nacimiento')}
        placeholder="" // Prevent google to detect this input as a credit card number
        value={asingDateValue(student_birthdate)}
        onChange={date => {
          date && changeDate(date);
        }}
        format={DATE_FORMAT}
      />
      <SelectController
        control={control}
        name="student_gender"
        label={tScholarshipKey('gender')}
        schema={schemaPersonalInformation}
        defaultValue={student_gender}
        validator={validateFields(scholarshipData, 'student_gender', validatorInfo.step)}
        size="50"
        errors={errors}
        disabled={readOnly}
        onClick={e => handleSelectChange(e.target)}
      >
        {Gender.toArray().map((element: string, index: number) => (
          <MenuItem key={index} value={element}>
            {tScholarshipKey(`gender.${element}`)}
          </MenuItem>
        ))}
      </SelectController>

      <SelectController
        control={control}
        name="student_nationality"
        label={tScholarshipKey('Nacionalidad')}
        schema={schemaPersonalInformation}
        defaultValue={student_nationality}
        size="50"
        validator={validateFields(scholarshipData, 'student_nationality', validatorInfo.step)}
        errors={errors}
        disabled={readOnly}
        onClick={e => handleSelectChange(e.target)}
      >
        {countries.map((element: string) => (
          <MenuItem key={element} value={element}>
            {element}
          </MenuItem>
        ))}
      </SelectController>
      <SelectController
        control={control}
        name="student_country_origin"
        label={tScholarshipKey('País de origen')}
        schema={schemaPersonalInformation}
        defaultValue={student_country_origin}
        size="50"
        validator={validateFields(scholarshipData, 'student_country_origin', validatorInfo.step)}
        errors={errors}
        disabled={readOnly}
        onClick={e => handleSelectChange(e.target)}
      >
        {countries.map((element: string) => (
          <MenuItem key={element} value={element}>
            {element}
          </MenuItem>
        ))}
      </SelectController>
    </>
  );

  let personalDataINS;
  let entityData;
  let documentsFiltered;
  let needsInterview;
  if (isEntity) {
    entityData = (
      <FormContainer title={tScholarshipKey('Entidad')}>
        <TextInputController
          control={control}
          errors={errors}
          defaultValue={entity_name}
          schema={schemaPersonalInformation}
          label={tScholarshipKey('Entidad')}
          name="entity_name"
          size="50"
          disabled
        />
        <TextInputController
          control={control}
          errors={errors}
          defaultValue={educator_name}
          schema={schemaPersonalInformation}
          label={tScholarshipKey('Nombre del educador/a')}
          name="educator_name"
          size="50"
          disabled
        />
        <TextInputController
          control={control}
          errors={errors}
          defaultValue={educator_surname}
          schema={schemaPersonalInformation}
          label={tScholarshipKey('Apellidos del educador/a')}
          name="educator_surname"
          size="50"
          disabled
        />
        <TextInputController
          control={control}
          errors={errors}
          defaultValue={educator_email}
          schema={schemaPersonalInformation}
          label={tScholarshipKey('Email del educador/a')}
          name="educator_email"
          size="50"
          disabled
        />
        <TextInputController
          control={control}
          errors={errors}
          defaultValue={educator_phone}
          schema={schemaPersonalInformation}
          label={tScholarshipKey('Teléfono del educador/a')}
          name="educator_phone"
          size="50"
          disabled
        />
      </FormContainer>
    );

    documentsFiltered = documentationFilter(documentation, ['NIF']);
  } else {
    personalDataINS = (
      <>
        <SelectController
          control={control}
          name="origin_academy_code"
          label={tScholarshipKey('Instituto en el que estudias')}
          defaultValue={origin_academy_code}
          schema={schemaPersonalInformation}
          size="50"
          validator={validateFields(scholarshipData, 'origin_academy_code', validatorInfo.step)}
          errors={errors}
          disabled={readOnly}
          onClick={e => handleSelectChange(e.target)}
        >
          {fnc_academies.map((element: CodeNamePair) => (
            <MenuItem key={element.code} value={element.code}>
              {element.name}
            </MenuItem>
          ))}
        </SelectController>
        <SelectController
          control={control}
          name="finished_educational_type_id"
          label={tScholarshipKey('Últimos estudios terminados')}
          defaultValue={finished_educational_type_id}
          schema={schemaPersonalInformation}
          size="50"
          validator={validateFields(scholarshipData, 'finished_educational_type_id', validatorInfo.step)}
          errors={errors}
          disabled={readOnly}
          onClick={e => handleSelectChange(e.target)}
        >
          {educationalTypes.map((element: IdNamePair) => (
            <MenuItem key={element.id} value={element.id}>
              {element.name}
            </MenuItem>
          ))}
        </SelectController>

        <TextInputController
          control={control}
          errors={errors}
          defaultValue={tutor_name}
          schema={schemaPersonalInformation}
          validator={validateFields(scholarshipData, 'tutor_name', validatorInfo.step)}
          label={tScholarshipKey('Nombre madre / padre / tutor')}
          name="tutor_name"
          size="50"
          disabled={readOnly}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('tutor_name', e.target.value)}
        />
        <TextInputController
          control={control}
          errors={errors}
          defaultValue={tutor_phone}
          schema={schemaPersonalInformation}
          validator={validateFields(scholarshipData, 'tutor_phone', validatorInfo.step)}
          label={tScholarshipKey('Teléfono madre / padre / tutor')}
          name="tutor_phone"
          size="50"
          disabled={readOnly}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('tutor_phone', e.target.value)}
        />

        <SelectController
          control={control}
          name="tutor_document_type"
          label={tScholarshipKey('Tipo de documento madre / padre / tutor')}
          defaultValue={tutor_document_type}
          schema={schemaPersonalInformation}
          size="50"
          errors={errors}
          disabled={readOnly}
          validator={validateFields(scholarshipData, 'tutor_document_type', validatorInfo.step)}
          onClick={e => handleSelectChange(e.target)}
          onBlur={(_e: FocusEvent<HTMLInputElement>) =>
            validateIdentificationCard(
              _e.target.value,
              tutor_document_number || '',
              'tutor_document_number',
              setError,
              clearError,
            )
          }
        >
          {doctTypesFiltered.map((element: DocType) => (
            <MenuItem key={element} value={element}>
              {element}
            </MenuItem>
          ))}
        </SelectController>
        <TextInputController
          control={control}
          errors={errors}
          defaultValue={tutor_document_number}
          schema={schemaPersonalInformation}
          validator={validateFields(scholarshipData, 'tutor_document_number', validatorInfo.step)}
          label={tScholarshipKey('Número de documento madre / padre / tutor')}
          name="tutor_document_number"
          size="50"
          disabled={readOnly}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            setData('tutor_document_number', e.target.value);
            validateIdentificationCard(
              tutor_document_type,
              e.target.value,
              'tutor_document_number',
              setError,
              clearError,
            );
          }}
        />

        <TextInputController
          control={control}
          errors={errors}
          defaultValue={tutor_email}
          schema={schemaPersonalInformation}
          validator={validateFields(scholarshipData, 'tutor_email', validatorInfo.step)}
          label={tScholarshipKey('Email madre / padre / tutor')}
          name="tutor_email"
          size="50"
          disabled={readOnly}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('tutor_email', e.target.value)}
        />
      </>
    );
    documentsFiltered = documentationFilter(documentation, ['NIF', 'TNF', 'IBB']);
  }

  if (isNousCims && !(state_code === ScholarshipStateCode.CREATION || state_code === ScholarshipStateCode.REQUEST)) {
    needsInterview = (
      <FormContainer title={tScholarshipKey('Otra información')}>
        <RadioButtons
          name="interview_entity"
          disabled={true}
          questionText={tScholarshipKey('¿La entrevista se ha realizado?')}
          options={yesNoOptions}
          defaultValue={!!interview_entity ? '1' : '0'}
        />
      </FormContainer>
    );
  }
  const addressDataInfo = (
    <FormContainer title={tScholarshipKey('Dirección')}>
      <Address
        control={control}
        errors={errors}
        genericMetadata={genericMetadata}
        setValue={setValue}
        disabled={readOnly}
        addressData={addressData}
        addressSchema={schemaPersonalInformation}
        optionalNames={optionalNames}
        triggerValidation={triggerValidation}
        validator={validatorInfo}
        setData={setData}
        setError={
          setError as (
            name: string | ManualFieldError<any>[],
            type?: Record<string, ValidateResult> | string,
            message?: string | undefined,
          ) => void
        }
      />
      <TextInputController
        control={control}
        errors={errors}
        disabled={readOnly}
        defaultValue={student_phone}
        schema={schemaPersonalInformation}
        label={tScholarshipKey('Teléfono de contacto 1')}
        name="student_phone"
        size="30"
        validator={validateFields(scholarshipData, 'student_phone', validatorInfo.step)}
        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
          setData('student_phone', e.target.value);
          triggerValidation('student_phone');
        }}
      />

      <div className={styles.intern_formContainer}>
        <TextInputController
          control={control}
          errors={errors}
          defaultValue={student_phone2}
          schema={schemaPersonalInformation}
          validator={validateFields(scholarshipData, 'student_phone2', validatorInfo.step)}
          label={tScholarshipKey('Teléfono de contacto 2')}
          name="student_phone2"
          size="50"
          disabled={readOnly}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('student_phone2', e.target.value)}
        />
        <TextInputController
          control={control}
          errors={errors}
          defaultValue={student_email2}
          schema={schemaPersonalInformation}
          validator={validateFields(scholarshipData, 'student_email2', validatorInfo.step)}
          label={tScholarshipKey('Email de contacto 2')}
          name="student_email2"
          size="50"
          disabled={readOnly}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('student_email2', e.target.value)}
        />
      </div>
    </FormContainer>
  );

  const endsWithoutCompleting = (stateCode: ScholarshipStateCode): boolean => {
    return [
      ScholarshipStateCode.CLOSURE,
      ScholarshipStateCode.DESTIMATED,
      ScholarshipStateCode.RENUNCIATION,
      ScholarshipStateCode.ABANDONMENT,
    ].includes(stateCode);
  };
  const renderRenounceView = (): JSX.Element | undefined => {
    if (!isNousCims) {
      return;
    }
    const stateCode =
      state_code === ScholarshipStateCode.PENDING_JUSTIFICATION && !!renounce_comment
        ? ScholarshipStateCode.ABANDONMENT
        : state_code;
    if (!endsWithoutCompleting(stateCode)) {
      return;
    }
    return (
      <RenounceViewForm renounce_comment={renounce_comment} renounce_date={renounce_date} state_code={stateCode} />
    );
  };
  return (
    <form onSubmit={e => e.preventDefault()} className={styles.form}>
      <Title>{tScholarshipKey('SOLICITUD DE BECA - DATOS DEL JOVEN')}</Title>
      <FormContainer
        title={tScholarshipKey('Datos personales')}
        subtitle={tScholarshipKey(t(`scholarship.${scholarshipType}.personal_information_form.subtitle`))}
      >
        {personalData}
        {personalDataINS}
      </FormContainer>
      {addressDataInfo}
      {entityData}
      {needsInterview}
      {documentsFiltered && (
        <FncAddDocuments
          title={tScholarshipKey('Documentos')}
          documents={documentsFiltered}
          getFile={getFile}
          disabled={readOnly}
          upload={uploadDocument}
          genericId={scholarshipData.id}
          download={downloadDocument}
          remove={deleteDocument}
          multidocument
          addDoc={addDoc}
          required
        />
      )}
      {renderRenounceView()}
    </form>
  );
};

const mapStateToProps = (state: AppState) => ({
  genericMetadata: selectGenericMetadata(state),
  scholarshipMetadata: selectGetScholarshipMetadata(state),
  documentation: selectGetScholarshipDocumentation(state),
  isNousCims: selectIsNousCims(state),
  validationErrors: selectScholarshipValidationErrors(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  setData: (key: string, value: string | number): void => dispatch(scholarshipSetPersonalInfoData(key, value)),
  saveData: (scholarshipData: ScholarshipData): void => dispatch(scholarshipSubmitInformation(scholarshipData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInformationForm);
