import React from 'react';
import MessageInfo from '../../../../../components/MessageInfo/MessageInfo';
import { tScholarshipKey } from '../../../../../helpers/translate';
import FollowUpCode from '../../../../../types/Scholarships/followUpCode';
import styles from './messageFollow.module.scss';
import { Link } from '@material-ui/core';

interface EconomicalValorationProps {
  followUpCode: FollowUpCode;
  scholarship_id: number;
  scholarship_tracking_id: number;
  student_name: string | null;
  student_surname: string | null;
  survey_submitted_at: string | null;
  first_follow_up_form_url: string | null;
  last_follow_up_form_url: string | null;
}

const MessageFollow: React.FC<EconomicalValorationProps> = ({
  followUpCode,
  scholarship_id,
  scholarship_tracking_id,
  student_name,
  student_surname,
  survey_submitted_at,
  first_follow_up_form_url,
  last_follow_up_form_url,
}) => {
  const renderSurveyMessage = (): JSX.Element | undefined => {
    if (!!survey_submitted_at) return;

    if (!(followUpCode.isFirstFollowUp() || followUpCode.isLastFollowUp())) return;

    const url = followUpCode.isFirstFollowUp() ? first_follow_up_form_url : last_follow_up_form_url;

    if (!url) return;

    const followUpIdParam = `followup_id=${scholarship_tracking_id}`;
    const nameParam = `student_name=${student_name} ${student_surname}`;
    const scholarshipParam = `scholarship_id=${scholarship_id}`;
    const envParam = `environment=${process.env.NODE_ENV}`;

    const urlWithParams = `${url}#${followUpIdParam}&${nameParam}&${scholarshipParam}&${envParam}`;
    return (
      <p className={styles.message}>
        {tScholarshipKey('MessageFollowSurvey')}
        <Link className={styles.link} href={urlWithParams} target="_blank" underline="none">
          {tScholarshipKey('MessageFollowUrlSurvey')}
        </Link>
      </p>
    );
  };
  return (
    <div className={styles.message_root}>
      <MessageInfo>{tScholarshipKey(`MessageFollow${followUpCode.value()}`)}</MessageInfo>
      <div className={styles.message_extension}>
        {renderSurveyMessage()}
        <p className={styles.message}>{tScholarshipKey('MessageFollowGratitude')}</p>
      </div>
    </div>
  );
};

export default MessageFollow;
