import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RejectScholarshipData, ScholarshipStateCode, ScholarshipData } from '../../../redux/scholarship/definitions';
import { tScholarshipKey } from '../../../helpers/translate';
import { KeyButton } from '../../../redux/common/definitions';
import { getRejectScholarshipInfo } from '../../../api/Scholarship/scholarship';
import RejectScholarshipModal from '../../Entities/RejectScholarshipModal';
import TransitionModal from '../../../components/Modal/TransitionModal';
import { getButtonType } from '../ButtonActions/ScholarshipButtonActions';
import { ScholarshipLeaveModes } from '../../../redux/scholarship/transitions/definitions';

interface Props {
  scholarshipData: ScholarshipData;
  handleRejectScholarship: (state: ScholarshipLeaveModes, modalData: RejectScholarshipData) => void;
}
const ChooseRejectScholarshipModals = ({ scholarshipData, handleRejectScholarship }: Props) => {
  const [modal, setModal] = useState(false);
  const [titleReject, setTitleReject] = useState('');
  const [dataReject, setDataReject] = useState<RejectScholarshipData>();
  const [stateReject, setStateReject] = useState<ScholarshipLeaveModes>('renounce');
  const { t } = useTranslation();

  const buttonRenounce = getButtonType(KeyButton.RENOUNCE);
  buttonRenounce.onClick = async e => {
    e.preventDefault();
    const data: RejectScholarshipData = await getRejectScholarshipInfo(
      ScholarshipStateCode.RENUNCIATION,
      scholarshipData.id,
    );
    setDataReject(data);
    setTitleReject(tScholarshipKey('motivo-de-renuncia'));
    setStateReject('renounce');
    setModal(true);
  };

  return (
    <TransitionModal
      view={modal}
      handleClose={() => {
        setModal(false);
      }}
      title={tScholarshipKey(t(`scholarship.shared.personal_information.renounce_comment`))}
      helperMode={true}
      bodyModal={''}
    >
      {dataReject && (
        <RejectScholarshipModal
          title={titleReject}
          rejectData={dataReject}
          handleClose={() => {
            setModal(false);
          }}
          handleSend={modalData => handleRejectScholarship(stateReject, modalData)}
        />
      )}
    </TransitionModal>
  );
};

export default ChooseRejectScholarshipModals;
