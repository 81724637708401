import { useState } from 'react';
import { RegisterFields, RolesProps } from '../../../redux/register/actions';
import { tKey } from '../../../helpers/translate';

type UseCreateUserModal = {
  userRole: () => string;
  userData: () => RegisterFields;
  showModal: () => boolean;
  closeModal: () => void;
  openModal: (roles: RolesProps[], data: RegisterFields) => void;
};

const useCreateUserModal = (): UseCreateUserModal => {
  const [role, setRole] = useState<string | null>(null);
  const [data, setData] = useState<RegisterFields | null>(null);

  const closeModal = () => {
    setRole(null);
    setData(null);
  };

  const openModal = (roles: RolesProps[], data: RegisterFields) => {
    const role = roles.find((role: RolesProps) => role.id.toString() === data.role_id.toString());
    if (role) {
      setData(data);
      const message = tKey('registerForm.role');
      setRole(message.replace('{rol}', role.name));
    }
  };

  const showModal = (): boolean => {
    return role !== null;
  };

  const userRole = (): string => {
    return role || '';
  };

  const userData = (): RegisterFields => {
    return data || ({} as RegisterFields);
  };

  return {
    userRole,
    userData,
    showModal,
    openModal,
    closeModal,
  };
};

export default useCreateUserModal;
