import { ButtonProps, MenuItem } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import FormContainer from '../../../../components/Forms/FormContainer';
import TextInput from '../../../../components/Inputs/TextInput';
import TextInputController from '../../../../components/Inputs/TextInputController/TextInputController';
import RadioButtonGroup from '../../../../components/RadioButtons/RadioButtonGroup';
import { yesNoOptions, zeroToFiveOptions } from '../../../../components/RadioButtons/RadioButtonOptions';
import SelectController from '../../../../components/Select/SelectController';
import Title from '../../../../components/Title/Title';
import { useButtonAction } from '../../../../helpers/customHooks/useHookMethod';
import { tFormKey, tKey, tScholarshipKey } from '../../../../helpers/translate';
import { IdNamePair } from '../../../../redux/common/definitions';
import { AppState } from '../../../../redux/root-reducer';
import { scholarshipSetAssessmentInfoData, scholarshipSubmitInformation } from '../../../../redux/scholarship/actions';
import { ScholarshipData, ScholarshipMetadata } from '../../../../redux/scholarship/definitions';
import { scholarshipStateTransitionAction } from '../../../../redux/scholarship/transitions/actions';
import { assessmentInfo } from '../../../../validations/assessmentSchema';
import styles from './assessmentForm.module.scss';
import { selectGetScholarshipMetadata } from '../../../../redux/scholarship/selectors';

interface AssessmentFormProps {
  scholarshipData: ScholarshipData;
  scholarshipMetadata: ScholarshipMetadata;
  readOnly: boolean;
  buttonActionSave: ButtonProps;
  buttonActionSendAssessment: ButtonProps;
  setData: (key: string, value: string | number) => void;
  saveData: (data: ScholarshipData) => void;
  sendAssessment: (id: number, scholarshipData?: ScholarshipData) => void;
}

const scholarshipContribOptions = ['Sí', 'No'];
const scholarshipProposalOptions = ['Sí', 'No', 'No está claro'];

const AssessmentForm: React.FC<AssessmentFormProps> = ({
  scholarshipData,
  scholarshipMetadata,
  readOnly,
  buttonActionSave,
  buttonActionSendAssessment,
  setData,
  saveData,
  sendAssessment,
}) => {
  const {
    id,
    assessment_user,
    referent_id,
    scholarship_risk,
    scholarship_risk_comm,
    scholarship_proposal,
    scholarship_proposal_comm,
    scholarship_mentoring,
    scholarship_mentoring_comm,
    scholarship_contrib,
    scholarship_contrib_comm,
    scholarship_num_nouscims,
    scholarship_committee,
    scholarship_tracking,
    scholarship_motivation,
    scholarship_motivation_com,
    scholarship_skill,
    scholarship_skills_comm,
    scholarship_socialrisc,
    scholarship_socialrisc_com,
    scholarship_impact,
    scholarship_impact_comm,
    scholarship_eco_sit,
    scholarship_eco_sit_comm,
    top_student,
    top_student_comment,
    is_top,
    deserves_grant,
    reason_grant_approved_rejected,
    scholarship_type,
    isEntity,
  } = scholarshipData;

  const { referring_users } = scholarshipMetadata;

  const { register, control, errors, handleSubmit, clearError } = useForm<ScholarshipData>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: assessmentInfo,
  });

  interface MentoringOptionItem {
    value: string;
    label: string;
  }

  const mentoringOptions: MentoringOptionItem[] = [
    { value: '0', label: tKey('No') },
    { value: '1', label: tKey('Sí') },
    { value: '2', label: tKey('No sabe') },
  ];

  const scholarship_type_options = [
    { value: 'ZING', label: 'ZING' },
    { value: 'ZING STEM', label: 'ZING STEM' },
    { value: 'ZING Online', label: 'ZING Online' },
    { value: 'ZING F5', label: 'ZING F5' },
    { value: 'ZING Concertada', label: tScholarshipKey('ZING Concertada') },
    { value: 'ZING TECH', label: 'ZING TECH' },
    { value: 'ZING Rural', label: 'ZING Rural' },
  ];

  const handleSelectChange = (target: any) => {
    if (target.name) {
      setData(target.name, target.value);
    }
  };

  const saveAssessmentInfo = () => {
    clearError();
    saveData(scholarshipData);
  };

  const sendAssessmentInfo = () => {
    clearError();
    sendAssessment(id, scholarshipData);
  };

  buttonActionSave.hidden = readOnly;

  useButtonAction(buttonActionSave, saveAssessmentInfo);
  useButtonAction(buttonActionSendAssessment, handleSubmit(sendAssessmentInfo));

  const renderSherpaInfo = (): JSX.Element | undefined => {
    if (!is_top) {
      return;
    }
    return (
      <>
        <FormContainer className={styles.halfContainer} title={tFormKey('Comentarios sherpa')}>
          <TextInput
            defaultValue={is_top}
            label={tFormKey('ip.tabla_participantes_joven_top')}
            name="is_top"
            size="50"
            disabled
          />
          <TextInput
            defaultValue={deserves_grant}
            label={tFormKey('ip.tabla_participantes_estado_beca')}
            name="deserves_grant"
            size="50"
            disabled
          />
        </FormContainer>
        <TextInput
          defaultValue={reason_grant_approved_rejected}
          label={tFormKey('ip.tabla_participantes_motivo_beca')}
          name="reason_grant_approved_rejected"
          fullWidth
          rows="4"
          rowsMax="10"
          multiline
          disabled
        />
      </>
    );
  };

  const renderReferentUsers = (): JSX.Element | undefined => {
    if (isEntity) {
      return;
    }
    return (
      <SelectController
        control={control}
        name="referent_id"
        label={tScholarshipKey('Assessment.referent_id')}
        defaultValue={referent_id}
        size="30"
        schema={assessmentInfo}
        errors={errors}
        disabled={readOnly}
        onClick={e => handleSelectChange(e.target)}
      >
        {referring_users.map((element: IdNamePair) => (
          <MenuItem key={element.id} value={element.id}>
            {element.name}
          </MenuItem>
        ))}
      </SelectController>
    );
  };

  return (
    <div className={styles.containerWithMenu}>
      <div className={styles.container}>
        <Title>{tScholarshipKey('Valoración')}</Title>

        <form>
          <FormContainer title="">
            <TextInputController
              control={control}
              errors={errors}
              defaultValue={assessment_user}
              schema={assessmentInfo}
              label={tScholarshipKey('Valorada por')}
              name="assessment_user"
              size={isEntity ? '50' : '30'}
              disabled
            />
            {renderReferentUsers()}
            <SelectController
              control={control}
              name="scholarship_type"
              label={tScholarshipKey('Tipo de beca')}
              defaultValue={scholarship_type}
              size={isEntity ? '50' : '30'}
              schema={assessmentInfo}
              errors={errors}
              disabled={readOnly}
              onClick={e => handleSelectChange(e.target)}
            >
              {scholarship_type_options.map(element => (
                <MenuItem key={element.value} value={element.value}>
                  {element.label}
                </MenuItem>
              ))}
            </SelectController>
          </FormContainer>
          {renderSherpaInfo()}
          <FormContainer title="">
            <RadioButtonGroup
              name="scholarship_risk"
              errors={errors.scholarship_risk}
              disabled={readOnly}
              questionText={tScholarshipKey('Riesgo Beca')}
              options={zeroToFiveOptions}
              value={scholarship_risk?.toString() || '0'}
              register={register}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setData('scholarship_risk', e.target.value);
              }}
            />
            <TextInputController
              control={control}
              schema={assessmentInfo}
              errors={errors}
              fullWidth
              rows="4"
              rowsMax="10"
              disabled={readOnly}
              label={tScholarshipKey('Comentario (Riesgo Beca)')}
              name="scholarship_risk_comm"
              multiline
              defaultValue={scholarship_risk_comm}
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('scholarship_risk_comm', e.target.value)}
            />
          </FormContainer>
          <FormContainer title="">
            <SelectController
              control={control}
              name="scholarship_proposal"
              label={tScholarshipKey('Propuesta')}
              defaultValue={scholarship_proposal}
              size="50"
              schema={assessmentInfo}
              errors={errors}
              disabled={readOnly}
              onClick={e => handleSelectChange(e.target)}
            >
              {scholarshipProposalOptions.map(element => (
                <MenuItem key={element} value={element}>
                  {tScholarshipKey(element)}
                </MenuItem>
              ))}
            </SelectController>
            <TextInputController
              control={control}
              schema={assessmentInfo}
              errors={errors}
              fullWidth
              rows="4"
              rowsMax="10"
              disabled={readOnly}
              label={tScholarshipKey('Comentario (Propuesta)')}
              name="scholarship_proposal_comm"
              multiline
              defaultValue={scholarship_proposal_comm}
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('scholarship_proposal_comm', e.target.value)}
            />
          </FormContainer>

          <FormContainer title="">
            <SelectController
              control={control}
              name="scholarship_mentoring"
              label={tScholarshipKey('Mentoría')}
              defaultValue={scholarship_mentoring?.toString()}
              size="50"
              schema={assessmentInfo}
              errors={errors}
              disabled={readOnly}
              onClick={e => handleSelectChange(e.target)}
            >
              {mentoringOptions.map((moi: MentoringOptionItem) => (
                <MenuItem key={moi.label} value={moi.value}>
                  {moi.label}
                </MenuItem>
              ))}
            </SelectController>
            <TextInputController
              control={control}
              schema={assessmentInfo}
              errors={errors}
              fullWidth
              rows="4"
              rowsMax="10"
              disabled={readOnly}
              label={tScholarshipKey('Comentario (Mentoría)')}
              name="scholarship_mentoring_comm"
              multiline
              defaultValue={scholarship_mentoring_comm}
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('scholarship_mentoring_comm', e.target.value)}
            />
          </FormContainer>

          <FormContainer title="">
            <SelectController
              control={control}
              name="scholarship_contrib"
              label={tScholarshipKey('Aportación Joven')}
              defaultValue={scholarship_contrib}
              size="50"
              schema={assessmentInfo}
              errors={errors}
              disabled={readOnly}
              onClick={e => handleSelectChange(e.target)}
            >
              {scholarshipContribOptions.map(element => (
                <MenuItem key={element} value={element}>
                  {tScholarshipKey(element)}
                </MenuItem>
              ))}
            </SelectController>
            <TextInputController
              control={control}
              schema={assessmentInfo}
              errors={errors}
              fullWidth
              rows="4"
              rowsMax="10"
              disabled={readOnly}
              label={tScholarshipKey('Comentario (Aportación)')}
              name="scholarship_contrib_comm"
              multiline
              defaultValue={scholarship_contrib_comm}
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('scholarship_contrib_comm', e.target.value)}
            />
            <RadioButtonGroup
              name="scholarship_num_nouscims"
              errors={errors.scholarship_num_nouscims}
              disabled={readOnly}
              questionText={tScholarshipKey('Número Nous Cims')}
              options={zeroToFiveOptions}
              value={scholarship_num_nouscims?.toString() || '0'}
              register={register}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setData('scholarship_num_nouscims', e.target.value);
              }}
            />
          </FormContainer>
          <FormContainer title="">
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <RadioButtonGroup
                name="scholarship_committee"
                errors={errors.scholarship_committee}
                disabled={readOnly}
                questionText={tScholarshipKey('¿Tiene que pasar por el comité?')}
                options={yesNoOptions}
                value={scholarship_committee?.toString() || '0'}
                register={register}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setData('scholarship_committee', e.target.value);
                }}
              />
              <RadioButtonGroup
                name="scholarship_tracking"
                errors={errors.scholarship_tracking}
                disabled={readOnly}
                questionText={tScholarshipKey('¿Necesita seguimiento especial 1º trimestre?')}
                options={yesNoOptions}
                value={scholarship_tracking?.toString() || '0'}
                register={register}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setData('scholarship_tracking', e.target.value);
                }}
              />
              <RadioButtonGroup
                name="scholarship_motivation"
                errors={errors.scholarship_motivation}
                disabled={readOnly}
                questionText={tScholarshipKey('Motivación')}
                options={zeroToFiveOptions}
                value={scholarship_motivation?.toString() || '0'}
                register={register}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setData('scholarship_motivation', e.target.value);
                }}
              />
              <TextInputController
                control={control}
                schema={assessmentInfo}
                errors={errors}
                fullWidth
                rows="4"
                rowsMax="10"
                disabled={readOnly}
                label={tScholarshipKey('Comentario (Motivación)')}
                name="scholarship_motivation_com"
                multiline
                defaultValue={scholarship_motivation_com}
                onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                  setData('scholarship_motivation_com', e.target.value)
                }
              />
            </div>
            <RadioButtonGroup
              name="scholarship_skill"
              errors={errors.scholarship_skill}
              disabled={readOnly}
              questionText={tScholarshipKey('Competencias y talentos')}
              options={zeroToFiveOptions}
              value={scholarship_skill?.toString() || '0'}
              register={register}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setData('scholarship_skill', e.target.value);
              }}
            />
            <TextInputController
              control={control}
              schema={assessmentInfo}
              errors={errors}
              fullWidth
              rows="4"
              rowsMax="10"
              disabled={readOnly}
              label={tScholarshipKey('Comentario (Competencias y talentos)')}
              name="scholarship_skills_comm"
              multiline
              defaultValue={scholarship_skills_comm}
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('scholarship_skills_comm', e.target.value)}
            />
          </FormContainer>
          <FormContainer title="">
            <RadioButtonGroup
              name="scholarship_socialrisc"
              errors={errors.scholarship_socialrisc}
              disabled={readOnly}
              questionText={tScholarshipKey('Riesgo Social')}
              options={zeroToFiveOptions}
              value={scholarship_socialrisc?.toString() || '0'}
              register={register}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setData('scholarship_socialrisc', e.target.value);
              }}
            />
            <TextInputController
              control={control}
              schema={assessmentInfo}
              errors={errors}
              fullWidth
              rows="4"
              rowsMax="10"
              disabled={readOnly}
              label={tScholarshipKey('Comentario (Riesgo Social)')}
              name="scholarship_socialrisc_com"
              multiline
              defaultValue={scholarship_socialrisc_com}
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('scholarship_socialrisc_com', e.target.value)}
            />
          </FormContainer>
          <FormContainer title="">
            <RadioButtonGroup
              name="scholarship_impact"
              errors={errors.scholarship_impact}
              disabled={readOnly}
              questionText={tScholarshipKey('Impacto Beca')}
              options={zeroToFiveOptions}
              value={scholarship_impact?.toString() || '0'}
              register={register}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setData('scholarship_impact', e.target.value);
              }}
            />
            <TextInputController
              control={control}
              schema={assessmentInfo}
              errors={errors}
              fullWidth
              rows="4"
              rowsMax="10"
              disabled={readOnly}
              label={tScholarshipKey('Comentario (Impacto Beca)')}
              name="scholarship_impact_comm"
              multiline
              defaultValue={scholarship_impact_comm}
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('scholarship_impact_comm', e.target.value)}
            />
          </FormContainer>

          <FormContainer title="">
            <RadioButtonGroup
              name="scholarship_eco_sit"
              errors={errors.scholarship_eco_sit}
              disabled={readOnly}
              questionText={tScholarshipKey('Situación Económica')}
              options={zeroToFiveOptions}
              value={scholarship_eco_sit?.toString() || '0'}
              register={register}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setData('scholarship_eco_sit', e.target.value);
              }}
            />
            <TextInputController
              control={control}
              schema={assessmentInfo}
              errors={errors}
              fullWidth
              rows="4"
              rowsMax="10"
              disabled={readOnly}
              label={tScholarshipKey('Comentario (Situación Económica)')}
              name="scholarship_eco_sit_comm"
              multiline
              defaultValue={scholarship_eco_sit_comm}
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('scholarship_eco_sit_comm', e.target.value)}
            />
          </FormContainer>
          <FormContainer title="">
            <RadioButtonGroup
              name="top_student"
              errors={errors.top_student}
              disabled={readOnly}
              questionText={tScholarshipKey('¿Es joven TOP?')}
              options={yesNoOptions}
              value={top_student?.toString() || '0'}
              register={register}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setData('top_student', e.target.value);
              }}
            />
            <TextInputController
              control={control}
              schema={assessmentInfo}
              errors={errors}
              fullWidth
              rows="4"
              rowsMax="10"
              disabled={readOnly || (top_student ? top_student.toString() : '0') !== '1'}
              label={tScholarshipKey('Comentario (TOP Estudiante)')}
              name="top_student_comment"
              multiline
              defaultValue={top_student_comment}
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('top_student_comment', e.target.value)}
            />
          </FormContainer>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  scholarshipMetadata: selectGetScholarshipMetadata(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  setData: (key: string, value: string | number): void => dispatch(scholarshipSetAssessmentInfoData(key, value)),
  saveData: (scholarshipData: ScholarshipData): void => dispatch(scholarshipSubmitInformation(scholarshipData)),
  sendAssessment: (id: number, scholarshipData?: ScholarshipData): void =>
    dispatch(scholarshipStateTransitionAction(id, 's05s08', undefined, scholarshipData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentForm);
