import { MenuItem } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { Control, FieldErrors, FieldValues } from 'react-hook-form/dist/types';
import { ObjectSchema, Shape } from 'yup';
import FormContainer from '../../../../components/Forms/FormContainer';
import SelectController from '../../../../components/Select/SelectController';
import { tScholarshipKey } from '../../../../helpers/translate';
import { IdNamePair } from '../../../../redux/common/definitions';
import { ScholarshipFollow, ScholarshipMetadata } from '../../../../redux/scholarship/definitions';

interface FuturePlansProps<T extends FieldValues> {
  scholarshipFollow: ScholarshipFollow;
  scholarshipMetadata: ScholarshipMetadata;
  readOnly?: boolean;
  errors: FieldErrors<T>;
  control: Control<T>;
  schema: ObjectSchema<T> | ObjectSchema<Shape<object, any>>;
  setDataFollow: (subKey: string, value: string | number | null) => void;
}

const FuturePlans = <T extends FieldValues>({
  scholarshipFollow,
  readOnly,
  scholarshipMetadata,
  errors,
  control,
  schema,
  setDataFollow,
}: FuturePlansProps<T>): ReactElement => {
  const { end_scholarship_future_actions_id } = scholarshipFollow;

  const { futureStudentPlans } = scholarshipMetadata;

  const handleSelectChange = (target: any) => {
    if (target.name) {
      setDataFollow(target.name, target.value);
    }
  };

  return (
    <FormContainer title={tScholarshipKey('Planes futuros del/la joven')}>
      <SelectController
        control={control}
        name="end_scholarship_future_actions_id"
        label={tScholarshipKey('planes-año-siguiente')}
        defaultValue={end_scholarship_future_actions_id}
        size="60"
        validator={!readOnly}
        disabled={readOnly}
        schema={schema}
        errors={errors}
        onClick={e => handleSelectChange(e.target)}
      >
        {futureStudentPlans.map((element: IdNamePair) => (
          <MenuItem key={element.id} value={element.id}>
            {element.name}
          </MenuItem>
        ))}
      </SelectController>
    </FormContainer>
  );
};

export default FuturePlans;
