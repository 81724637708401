import { ButtonProps } from '@material-ui/core';
import { ContactSupport, HelpOutline } from '@material-ui/icons';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import CheckBox from '@material-ui/icons/CheckBox';
import React from 'react';
import { tKey, tScholarshipKey } from '../../../helpers/translate';
import { KeyButton } from '../../../redux/common/definitions';

const buttonDefault: ButtonProps = {};

const buttonHelp: ButtonProps = {
  startIcon: <HelpOutline />,
  color: 'primary',
  disableElevation: true,
  fullWidth: true,
  key: KeyButton.HELP,
};

const buttonTracking: ButtonProps = {
  startIcon: <CheckBox />,
  fullWidth: true,
  color: 'primary',
  key: KeyButton.TRACKING,
};

const buttonZinger: ButtonProps = {
  startIcon: <AssignmentIndIcon />,
  color: 'primary',
  disableElevation: true,
  fullWidth: true,
  key: KeyButton.ZINGER,
};
const buttonTransversalCompetences: ButtonProps = {
  startIcon: <ContactSupport />,
  fullWidth: true,
  color: 'primary',
  key: KeyButton.TRANSVERSAL_COMPETENCES,
};

export const getMoreInfoButtonType = (type: KeyButton): ButtonProps => {
  switch (type) {
    case KeyButton.HELP:
      return buttonHelp;
    case KeyButton.TRACKING:
      return buttonTracking;
    case KeyButton.ZINGER:
      return buttonZinger;
    case KeyButton.TRANSVERSAL_COMPETENCES:
      return buttonTransversalCompetences;
    default:
      return buttonDefault;
  }
};

const initButtons = () => {
  buttonHelp.children = tKey('Ayuda');
  buttonTracking.children = tKey('Tracking');
  buttonZinger.children = tScholarshipKey('ver-zinger');
  buttonTransversalCompetences.children = tScholarshipKey('ver_competencias');
};

export const getMoreInfoButtons = (isNouscims: boolean, isInstitut: boolean) => {
  const moreInfo: ButtonProps[] = [];
  initButtons();

  if (isNouscims) {
    moreInfo.push(buttonTracking);
  }
  if (!isInstitut) {
    moreInfo.push(buttonTransversalCompetences);
  }
  moreInfo.push(buttonZinger);
  moreInfo.push(buttonHelp);
  return moreInfo;
};
