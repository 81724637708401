import moment from 'moment';
import { APIValidationError } from '../../api/api';
import { FileItem } from '../common/definitions';
import { AccountingConsts } from './action_types';
import {
  AcceptInvoiceAction,
  AcceptInvoiceKoAction,
  AcceptInvoiceOkAction,
  AccountingSettings,
  AuthorizeInvoiceAction,
  AuthorizeInvoiceKoAction,
  AuthorizeInvoiceOkAction,
  ChangeState,
  ChangeStateKo,
  ChangeStateOk,
  CloseInvoicesAction,
  CloseInvoicesKoAction,
  CloseInvoicesOkAction,
  CloseModalAction,
  ClosePaymentModalAction,
  ConfirmInvoiceAction,
  ConfirmInvoiceKoAction,
  ConfirmInvoiceOkAction,
  CreateExpenseAccountAction,
  CreateExpenseAccountKoAction,
  CreateExpenseAccountOkAction,
  CreateInvoiceAction,
  CreateInvoiceKoAction,
  CreateInvoiceOkAction,
  CreateNewInvoiceDetailAction,
  CreateNewInvoiceDetailKoAction,
  CreateNewInvoiceDetailOkAction,
  DeleteExpenseAccountAction,
  DeleteExpenseAccountKoAction,
  DeleteExpenseAccountOkAction,
  DeleteInvoiceDataAction,
  DeleteInvoiceDataKoAction,
  DeleteInvoiceDataOkAction,
  DeleteInvoiceDetailAction,
  DeleteInvoiceDetailKoAction,
  DeleteInvoiceDetailOkAction,
  DownloadInvoiceDocumentAction,
  DownloadInvoiceDocumentKoAction,
  DownloadInvoiceDocumentOkAction,
  EditAccountingSettingsAction,
  EditAccountingSettingsKoAction,
  EditAccountingSettingsOkAction,
  EditExpenseAccountAction,
  EditExpenseAccountKoAction,
  EditExpenseAccountOkAction,
  EditInvoiceDataAction,
  EditInvoiceDataKoAction,
  EditInvoiceDataOkAction,
  EditInvoiceDetailAction,
  EditInvoiceDetailKoAction,
  EditInvoiceDetailOkAction,
  ExpenseAccount,
  GetAccountingSettingsAction,
  GetAccountingSettingsKoAction,
  GetAccountingSettingsOkAction,
  GetExpenseAccountListAction,
  GetExpenseAccountListKoAction,
  GetExpenseAccountListOkAction,
  GetInvoiceDataAction,
  GetInvoiceDataKoAction,
  GetInvoiceDataOkAction,
  GetInvoiceDetailAction,
  GetInvoiceDetailKoAction,
  GetInvoiceDetailOkAction,
  GetInvoicesDetailListAction,
  GetInvoicesDetailListKoAction,
  GetInvoicesDetailListOkAction,
  GetOneExpenseAccountAction,
  GetOneExpenseAccountKoAction,
  GetOneExpenseAccountOkAction,
  GetOnePendingInvoiceAction,
  GetOnePendingInvoiceKoAction,
  GetOnePendingInvoiceOkAction,
  GetPendingToSendInvoicesAction,
  GetPendingToSendInvoicesKoAction,
  GetPendingToSendInvoicesOkAction,
  GetScholarshipSettingAction,
  GetScholarshipSettingKoAction,
  GetScholarshipSettingOkAction,
  InvoiceData,
  InvoiceDetail,
  InvoiceDetailSetDataAction,
  InvoiceListItem,
  InvoiceSetDataAction,
  InvoiceValidationErrorsAction,
  MarkInvoiceAsPaidAction,
  MarkInvoiceAsPaidKoAction,
  MarkInvoiceAsPaidOkAction,
  MoveBackInvoiceAction,
  MoveBackInvoiceOkAction,
  MoveBackInvoiceKoAction,
  OpenModalAction,
  OpenPaymentModalAction,
  PayInvoicesAction,
  PayInvoicesOkAction,
  PendingInvoiceData,
  RemoveInvoiceDocumentAction,
  RemoveInvoiceDocumentKoAction,
  RemoveInvoiceDocumentOkAction,
  RemovePendingInvoiceAction,
  RemovePendingInvoiceKoAction,
  ResetInvoiceDetailAction,
  ScholarshipSetDataSettingsAction,
  ScholarshipSetting,
  ScholarshipSettingValidationErrorsAction,
  SendInvoiceAction,
  SendInvoiceKoAction,
  SendInvoiceOkAction,
  SendInvoicesToPayAction,
  SendInvoicesToPayOkAction,
  SetInsScholarshipsPaymentDateFilterAction,
  SetOnePendingInvoiceAction,
  ToPayInvoiceAction,
  ToPayInvoiceKoAction,
  ToPayInvoiceOkAction,
  UpdatePendingInvoiceAction,
  UpdatePendingInvoiceKoAction,
  UpdateScholarshipSettingAction,
  UpdateScholarshipSettingKoAction,
  UpdateScholarshipSettingOkAction,
  UploadInvoiceDocumentAction,
  UploadInvoiceDocumentKoAction,
  UploadInvoiceDocumentOkAction,
  DownloadInvoiceContractKoAction,
  DownloadInvoiceContractAction,
  DownloadInvoiceContractOkAction,
} from './definitions';

//GET EXPENSES ACCOUNT LIST

export function getExpenseAccountList(): GetExpenseAccountListAction {
  return {
    type: AccountingConsts.GET_EXPENSE_ACCOUNT_LIST,
  };
}

export function getExpenseAccountListOk(expenseAccountList: ExpenseAccount[]): GetExpenseAccountListOkAction {
  return {
    type: AccountingConsts.GET_EXPENSE_ACCOUNT_LIST_OK,
    expenseAccountList,
  };
}
export function getExpenseAccountListKo(error: string): GetExpenseAccountListKoAction {
  return {
    type: AccountingConsts.GET_EXPENSE_ACCOUNT_LIST_KO,
    error,
  };
}

//  CREATE EXPENSE ACCOUNT
export function createExpenseAccount(newExpenseAccount: ExpenseAccount): CreateExpenseAccountAction {
  return {
    type: AccountingConsts.CREATE_EXPENSE_ACCOUNT,
    newExpenseAccount,
  };
}

export function createExpenseAccountOk(): CreateExpenseAccountOkAction {
  return {
    type: AccountingConsts.CREATE_EXPENSE_ACCOUNT_OK,
  };
}

export function createExpenseAccountKo(error: string): CreateExpenseAccountKoAction {
  return {
    type: AccountingConsts.CREATE_EXPENSE_ACCOUNT_KO,
    error,
  };
}

// DELETE EXPENSE ACCOUNT

export function deleteExpenseAccount(id: number): DeleteExpenseAccountAction {
  return {
    type: AccountingConsts.DELETE_EXPENSE_ACCOUNT,
    id,
  };
}

export function deleteExpenseAccountOk(id: number): DeleteExpenseAccountOkAction {
  return {
    type: AccountingConsts.DELETE_EXPENSE_ACCOUNT_OK,
    id,
  };
}

export function deleteExpenseAccountKo(error: string): DeleteExpenseAccountKoAction {
  return {
    type: AccountingConsts.DELETE_EXPENSE_ACCOUNT_KO,
    error,
  };
}

// GET ONE EXPENSE ACCOUNT

export function getOneExpenseAccount(id: number): GetOneExpenseAccountAction {
  return {
    type: AccountingConsts.GET_ONE_EXPENSE_ACCOUNT,
    id,
  };
}

export function getOneExpenseAccountOk(expenseAccount: ExpenseAccount): GetOneExpenseAccountOkAction {
  return {
    type: AccountingConsts.GET_ONE_EXPENSE_ACCOUNT_OK,
    expenseAccount,
  };
}

export function getOneExpenseAccountKo(error: string): GetOneExpenseAccountKoAction {
  return {
    type: AccountingConsts.GET_ONE_EXPENSE_ACCOUNT_KO,
    error,
  };
}

// EDIT EXPENSE ACCOUNT

export function editExpenseAccount(expenseAccount: ExpenseAccount, id: number): EditExpenseAccountAction {
  return {
    type: AccountingConsts.EDIT_EXPENSE_ACCOUNT,
    expenseAccount,
    id,
  };
}

export function editExpenseAccountOk(expenseAccount: ExpenseAccount): EditExpenseAccountOkAction {
  return {
    type: AccountingConsts.EDIT_EXPENSE_ACCOUNT_OK,
    expenseAccount,
  };
}

export function editExpenseAccountKo(error: string): EditExpenseAccountKoAction {
  return {
    type: AccountingConsts.EDIT_EXPENSE_ACCOUNT_KO,
    error,
  };
}

// GET ACCOUNT SETTINGS

export function getAccountingSettings(): GetAccountingSettingsAction {
  return {
    type: AccountingConsts.GET_ACCOUNTING_SETTINGS,
  };
}

export function getAccountingSettingsOk(accountingSettings: AccountingSettings): GetAccountingSettingsOkAction {
  return {
    type: AccountingConsts.GET_ACCOUNTING_SETTINGS_OK,
    accountingSettings,
  };
}

export function getAccountingSettingsKo(error: string): GetAccountingSettingsKoAction {
  return {
    type: AccountingConsts.GET_ACCOUNTING_SETTINGS_KO,
    error,
  };
}

// EDIT ACCOUNTING SETTINGS

export function editAccountingSettings(accountingSettings: AccountingSettings): EditAccountingSettingsAction {
  return {
    type: AccountingConsts.EDIT_ACCOUNTING_SETTINGS,
    accountingSettings,
  };
}

export function editAccountingSettingsOk(): EditAccountingSettingsOkAction {
  return {
    type: AccountingConsts.EDIT_ACCOUNTING_SETTINGS_OK,
  };
}

export function editAccountingSettingsKo(error: string): EditAccountingSettingsKoAction {
  return {
    type: AccountingConsts.EDIT_ACCOUNTING_SETTINGS_KO,
    error,
  };
}

export function createInvoiceData(entity_id: number): CreateInvoiceAction {
  return {
    type: AccountingConsts.CREATE_INVOICE,
    entity_id,
  };
}

export function createInvoiceDataOk(invoice: InvoiceData): CreateInvoiceOkAction {
  return {
    type: AccountingConsts.CREATE_INVOICE_OK,
    invoice,
  };
}

export function createInvoiceDataKo(error: string): CreateInvoiceKoAction {
  return {
    type: AccountingConsts.CREATE_INVOICE_KO,
    error,
  };
}

export function getInvoiceData(id: number): GetInvoiceDataAction {
  return {
    type: AccountingConsts.GET_INVOICE_DATA,
    id,
  };
}

export function getInvoiceDataOk(invoice: InvoiceData): GetInvoiceDataOkAction {
  return {
    type: AccountingConsts.GET_INVOICE_DATA_OK,
    invoice,
  };
}

export function getInvoiceDataKo(error: string): GetInvoiceDataKoAction {
  return {
    type: AccountingConsts.GET_INVOICE_DATA_KO,
    error,
  };
}

export function editInvoiceData(invoice: InvoiceData): EditInvoiceDataAction {
  return {
    type: AccountingConsts.EDIT_INVOICE_DATA,
    invoice,
  };
}

export function editInvoiceDataOk(invoice: InvoiceData): EditInvoiceDataOkAction {
  return {
    type: AccountingConsts.EDIT_INVOICE_DATA_OK,
    invoice,
  };
}

export function editInvoiceDataKo(error: string): EditInvoiceDataKoAction {
  return {
    type: AccountingConsts.EDIT_INVOICE_DATA_KO,
    error,
  };
}

export function deleteInvoiceData(id: number): DeleteInvoiceDataAction {
  return {
    type: AccountingConsts.DELETE_INVOICE_DATA,
    id,
  };
}

export function deleteInvoiceDataOk(): DeleteInvoiceDataOkAction {
  return {
    type: AccountingConsts.DELETE_INVOICE_DATA_OK,
  };
}

export function deleteInvoiceDataKo(error: string): DeleteInvoiceDataKoAction {
  return {
    type: AccountingConsts.DELETE_INVOICE_DATA_KO,
    error,
  };
}

export function uploadInvoiceDocument(file: File, invoice_id: number): UploadInvoiceDocumentAction {
  return {
    type: AccountingConsts.UPLOAD_INVOICE_DOCUMENT,
    invoice_id,
    file,
  };
}

export function uploadInvoiceDocumentOk(fileData: FileItem): UploadInvoiceDocumentOkAction {
  return {
    type: AccountingConsts.UPLOAD_INVOICE_DOCUMENT_OK,
    fileData,
  };
}

export function uploadInvoiceDocumentKo(error: string): UploadInvoiceDocumentKoAction {
  return {
    type: AccountingConsts.UPLOAD_INVOICE_DOCUMENT_KO,
    error,
  };
}

export function removeInvoiceDocument(file_id: number): RemoveInvoiceDocumentAction {
  return {
    type: AccountingConsts.REMOVE_INVOICE_DOCUMENT,
    file_id,
  };
}

export function removeInvoiceDocumentOk(): RemoveInvoiceDocumentOkAction {
  return {
    type: AccountingConsts.REMOVE_INVOICE_DOCUMENT_OK,
  };
}

export function removeInvoiceDocumentKo(error: string): RemoveInvoiceDocumentKoAction {
  return {
    type: AccountingConsts.REMOVE_INVOICE_DOCUMENT_KO,
    error,
  };
}

export function downloadInvoiceDocument(file_id: number, filename: string): DownloadInvoiceDocumentAction {
  return {
    type: AccountingConsts.DOWNLOAD_INVOICE,
    file_id,
    filename,
  };
}

export function downloadInvoiceDocumentOk(data: Blob, name: string): DownloadInvoiceDocumentOkAction {
  return {
    type: AccountingConsts.DOWNLOAD_INVOICE_OK,
    data,
    name,
  };
}

export function downloadInvoiceDocumentKo(error: string): DownloadInvoiceDocumentKoAction {
  return {
    type: AccountingConsts.DOWNLOAD_INVOICE_KO,
    error,
  };
}

export function invoiceSetData(key: string, value: string | number): InvoiceSetDataAction {
  return {
    type: AccountingConsts.INVOICE_SET_DATA,
    key,
    value,
  };
}

export function invoiceDetailSetData(key_detail: string, value_detail: string | number): InvoiceDetailSetDataAction {
  return {
    type: AccountingConsts.INVOICE_DETAIL_SET_DATA,
    key_detail,
    value_detail,
  };
}

export function invoiceValidationErrors(validationErrors: APIValidationError): InvoiceValidationErrorsAction {
  return {
    type: AccountingConsts.INVOICE_VALIDATION_ERRORS,
    validationErrors,
  };
}
//  CREATE INVOICE
export function createInvoice(entity_id: number): CreateInvoiceAction {
  return {
    type: AccountingConsts.CREATE_INVOICE,
    entity_id,
  };
}

export function createInvoiceOk(invoice: InvoiceData): CreateInvoiceOkAction {
  return {
    type: AccountingConsts.CREATE_INVOICE_OK,
    invoice,
  };
}

export function createInvoiceKo(error: string): CreateInvoiceKoAction {
  return {
    type: AccountingConsts.CREATE_INVOICE_KO,
    error,
  };
}

//  CREATE INVOICE DETAIL
export function createNewInvoiceDetail(newInvoiceDetail: InvoiceDetail): CreateNewInvoiceDetailAction {
  return {
    type: AccountingConsts.CREATE_NEW_INVOICE_DETAIL,
    newInvoiceDetail,
  };
}

export function createNewInvoiceDetailOk(invoice_details: InvoiceDetail[]): CreateNewInvoiceDetailOkAction {
  return {
    type: AccountingConsts.CREATE_NEW_INVOICE_DETAIL_OK,
    invoice_details,
  };
}

export function createNewInvoiceDetailKo(error: string): CreateNewInvoiceDetailKoAction {
  return {
    type: AccountingConsts.CREATE_NEW_INVOICE_DETAIL_KO,
    error,
  };
}

export function getInvoiceDetail(id: number): GetInvoiceDetailAction {
  return {
    type: AccountingConsts.GET_INVOICE_DETAIL,
    id,
  };
}

export function getInvoiceDetailOk(invoiceDetail: InvoiceDetail): GetInvoiceDetailOkAction {
  return {
    type: AccountingConsts.GET_INVOICE_DETAIL_OK,
    invoiceDetail,
  };
}

export function getInvoiceDetailKo(error: string): GetInvoiceDetailKoAction {
  return {
    type: AccountingConsts.GET_INVOICE_DETAIL_KO,
    error,
  };
}

//GET INVOICES DETAIL LIST

export function getInvoicesDetailList(invoice_id: number): GetInvoicesDetailListAction {
  return {
    type: AccountingConsts.GET_INVOICES_DETAIL_LIST,
    invoice_id,
  };
}

export function getInvoicesDetailListOk(invoicesDetailList: InvoiceDetail[]): GetInvoicesDetailListOkAction {
  return {
    type: AccountingConsts.GET_INVOICES_DETAIL_LIST_OK,
    invoicesDetailList,
  };
}

export function getInvoicesDetailListKo(error: string): GetInvoicesDetailListKoAction {
  return {
    type: AccountingConsts.GET_INVOICES_DETAIL_LIST_KO,
    error,
  };
}

// EDIT INVOICE DETAIL

export function editInvoiceDetail(invoiceDetail: InvoiceDetail): EditInvoiceDetailAction {
  return {
    type: AccountingConsts.EDIT_INVOICE_DETAIL,
    invoiceDetail,
  };
}

export function editInvoiceDetailOk(invoice_details: InvoiceDetail[]): EditInvoiceDetailOkAction {
  return {
    type: AccountingConsts.EDIT_INVOICE_DETAIL_OK,
    invoice_details,
  };
}

export function editInvoiceDetailKo(error: string): EditInvoiceDetailKoAction {
  return {
    type: AccountingConsts.EDIT_INVOICE_DETAIL_KO,
    error,
  };
}

//DELETE INVOICE DETAIL

export function deleteInvoiceDetail(invoiceDetail: InvoiceDetail): DeleteInvoiceDetailAction {
  return {
    type: AccountingConsts.DELETE_INVOICE_DETAIL,
    invoiceDetail,
  };
}

export function deleteInvoiceDetailOk(invoiceDetail: InvoiceDetail): DeleteInvoiceDetailOkAction {
  return {
    type: AccountingConsts.DELETE_INVOICE_DETAIL_OK,
    invoiceDetail,
  };
}

export function deleteInvoiceDetailKo(error: string): DeleteInvoiceDetailKoAction {
  return {
    type: AccountingConsts.DELETE_INVOICE_DETAIL_KO,
    error,
  };
}

//RESET INVOICE DETAIL

export function resetInvoiceDetail(): ResetInvoiceDetailAction {
  return {
    type: AccountingConsts.RESET_INVOICE_DETAIL,
  };
}

// CHANGE STATE

export function changeState(invoiceID: number, comments: string, state: string): ChangeState {
  return {
    type: AccountingConsts.CHANGE_STATE,
    invoiceID,
    comments,
    state,
  };
}

export function changeStateOk(invoiceData: InvoiceData): ChangeStateOk {
  return {
    type: AccountingConsts.CHANGE_STATE_OK,
    invoiceData,
  };
}

export function changeStateKo(error: string): ChangeStateKo {
  return {
    type: AccountingConsts.CHANGE_STATE_KO,
    error,
  };
}

// MOVE BACK INVOICE TO ACCEPTED
export function moveBackInvoice(invoice_id: number): MoveBackInvoiceAction {
  return {
    type: AccountingConsts.MOVE_BACK_INVOICE,
    invoice_id,
  };
}

export function moveBackInvoiceOk(invoiceData: InvoiceData): MoveBackInvoiceOkAction {
  return {
    type: AccountingConsts.MOVE_BACK_INVOICE_OK,
    invoiceData,
  };
}

export function moveBackInvoiceKo(error: string): MoveBackInvoiceKoAction {
  return {
    type: AccountingConsts.MOVE_BACK_INVOICE_KO,
    error,
  };
}

export function sendInvoice(invoiceData: InvoiceData): SendInvoiceAction {
  return {
    type: AccountingConsts.SEND_INVOICE,
    invoiceData,
  };
}

export function sendInvoiceOk(invoiceData: InvoiceData): SendInvoiceOkAction {
  return {
    type: AccountingConsts.SEND_INVOICE_OK,
    invoiceData,
  };
}

export function sendInvoiceKo(error: string): SendInvoiceKoAction {
  return {
    type: AccountingConsts.SEND_INVOICE_KO,
    error,
  };
}

export function acceptInvoice(invoiceData: InvoiceData): AcceptInvoiceAction {
  return {
    type: AccountingConsts.ACCEPT_INVOICE,
    invoiceData,
  };
}

export function acceptInvoiceOk(invoiceData: InvoiceData): AcceptInvoiceOkAction {
  return {
    type: AccountingConsts.ACCEPT_INVOICE_OK,
    invoiceData,
  };
}

export function acceptInvoiceKo(error: string): AcceptInvoiceKoAction {
  return {
    type: AccountingConsts.ACCEPT_INVOICE_KO,
    error,
  };
}

export function authorizeInvoice(invoice_id: number): AuthorizeInvoiceAction {
  return {
    type: AccountingConsts.AUTORIZE_INVOICE,
    invoice_id,
  };
}

export function authorizeInvoiceOk(invoiceData: InvoiceData): AuthorizeInvoiceOkAction {
  return {
    type: AccountingConsts.AUTORIZE_INVOICE_OK,
    invoiceData,
  };
}

export function authorizeInvoiceKo(error: string): AuthorizeInvoiceKoAction {
  return {
    type: AccountingConsts.AUTORIZE_INVOICE_KO,
    error,
  };
}

export function confirmInvoice(invoiceData: InvoiceData): ConfirmInvoiceAction {
  return {
    type: AccountingConsts.CONFIRM_INVOICE,
    invoiceData,
  };
}

export function confirmInvoiceOk(invoiceData: InvoiceData): ConfirmInvoiceOkAction {
  return {
    type: AccountingConsts.CONFIRM_INVOICE_OK,
    invoiceData,
  };
}

export function confirmInvoiceKo(error: string): ConfirmInvoiceKoAction {
  return {
    type: AccountingConsts.CONFIRM_INVOICE_KO,
    error,
  };
}

export function toPayInvoice(id: number): ToPayInvoiceAction {
  return {
    type: AccountingConsts.TO_PAY_INVOICE,
    id,
  };
}

export function toPayInvoiceOk(invoiceData: InvoiceData): ToPayInvoiceOkAction {
  return {
    type: AccountingConsts.TO_PAY_INVOICE_OK,
    invoiceData,
  };
}

export function toPayInvoiceKo(error: string): ToPayInvoiceKoAction {
  return {
    type: AccountingConsts.TO_PAY_INVOICE_KO,
    error,
  };
}

export function payInvoices(): PayInvoicesAction {
  return {
    type: AccountingConsts.PAY_INVOICES,
  };
}

export function payInvoicesOk(): PayInvoicesOkAction {
  return {
    type: AccountingConsts.PAY_INVOICES_OK,
  };
}

export function sendInvoicesToPay(): SendInvoicesToPayAction {
  return {
    type: AccountingConsts.TO_PAY_INVOICES,
  };
}

export function sendInvoicesToPayOk(): SendInvoicesToPayOkAction {
  return {
    type: AccountingConsts.TO_PAY_INVOICES_OK,
  };
}

export function openModal(): OpenModalAction {
  return {
    type: AccountingConsts.OPEN_MODAL,
  };
}

export function closeModal(): CloseModalAction {
  return {
    type: AccountingConsts.CLOSE_MODAL,
  };
}

export function getScholarshipSetting(): GetScholarshipSettingAction {
  return {
    type: AccountingConsts.GET_SCHOLARSHIP_SETTING,
  };
}

export function getScholarshipSettingOk(value: ScholarshipSetting): GetScholarshipSettingOkAction {
  return {
    type: AccountingConsts.GET_SCHOLARSHIP_SETTING_OK,
    value,
  };
}

export function getScholarshipSettingKo(error: string): GetScholarshipSettingKoAction {
  return {
    type: AccountingConsts.GET_SCHOLARSHIP_SETTING_KO,
    error,
  };
}

export function updateScholarshipSettingAction(value: ScholarshipSetting): UpdateScholarshipSettingAction {
  return {
    type: AccountingConsts.UPDATE_SCHOLARSHIP_SETTING,
    value,
  };
}
export function updateScholarshipSettingOkAction(value: ScholarshipSetting): UpdateScholarshipSettingOkAction {
  return {
    type: AccountingConsts.UPDATE_SCHOLARSHIP_SETTING_OK,
    value,
  };
}
export function updateScholarshipSettingKoAction(error: string): UpdateScholarshipSettingKoAction {
  return {
    type: AccountingConsts.UPDATE_SCHOLARSHIP_SETTING_KO,
    error,
  };
}

export function scholarshipSettingValidationErrors(
  validationErrors: APIValidationError,
): ScholarshipSettingValidationErrorsAction {
  return {
    type: AccountingConsts.SCHOLARSHIP_SETTING_VALIDATION_ERRORS,
    validationErrors,
  };
}

export function scholarshipSetDataSettings(key: string, value: string | number): ScholarshipSetDataSettingsAction {
  return {
    type: AccountingConsts.SCHOLARSHIP_SET_DATA_SETTING,
    key,
    value,
  };
}

//GET PENDING TO SEND INVOICES
export function getPendingToSendInvoices(): GetPendingToSendInvoicesAction {
  return {
    type: AccountingConsts.GET_PENDING_INVOICES,
  };
}
export function getPendingToSendInvoicesOk(pendingInvoicesList: InvoiceListItem[]): GetPendingToSendInvoicesOkAction {
  return {
    type: AccountingConsts.GET_PENDING_INVOICES_OK,
    pendingInvoicesList,
  };
}
export function getPendingToSendInvoicesKo(error: string): GetPendingToSendInvoicesKoAction {
  return {
    type: AccountingConsts.GET_PENDING_INVOICES_KO,
    error,
  };
}
//REMOVE PENDING INVOICES
export function removePendingInvoice(pendingInvoice_id: number): RemovePendingInvoiceAction {
  return {
    type: AccountingConsts.REMOVE_PENDING_INVOICE,
    pendingInvoice_id,
  };
}

export function removePendingInvoiceKo(error: string): RemovePendingInvoiceKoAction {
  return {
    type: AccountingConsts.REMOVE_PENDING_INVOICE_KO,
    error,
  };
}

//GET ONE PENDING INVOICES
export function getOnePendingInvoice(pendingInvoice_id: number): GetOnePendingInvoiceAction {
  return {
    type: AccountingConsts.GET_ONE_PENDING_INVOICE,
    pendingInvoice_id,
  };
}
export function getOnePendingInvoiceOk(pendingInvoice: PendingInvoiceData): GetOnePendingInvoiceOkAction {
  return {
    type: AccountingConsts.GET_ONE_PENDING_INVOICE_OK,
    pendingInvoice,
  };
}
export function getOnePendingInvoiceKo(error: string): GetOnePendingInvoiceKoAction {
  return {
    type: AccountingConsts.GET_ONE_PENDING_INVOICE_KO,
    error,
  };
}

//UPDATE ONE PENDING INVOICES
export function updatePendingInvoice(invoice: PendingInvoiceData): UpdatePendingInvoiceAction {
  return {
    type: AccountingConsts.UPDATE_PENDING_INVOICE,
    invoice,
  };
}

export function updatePendingInvoiceKo(error: string): UpdatePendingInvoiceKoAction {
  return {
    type: AccountingConsts.UPDATE_PENDING_INVOICE_KO,
    error,
  };
}
//SET ONE PENDING INVOICES
export function setOnePendingInvoice(key_i: string, value_i: string | number): SetOnePendingInvoiceAction {
  return {
    type: AccountingConsts.SET_ONE_PENDING_INVOICE,
    key_i,
    value_i,
  };
}

export function openPaymentModal(): OpenPaymentModalAction {
  return {
    type: AccountingConsts.OPEN_PAYMENT_MODAL,
  };
}

export function closePaymentModal(): ClosePaymentModalAction {
  return {
    type: AccountingConsts.CLOSE_PAYMENT_MODAL,
  };
}

export function markInvoiceAsPaid(invoice_id: number): MarkInvoiceAsPaidAction {
  return {
    type: AccountingConsts.MARK_INVOICE_AS_PAID,
    invoice_id,
  };
}

export function markInvoiceAsPaidOk(invoice: InvoiceData): MarkInvoiceAsPaidOkAction {
  return {
    type: AccountingConsts.MARK_INVOICE_AS_PAID_OK,
    invoice,
  };
}

export function markInvoiceAsPaidKo(error: string): MarkInvoiceAsPaidKoAction {
  return {
    type: AccountingConsts.MARK_INVOICE_AS_PAID_KO,
    error,
  };
}

export function setInsScholarshipsPaymentDateFilter(
  paymentDate: moment.Moment | null,
  reloadDate: moment.Moment | null,
): SetInsScholarshipsPaymentDateFilterAction {
  return {
    type: AccountingConsts.SET_INS_SCHOLARSHIPS_PAYMENT_DATE_FILTER,
    paymentDate,
    reloadDate,
  };
}

export function closeInvoices(closing_year: number): CloseInvoicesAction {
  return {
    type: AccountingConsts.CLOSE_INVOICES,
    closing_year,
  };
}

export function closeInvoicesOk(): CloseInvoicesOkAction {
  return {
    type: AccountingConsts.CLOSE_INVOICES_OK,
  };
}

export function closeInvoicesKo(error: string): CloseInvoicesKoAction {
  return {
    type: AccountingConsts.CLOSE_INVOICES_KO,
    error,
  };
}

export function downloadInvoiceContractAction(
  entity_id: number,
  contract_number: string,
): DownloadInvoiceContractAction {
  return {
    type: AccountingConsts.DOWNLOAD_INVOICE_CONTRACT,
    entity_id,
    contract_number,
  };
}

export function downloadInvoiceContractOkAction(data: Blob, name: string): DownloadInvoiceContractOkAction {
  return {
    type: AccountingConsts.DOWNLOAD_INVOICE_CONTRACT_OK,
    data,
    name,
  };
}

export function downloadInvoiceContractKoAction(error: string): DownloadInvoiceContractKoAction {
  return {
    type: AccountingConsts.DOWNLOAD_INVOICE_CONTRACT_KO,
    error,
  };
}
