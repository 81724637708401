import { MenuItem } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { Control, FieldErrors, FieldValues } from 'react-hook-form/dist/types';
import { ObjectSchema, Shape } from 'yup';
import SelectController from '../../../../../components/Select/SelectController';
import { tKey, tScholarshipKey } from '../../../../../helpers/translate';
import { ScholarshipFollow } from '../../../../../redux/scholarship/definitions';
import styles from '../followForm.module.scss';

interface AcademicResultNextCourseProps<T extends FieldValues> {
  scholarshipFollow: ScholarshipFollow;
  readOnly: boolean;
  errors: FieldErrors<T> | any;
  control: Control<T>;
  scholarshipType: string;
  schema: ObjectSchema<T> | ObjectSchema<Shape<object, any>>;
  setDataFollow: (subKey: string, value: string | number | null) => void;
}

const AcademicResultNextCourse = <T extends FieldValues>({
  scholarshipFollow,
  readOnly,
  control,
  errors,
  scholarshipType,
  schema,
  setDataFollow,
}: AcademicResultNextCourseProps<T>): ReactElement => {
  const { graduated_this_course, continue_studying_next_course, continue_zing_next_course } = scholarshipFollow;

  const handleSelectChange = (target: any) => {
    if (target.name) {
      setDataFollow(target.name, target.value);
    }
  };

  const selectOptions = (isGraduated: boolean): string[] => {
    return ['Sí', isGraduated ? 'Aún no' : 'No'];
  };

  return (
    <div className={styles.container}>
      <SelectController
        control={control}
        name="graduated_this_course"
        label={tScholarshipKey(`follow_up.${scholarshipType}.graduated_this_course`)}
        defaultValue={graduated_this_course}
        schema={schema}
        errors={errors}
        disabled={readOnly}
        validator={!readOnly}
        onClick={e => handleSelectChange(e.target)}
      >
        {selectOptions(true).map((value: string) => (
          <MenuItem key={value} value={value}>
            {tKey(value)}
          </MenuItem>
        ))}
      </SelectController>
      <SelectController
        control={control}
        name="continue_studying_next_course"
        label={tScholarshipKey(`follow_up.${scholarshipType}.continue_studying_next_course`)}
        defaultValue={continue_studying_next_course}
        schema={schema}
        errors={errors}
        disabled={readOnly}
        validator={!readOnly}
        onClick={e => handleSelectChange(e.target)}
      >
        {selectOptions(false).map((value: string) => (
          <MenuItem key={value} value={value}>
            {tKey(value)}
          </MenuItem>
        ))}
      </SelectController>
      <SelectController
        control={control}
        name="continue_zing_next_course"
        label={tScholarshipKey(`follow_up.${scholarshipType}.continue_zing_next_course`)}
        defaultValue={continue_zing_next_course}
        schema={schema}
        errors={errors}
        disabled={readOnly}
        validator={!readOnly}
        onClick={e => handleSelectChange(e.target)}
      >
        {selectOptions(false).map((value: string) => (
          <MenuItem key={value} value={value}>
            {tKey(value)}
          </MenuItem>
        ))}
      </SelectController>
    </div>
  );
};

export default AcademicResultNextCourse;
