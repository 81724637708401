class FollowUpSatisfaction {
  static MUY_SATISFACTORIO = 'Muy satisfactorio';
  static SATISFACTORIO = 'Satisfactorio';
  static POCO_SATISFACTORIO = 'Poco satisfactorio';
  static NADA_SATISFACTORIO = 'Nada satisfactorio';

  constructor(private code: string) {}

  public value(): string {
    return this.code;
  }

  public static toArray(): string[] {
    return [
      FollowUpSatisfaction.MUY_SATISFACTORIO,
      FollowUpSatisfaction.SATISFACTORIO,
      FollowUpSatisfaction.POCO_SATISFACTORIO,
      FollowUpSatisfaction.NADA_SATISFACTORIO,
    ];
  }
}

export default FollowUpSatisfaction;
