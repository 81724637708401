import { IconButton, MenuItem, Select, Tooltip } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { DeleteOutline } from '@material-ui/icons';
import PostAddIcon from '@material-ui/icons/PostAdd';
import { Action, Column, MaterialTableProps } from 'material-table';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import {
  assignUserApi,
  deleteScholarships,
  getUsersNouscimsFromApi,
  ScholarhipGetList,
} from '../../../api/Scholarship/scholarship';
import CopyOnClickTable from '../../../components/CopyOnClickTable/CopyOnClickTable';
import ScholarshipDashboard from '../../../components/Dashboard/Dashboard';
import Layout from '../../../components/Layout/Layout';
import Loading from '../../../components/Loading/Loading';
import BodyModal from '../../../components/Modal/BodyModal';
import TransitionModal from '../../../components/Modal/TransitionModal';
import SelectCheckboxController, {
  ListSelectCheckbox,
} from '../../../components/Select/SelectCheckboxController/SelectCheckboxController';
import MobileTable from '../../../components/Tables/MobileTable';
import TableWithEdit from '../../../components/Tables/TableWithEdit';
import * as DateConstants from '../../../constants/date';
import { permissionType } from '../../../constants/permissions_definitions';
import { useWindowSize } from '../../../helpers/customHooks/useHookMethod';
import { unixToDate } from '../../../helpers/dateHelper';
import { exportToExcel } from '../../../helpers/exportToXslx';
import { haveTerm } from '../../../helpers/normalizeString';
import { tScholarshipKey } from '../../../helpers/translate';
import {
  selectIsNousCims,
  selectUserCanCreateScholarships,
  selectUserCanRenewScholarships,
} from '../../../redux/auth/selectors';
import { ResponseData } from '../../../redux/common/definitions';
import { showSnackbar } from '../../../redux/FeedbackAPI/actions';
import { SeveritySnackbar } from '../../../redux/FeedbackAPI/definitions';
import { selectCurrentAcademicYear, selectGenericMetadata } from '../../../redux/metadata/selectors';
import { selectUserCan } from '../../../redux/permissions/selectors';
import { AppState } from '../../../redux/root-reducer';
import { exportScholarshipsReports, setScholarshipStep } from '../../../redux/scholarship/actions';
import {
  ExportSourceType,
  ListOfScholarships,
  ScholarshipListItem,
  ScholarshipStateCode,
  ScholarshipType,
  ScholarshipUserCheck,
  StateCode,
  transversalCompetenceTypes,
} from '../../../redux/scholarship/definitions';
import AddNewScholarhipModal from '../Modals/AddNewScholarhipModal';
import ButtonsMobile from './ButtonsMobile';
import styles from './scholarshipList.module.scss';
import ShowTypes from './ShowTypes';

const tableData: MaterialTableProps<ScholarshipListItem> = {
  title: '',
  columns: [],
  data: [],
  options: {
    tableLayout: 'auto',
    selection: true,
    exportButton: true,
    pageSize: 100,
    pageSizeOptions: [20, 50, 100],
  },
};

interface ScholarshipListProps extends RouteComponentProps<{ state_code: StateCode; academic_year: string }> {
  type: ScholarshipType;
  myScholarship?: boolean;
  isNousCims: boolean;
  canCreate: boolean;
  canRenew: boolean;
  academicYears: string[];
  currentAcademicYear?: string;
  userCan: (permission: permissionType) => boolean | undefined;
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number) => void;
  exportScholarships: (source: ExportSourceType, scholarship_type: ScholarshipType, academic_year: string) => void;
  setScholarshipStep: (step: number) => void;
}

interface UsersDataNouscims {
  id: number;
  name: string;
}

interface UsersNouscims {
  users: UsersDataNouscims[];
}

const minSearchLength = 3;

const ScholarshipList: React.FC<ScholarshipListProps> = ({
  type,
  myScholarship,
  isNousCims,
  match,
  academicYears,
  canCreate,
  canRenew,
  userCan,
  showSnackbar,
  exportScholarships,
  setScholarshipStep,
  currentAcademicYear,
}) => {
  const isMobile = useWindowSize().IsMobile;
  const history = useHistory();
  const [arrIds, setArrIds] = React.useState<number[]>([]);
  const [view, setView] = React.useState(false);
  const [responseData, setResponseData] = React.useState<ResponseData | null>(null);
  const [loading, setLoading] = React.useState(true);
  const { t } = useTranslation();
  const [scholarShipsList, setScholarShipsList] = React.useState<ScholarshipListItem[]>([]);
  const [filteredScholarships, setFilteredScholarships] = useState<ScholarshipListItem[]>([]);

  const canIexport = userCan('action_nouscims');
  const canIAssignUser = userCan('action_scholarship_assign_user');

  const {
    params: { state_code, academic_year },
  } = match;

  const [addNewModal, setAddNewModal] = React.useState({
    view: false,
    student: null as null | ScholarshipUserCheck,
    canRenewal: false,
  });

  const assignUser = async (id: number, scholarship_id: number) => {
    try {
      await assignUserApi(id, scholarship_id);
    } catch (e) {
      console.log(e);
    }
  };

  const SelectAssignNouscimsUser = ({
    rowData,
    users,
  }: {
    rowData: ScholarshipListItem;
    users: UsersDataNouscims[];
  }) => {
    const [userId, setUserId] = useState(rowData.assigned_to_user_id);

    return (
      <Select
        label=""
        value={userId}
        onChange={(e: any) => {
          assignUser(e.target.value, rowData.id);
          setUserId(e.target.value);
        }}
        className={styles.selectAssesment}
      >
        {users.map(element => (
          <MenuItem key={element.id} value={element.id} data-name={element.name}>
            {element.name}
          </MenuItem>
        ))}
      </Select>
    );
  };

  const actionIcons = {
    Delete: () => <DeleteOutline style={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
    DeleteDis: () => <DeleteOutline style={{ color: 'rgba(0, 0, 0, 0.2)' }} />,
    PostAdd: () => <PostAddIcon style={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
    PostAddDis: () => <PostAddIcon style={{ color: 'rgba(0, 0, 0, 0.2)' }} />,
  };

  function getScholarshipData(scholarshipList: ListOfScholarships): ScholarshipListItem[] {
    const scholarshipsItems = scholarshipList.scholarships
      .map((scholarship: ScholarshipListItem) => {
        const transversal_competences = transversalCompetenceTypes
          .filter(({ code }) => scholarship[code])
          .map(type => type.code);
        return { ...scholarship, transversal_competences };
      })
      .filter(scholarship => {
        if (isMobile && currentAcademicYear) {
          return scholarship.academic_year === currentAcademicYear;
        } else {
          return scholarship;
        }
      });

    if (state_code) {
      return scholarshipsItems.filter(scholarship => scholarship.state_code === state_code);
    }
    return scholarshipsItems;
  }

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;

    (async function fetchData() {
      const stateFilters = {} as any;
      const academicCoursesFilters = {} as any;
      const renewalFilters = {} as any;
      const scholarshipList = (await ScholarhipGetList(type, academic_year, signal)) as ListOfScholarships;
      const usersNouscims = (await getUsersNouscimsFromApi()) as UsersNouscims;

      if (scholarshipList.filters) {
        const { states, renewal } = scholarshipList.filters;
        states.forEach(
          (element: string) =>
            (stateFilters[t(`scholarship.states.${element}`)] = tScholarshipKey(t(`scholarship.states.${element}`))),
        );
        academicYears.forEach((element: string) => (academicCoursesFilters[element] = element));
        renewal.forEach((element: string) => (renewalFilters[element] = element));

        const columns: Column<ScholarshipListItem>[] = [
          {
            title: tScholarshipKey('Nombre'),
            field: 'student_name',
            render: (rowiData: ScholarshipListItem) => (
              <div>{`${rowiData.confirmed_with_diff ? '* ' : ''}${rowiData.student_name}`}</div>
            ),
            customFilterAndSearch: (term, rowiData: ScholarshipListItem) => haveTerm(rowiData.student_name || '', term),
          },
          {
            title: tScholarshipKey('Apellidos'),
            field: 'student_surname',
            render: (rowiData: ScholarshipListItem) => (
              <div>{`${rowiData.student_surname} ${rowiData.student_surname2 || ''}`}</div>
            ),
            customFilterAndSearch: (term, rowiData: ScholarshipListItem) =>
              haveTerm(rowiData.student_surname + ' ' + rowiData.student_surname2, term),
          },
        ];

        if (type === 'institute') {
          tableData.title = myScholarship ? tScholarshipKey('Mis becas') : tScholarshipKey('Becas instituto');
          columns.push({
            title: tScholarshipKey('Correo electrónico'),
            field: 'student_email',
            disableClick: true,
            render: rowData => <CopyOnClickTable field={rowData.student_email} />,
            customFilterAndSearch: (term, rowiData: ScholarshipListItem) => haveTerm(rowiData.student_email, term),
          });
          columns.push({
            title: tScholarshipKey('Teléfono'),
            field: 'student_phone',
            hidden: true,
            disableClick: true,
          });
          columns.push({
            title: tScholarshipKey('Doc. identidad'),
            field: 'student_document_number',
            disableClick: true,
            render: rowData => <CopyOnClickTable field={rowData.student_document_number} />,
          });
          columns.push({
            title: tScholarshipKey('Centro'),
            field: 'academy',
            disableClick: true,
            render: rowData => <CopyOnClickTable field={rowData.academy} />,
            customFilterAndSearch: (term, rowiData: ScholarshipListItem) => haveTerm(rowiData.academy, term),
          });
        } else if (type === 'study_center') {
          tableData.title = tScholarshipKey('Becas escuelas concertadas');
          columns.push({
            title: tScholarshipKey('Correo electrónico'),
            field: 'student_email',
            hidden: true,
            disableClick: true,
          });
          columns.push({
            title: tScholarshipKey('Teléfono'),
            field: 'student_phone',
            hidden: true,
            disableClick: true,
          });
          columns.push({
            title: tScholarshipKey('Entidad'),
            field: 'entity',
            render: rowData => (
              <Tooltip title={rowData.entity}>
                <span>{rowData.entity}</span>
              </Tooltip>
            ),
            customFilterAndSearch: (term, rowiData: ScholarshipListItem) => haveTerm(rowiData.entity, term),
          });
          columns.push({
            title: tScholarshipKey('Email educador/a'),
            field: 'educator_email',
            disableClick: true,
            render: rowData => <CopyOnClickTable field={rowData.educator_email} />,
            customFilterAndSearch: (term, rowiData: ScholarshipListItem) =>
              haveTerm(rowiData.educator_name + ' ' + rowiData.educator_email, term),
          });
        } else {
          tableData.title = tScholarshipKey('Becas entidad');
          columns.push({
            title: tScholarshipKey('Correo electrónico'),
            field: 'student_email',
            hidden: true,
            disableClick: true,
          });
          columns.push({
            title: tScholarshipKey('Teléfono'),
            field: 'student_phone',
            hidden: true,
            disableClick: true,
          });
          columns.push({
            title: tScholarshipKey('Entidad'),
            field: 'entity',
            cellStyle: { maxWidth: 300 },
            render: rowData => (
              <Tooltip title={rowData.entity}>
                <span>{rowData.entity}</span>
              </Tooltip>
            ),
            customFilterAndSearch: (term, rowiData: ScholarshipListItem) => haveTerm(rowiData.entity, term),
          });

          columns.push({
            title: tScholarshipKey('Email educador/a'),
            field: 'educator_email',
            disableClick: true,
            render: rowData => <CopyOnClickTable field={rowData.educator_email} />,
            customFilterAndSearch: (term, rowiData: ScholarshipListItem) =>
              haveTerm(rowiData.educator_name + ' ' + rowiData.educator_email, term),
          });
        }
        columns.push({
          title: tScholarshipKey('Curso'),
          field: 'academic_year',
          lookup: academicCoursesFilters,
          //@ts-ignore
          width: '120px',
        });

        columns.push({
          title: tScholarshipKey('Renovación'),
          field: 'is_renewal',
          cellStyle: {
            textAlign: 'center',
          } as CSSProperties,
          lookup: renewalFilters,
          // @ts-ignore
          width: '100px',
        });

        columns.push({
          title: tScholarshipKey('Fecha solicitud'),
          field: 'request_date_nf',
          customFilterAndSearch: (term, rowData: ScholarshipListItem) =>
            !!unixToDate(rowData.request_date_nf)?.includes(term),
          //@ts-ignore
          width: '120px',
          render: rowData => (
            <CopyOnClickTable field={moment.unix(rowData.request_date_nf).format(DateConstants.DATE_FORMAT)} />
          ),
        });

        if (isNousCims) {
          columns.push({
            title: tScholarshipKey('Importe'),
            field: 'total_requested',
            type: 'currency',
            currencySetting: {
              locale: 'es-ES',
              currencyCode: 'EUR',
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            },
            //@ts-ignore
            width: '120px',
          });
        }
        if (canIAssignUser) {
          columns.push({
            title: tScholarshipKey('Asignada a'),
            // @ts-ignore
            width: '180px',
            field: 'assigned_to_user',
            disableClick: true,
            render: rowData => <SelectAssignNouscimsUser rowData={rowData} users={usersNouscims.users} />,
          });
        } else {
          columns.push({
            title: tScholarshipKey('Asignada a'),
            field: 'assigned_to_user',
            customFilterAndSearch: (term, rowiData: ScholarshipListItem) =>
              haveTerm(rowiData.assigned_to_user || '', term),
          });
        }

        columns.push({
          title: tScholarshipKey('Estado beca'),
          field: 'state',
          lookup: stateFilters,
          // width: '150px',
        });

        if (type !== 'institute') {
          columns.push({
            title: tScholarshipKey('Competencias transversales'),
            field: 'transversal_competences', //forced
            render: (rowData: ScholarshipListItem) => <ShowTypes rowData={rowData} />,
            customFilterAndSearch: (types, rowData) =>
              types.every((type: string) => rowData.transversal_competences.includes(type)),
            filterComponent: ({ columnDef, onFilterChanged }) => (
              <SelectCheckboxController
                list={transversalCompetenceTypes as ListSelectCheckbox[]}
                valueSend="code"
                valueLabel="name"
                columnDef={columnDef}
                onFilterChanged={onFilterChanged}
              />
            ),

            //@ts-ignore
            width: '150px',
          });
        }
        if (canRenew) {
          columns.push({
            title: tScholarshipKey('Opciones'),
            field: 'canRenewal',
            // @ts-ignore
            width: '150px',
            filtering: false,
            disableClick: true,
            render: (data: ScholarshipListItem) => (
              <Fragment>
                <IconButton
                  disabled={!data.canRenewal}
                  onClick={() => {
                    setAddNewModal(prevState => ({
                      ...prevState,
                      view: true,
                      student: {
                        email: data.student_email,
                        scholarship_id: data.id,
                        current: false,
                        is_renewal: data.canRenewal,
                        user_id: data.user_id,
                        is_allowed: true,
                        phone: data.student_phone,
                      },
                      canRenewal: true,
                    }));
                  }}
                >
                  <Tooltip title={tScholarshipKey('Renovar Beca')}>
                    <PostAddIcon />
                  </Tooltip>
                </IconButton>
                <IconButton
                  disabled={data.state_code !== ScholarshipStateCode.CREATION}
                  onClick={() => {
                    setArrIds([data.id]);
                    setView(true);
                  }}
                >
                  <Tooltip title={tScholarshipKey('Borrar Beca')}>
                    <DeleteOutline />
                  </Tooltip>
                </IconButton>
              </Fragment>
            ),
          });
        }

        const actions: Action<ScholarshipListItem>[] = [];

        if (canCreate) {
          actions.push({
            icon: actionIcons.Delete,
            tooltip: tScholarshipKey('Eliminar'),
            position: 'toolbarOnSelect',
            onClick: (event: any, data: ScholarshipListItem | ScholarshipListItem[]) => {
              setArrIds(Array.isArray(data) ? data.map(({ id }) => id) : [data.id]);
              setView(true);
            },
          });
        }

        tableData.columns = columns;
        tableData.actions = actions;
      }

      const scholarshipData = getScholarshipData(scholarshipList);

      setScholarShipsList(scholarshipData);
      setFilteredScholarships(scholarshipData);
      setLoading(false);
    })();

    return function cleanup() {
      abortController.abort();
    };
  }, [type, myScholarship, t, state_code, academic_year]); // eslint-disable-line react-hooks/exhaustive-deps

  tableData.data = filteredScholarships;

  const [createdModal, setCreatedModal] = React.useState({
    view: false,
    title: tScholarshipKey('Ayuda'),
    children: (
      <ul className={styles.list}>
        <li className={styles.listItem}>
          <p className={styles.listText}>{tScholarshipKey('recuperar-información')}</p>
        </li>
        <li className={styles.listItem}>
          <p className={styles.listText}>
            {tScholarshipKey('info-enviar-solicitud')}
            <a className={styles.listLink} href="becas@zingprogramme.com">
              becas@zingprogramme.com
            </a>
          </p>
        </li>
        <li className={styles.listItem}>
          <p className={styles.listText}>{tScholarshipKey('solicitud-tramitar-correctamente')}</p>
        </li>
      </ul>
    ),
  });

  const goToScholarshipDetail = (scholaship_id: number) => {
    if (type === 'study_center') {
      history.push({ pathname: `/becas/entity/detalle/${scholaship_id}` });
      return;
    }
    history.push({ pathname: `/becas/${type}/detalle/${scholaship_id}` });
  };

  const details = (rowData: ScholarshipListItem, selectedStep: number) => {
    setScholarshipStep(selectedStep);
    goToScholarshipDetail(rowData.id);
  };

  const addNew = () => {
    setAddNewModal(prevState => ({ ...prevState, view: true }));
  };

  const search = (searchTerm: string) => {
    if (searchTerm.length >= minSearchLength) {
      const filteredList = scholarShipsList.filter(
        item =>
          item.student_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.student_surname?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.student_surname2?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.state?.toLowerCase().includes(searchTerm.toLowerCase()),
      );
      setFilteredScholarships(filteredList);
    } else {
      setFilteredScholarships(scholarShipsList);
    }
  };

  const deleteScholarshipsList = async () => {
    try {
      const result = await deleteScholarships(arrIds);
      const newscholarshipsList = scholarShipsList.filter(
        x => !result.data.filter(y => y.id === x.id && y.success).length,
      );
      setScholarShipsList(newscholarshipsList);
      setResponseData(result);
    } catch (e) {
      showSnackbar(tScholarshipKey('Error en la eliminación de la beca'), 'error', undefined, 1000);
    }
  };

  const onExportCommittee = () => {
    exportScholarships('committee', type, academic_year?.replace('-', '/'));
  };

  const onExportAcademicSecretary = () => {
    exportScholarships('academic_secretary', type, academic_year?.replace('-', '/'));
  };

  tableData.options = {
    ...tableData.options,
    selection: canCreate,
    exportCsv: (columns, data) => {
      const columnsExport = columns.map((c: Column<ScholarshipListItem>) => {
        if (c.field === 'request_date_nf') {
          return {
            ...c,
            field: 'request_date',
          };
        }
        return c;
      });
      const dataExport = data.map((d: ScholarshipListItem) => {
        if (d.confirmed_with_diff) {
          return {
            ...d,
            student_name: `* ${d.student_name}`,
          };
        }
        return d;
      });
      exportToExcel(columnsExport, dataExport, `Becas_${type}`);
    },
  };

  const exportsList = isNousCims
    ? [
        {
          title: tScholarshipKey('Comité'),
          titleFile: `Becas_${type}_comite`,
          dataBody: { academic_year, type },
          onClick: onExportCommittee,
        },
        {
          title: tScholarshipKey('Secretaría académica'),
          titleFile: `Becas_${type}_secretaria_academica`,
          dataBody: { academic_year, type },
          onClick: onExportAcademicSecretary,
        },
        {
          title: tScholarshipKey('Zing network'),
          titleFile: `Becas_${type}_zing_network`,
          endpoint: '/scholarship/exports/zing_network',
          dataBody: { type },
        },
      ]
    : undefined;

  return (
    <Layout>
      <div className={styles.root}>
        {loading && <Loading big />}
        {!loading && (
          <Fragment>
            <h1 className={styles.title}>{tableData.title}</h1>
            {isNousCims && (
              <ScholarshipDashboard
                type={type}
                state_code={state_code}
                academic_years={academicYears}
                academic_year={academic_year}
              />
            )}
            <ButtonsMobile
              onHelp={() => {
                setCreatedModal(prevState => ({ ...prevState, view: true }));
              }}
              onNew={() => {
                addNew();
              }}
              canCreate={canCreate}
              isNousCims={isNousCims}
              onSearch={(searchTerm: string) => {
                search(searchTerm);
              }}
            />
            {isMobile && (
              <MobileTable
                tableData={tableData}
                columnsFilter={['student_name', 'academic_year', 'state']}
                onRowClick={(rowData: ScholarshipListItem) => details(rowData, -1)}
              />
            )}
            <TableWithEdit
              tableData={tableData}
              filtering
              permission={canCreate}
              permissionOthers={canIexport}
              optionsNew={{
                newButtonText: tScholarshipKey('Nueva beca'),
                onNew: addNew,
              }}
              optionsHelp={{
                help: tScholarshipKey('Ayuda'),
                onHelp: () => setCreatedModal(prevState => ({ ...prevState, view: true })),
              }}
              onRowClick={(rowData: ScholarshipListItem) => details(rowData, 0)}
              exportList={exportsList}
            />
          </Fragment>
        )}
      </div>
      <TransitionModal
        view={view}
        handleClose={() => {
          setArrIds([]);
          setView(false);
        }}
        bodyModal={
          arrIds.length > 1
            ? tScholarshipKey('¿Seguro que desea eliminar estas becas?')
            : tScholarshipKey('¿Seguro que desea eliminar esta beca?')
        }
        buttonOk={tScholarshipKey('Sí')}
        buttonKo={tScholarshipKey('No')}
        title={tScholarshipKey('Atención!')}
        handleYes={() => {
          deleteScholarshipsList();
          setView(false);
        }}
      />
      <TransitionModal
        view={responseData !== null && responseData.data.length > 0}
        handleClose={() => setResponseData(null)}
        buttonOk={tScholarshipKey('Vale')}
        title={tScholarshipKey('Resultado')}
        bodyModal={
          responseData ? (
            <BodyModal options={{ data: responseData, type: 'response', itemType: 'scholarship' }} />
          ) : (
            undefined
          )
        }
        handleYes={() => {
          setResponseData(null);
        }}
      />
      <AddNewScholarhipModal
        view={addNewModal.view}
        institute={type === 'institute'}
        handleClose={() => {
          setAddNewModal(prevState => ({ ...prevState, view: false, student: null, canRenewal: false }));
        }}
        goToScholarshipDetail={id => {
          goToScholarshipDetail(id);
        }}
        student={addNewModal.student}
        canRenewal={addNewModal.canRenewal}
      ></AddNewScholarhipModal>
      <TransitionModal
        view={createdModal.view}
        handleClose={() => {
          setCreatedModal(prevState => ({ ...prevState, view: false }));
        }}
        title={createdModal.title}
        helperMode={true}
      >
        <>{createdModal.children}</>
      </TransitionModal>
    </Layout>
  );
};

const mapStateProps = (state: AppState) => ({
  userCan: selectUserCan(state),
  isNousCims: selectIsNousCims(state),
  academicYears: selectGenericMetadata(state).academicYears,
  canCreate: selectUserCanCreateScholarships(state),
  canRenew: selectUserCanRenewScholarships(state),
  currentAcademicYear: selectCurrentAcademicYear(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number): void =>
    dispatch(showSnackbar(message, severity, route, time)),
  exportScholarships: (source: ExportSourceType, scholarship_type: ScholarshipType, academic_year: string): void =>
    dispatch(exportScholarshipsReports(source, scholarship_type, academic_year)),
  setScholarshipStep: (step: number) => dispatch(setScholarshipStep(step)),
});

export default connect(mapStateProps, mapDispatchToProps)(ScholarshipList);
