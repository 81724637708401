import React, { ReactElement } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { ObjectSchema, Shape } from 'yup';
import KeyboardDatePickerController from '../../../../components/DatePicker/KeyboardDatePickerController';
import FormContainer from '../../../../components/Forms/FormContainer';
import TextInputController from '../../../../components/Inputs/TextInputController/TextInputController';
import RadioButtonGroup from '../../../../components/RadioButtons/RadioButtonGroup';
import { yesNoNumberOptions } from '../../../../components/RadioButtons/RadioButtonOptions';
import { tScholarshipKey } from '../../../../helpers/translate';
import { ScholarshipFollow } from '../../../../redux/scholarship/definitions';
import { DATE_FORMAT, DATE_FORMAT_DB } from '../../../../constants/date';
import styles from './followForm.module.scss';
import moment from 'moment';

interface MecScholarshipProps<T extends FieldValues> {
  scholarshipFollow: ScholarshipFollow;
  academic_year: string;
  scholarshipType: string;
  readOnly?: boolean;
  errors: any;
  control: ReturnType<typeof useForm>['control'];
  schema: ObjectSchema<T> | ObjectSchema<Shape<object, any>>;
  register?: ReturnType<typeof useForm>['register'];
  setDataFollow: (subKey: string, value: string | number | null) => void;
}

const MecScholarship = <T extends FieldValues>({
  scholarshipFollow,
  scholarshipType,
  academic_year,
  readOnly,
  errors,
  control,
  schema,
  register,
  setDataFollow,
}: MecScholarshipProps<T>): ReactElement => {
  const {
    requested,
    why_not_requested,
    awarded,
    assigned_amount,
    income_received,
    income_received_at,
  } = scholarshipFollow;

  const labelTranslation = (): string => {
    const label = tScholarshipKey(`MecScholarship.${scholarshipType}.requested`);
    return label.replace('{course}', academic_year);
  };

  const renderWhyNotRequested = (): JSX.Element | undefined => {
    if (requested === null || !!requested) {
      return;
    }
    return (
      <TextInputController
        control={control}
        errors={errors}
        defaultValue={why_not_requested}
        schema={schema}
        fullWidth
        multiline
        rows={4}
        rowsMax={4}
        label={tScholarshipKey('MecScholarship.why_not_requested')}
        name="why_not_requested"
        disabled={readOnly}
        onBlur={e => {
          setDataFollow('why_not_requested', e.target.value);
        }}
      />
    );
  };

  const renderAwarded = (): JSX.Element | undefined => {
    if (requested === null || !requested) {
      return;
    }
    return (
      <RadioButtonGroup
        name="awarded"
        errors={errors.awarded}
        disabled={readOnly}
        questionText={tScholarshipKey(`MecScholarship.${scholarshipType}.awarded`)}
        options={yesNoNumberOptions}
        value={awarded}
        register={register}
        onChange={e => {
          const value = +e.target.value;
          setDataFollow('awarded', value);
          if (value === 0) {
            setDataFollow('assigned_amount', value);
            setDataFollow('income_received', null);
            setDataFollow('income_received_at', null);
          }
        }}
      />
    );
  };

  const asingDateValue = (date: string | null): string | null => {
    return date ? moment(date, DATE_FORMAT_DB).format(DATE_FORMAT) : null;
  };

  const setDateValue = (date: string | null) => {
    if (date === null || date.trim() === '') {
      setDataFollow('income_received_at', null);
      return;
    }
    const dateMoment = moment(date, DATE_FORMAT);
    if (dateMoment.isValid()) {
      setDataFollow('income_received_at', dateMoment.format(DATE_FORMAT_DB));
      return;
    }
  };

  const renderIncomeReceivedAt = (): JSX.Element | undefined => {
    if (!income_received) {
      return;
    }
    return (
      <KeyboardDatePickerController
        name="income_received_at"
        size="50"
        label={tScholarshipKey(`MecScholarship.income_received_at`)}
        control={control}
        schema={schema}
        ref={register}
        format={DATE_FORMAT}
        defaultValue={asingDateValue(income_received_at)}
        errors={errors}
        disabled={readOnly}
        onAccept={date => {
          const dateFormatted = date ? date.format(DATE_FORMAT_DB) : null;
          setDataFollow('income_received_at', dateFormatted);
        }}
        onBlur={e => {
          setDateValue(e.target.value);
        }}
      />
    );
  };

  const renderWhenAwarded = (): JSX.Element | undefined => {
    if (!awarded) {
      return;
    }
    return (
      <>
        <TextInputController
          type="number"
          control={control}
          errors={errors}
          defaultValue={assigned_amount}
          schema={schema}
          label={tScholarshipKey(`MecScholarship.${scholarshipType}.assigned_amount`)}
          name="assigned_amount"
          size="50"
          disabled={readOnly}
          price
          onBlur={e => {
            setDataFollow('assigned_amount', e.target.value);
          }}
        />
        <RadioButtonGroup
          name="income_received"
          errors={errors.income_received}
          disabled={readOnly}
          questionText={tScholarshipKey(`MecScholarship.${scholarshipType}.income_received`)}
          options={yesNoNumberOptions}
          value={income_received}
          register={register}
          onChange={e => {
            setDataFollow('income_received', +e.target.value);
          }}
        />
        {renderIncomeReceivedAt()}
      </>
    );
  };

  return (
    <FormContainer space title={tScholarshipKey('MecScholarship.title')}>
      <div className={styles.mecScholarshipContainer}>
        <RadioButtonGroup
          name="requested"
          errors={errors.requested}
          disabled={readOnly}
          questionText={labelTranslation()}
          options={yesNoNumberOptions}
          value={requested}
          register={register}
          onChange={e => {
            const value = +e.target.value;
            setDataFollow('requested', value);
            if (value === 0) {
              setDataFollow('awarded', null);
              setDataFollow('assigned_amount', value);
              setDataFollow('income_received', null);
              setDataFollow('income_received_at', null);
            }
          }}
        />
        {renderWhyNotRequested()}
        {renderAwarded()}
        {renderWhenAwarded()}
      </div>
    </FormContainer>
  );
};

export default MecScholarship;
