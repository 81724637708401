/* eslint-disable @typescript-eslint/no-explicit-any */
import { InputAdornment } from '@material-ui/core';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import FncButton from '../../../../components/FncButton/FncButton';
import FormContainer from '../../../../components/Forms/FormContainer';
import TextInput from '../../../../components/Inputs/TextInput';
import SubTitle from '../../../../components/SubTitle/Subtitle';
import { tScholarshipKey } from '../../../../helpers/translate';
import { selectUserCan } from '../../../../redux/permissions/selectors';
import { AppState } from '../../../../redux/root-reducer';
import { ScholarshipAgreementData } from '../../../../redux/scholarship/agreement/ScholarshipAgreement';
import { ScholarshipData } from '../../../../redux/scholarship/definitions';
import { getScholarshipPaymentAdjustmentAction } from '../../../../redux/scholarship/paymentAdjustments/actions';
import { ScholarshipPaymentAdjustmentData } from '../../../../redux/scholarship/paymentAdjustments/PaymentAdjustment';
import { selectGetScholarship } from '../../../../redux/scholarship/selectors';
import ScholarshipAdjustmentsTable from '../../Modals/PaymentAdjustment/ScholarshipAdjustmentsTable/ScholarshipAdjustmentsTable';
import ScholarshipPaymentAdjustmentModal from '../../Modals/PaymentAdjustment/ScholarshipPaymentAdjustmentModal';
import ScholarshipPaymentsTable from '../../Modals/PaymentAdjustment/ScholarshipPaymentsTable/ScholarshipPaymentsTable';
import styles from './paymentForm.module.scss';

interface PaymentFormAgreementPaymentsProps {
  scholarshipAgreementData: ScholarshipAgreementData;
  scholarshipData: ScholarshipData;
  scholarshipPayments: ScholarshipPaymentAdjustmentData;
  allowPaymentAdjustment: boolean;
  getScholarshipPayments: (scholarship_id: number) => void;
}

const PaymentFormAgreementPayments: React.FC<PaymentFormAgreementPaymentsProps> = ({
  scholarshipAgreementData,
  scholarshipData,
  allowPaymentAdjustment,
  scholarshipPayments,
  getScholarshipPayments,
}) => {
  const { scholarship_id, total_cost } = scholarshipAgreementData;
  const { scholarship_adjustments } = scholarshipPayments;
  const { student_name, student_surname } = scholarshipData;
  const [showAdjustmentModal, setShowAdjustmentModal] = useState<boolean>(false);

  const renderTotalCost = (): JSX.Element => {
    return (
      <TextInput
        type="number"
        label={tScholarshipKey('Coste total de la beca confirmada')}
        size="30"
        name="total_cost"
        disabled
        value={total_cost}
        InputProps={{
          endAdornment: <InputAdornment position="end">€</InputAdornment>,
        }}
      />
    );
  };

  const renderPaymentAdustmentButton = (): JSX.Element | undefined => {
    if (!allowPaymentAdjustment) {
      return undefined;
    }
    return (
      <div className={`${styles.buttonsWrapper} ${styles.buttonActionRight}`}>
        <FncButton customClass={styles.iconButton} onClick={() => setShowAdjustmentModal(true)} color="primary">
          <EuroSymbolIcon />
          <span>{tScholarshipKey('Ajuste de pagos')}</span>
        </FncButton>
      </div>
    );
  };

  const closeAdjustmentModal = (): void => {
    getScholarshipPayments(scholarship_id);
    setShowAdjustmentModal(false);
  };

  const renderAdjustmentModal = (): JSX.Element | undefined => {
    if (!showAdjustmentModal) {
      return undefined;
    }
    return (
      <ScholarshipPaymentAdjustmentModal
        show={showAdjustmentModal}
        handleClose={() => closeAdjustmentModal()}
        scholarshipId={scholarship_id}
        entityId={null}
        studentName={`${student_name} ${student_surname}`}
      />
    );
  };

  return (
    <FormContainer title={tScholarshipKey('Pagos de la beca')}>
      {renderTotalCost()}
      <ScholarshipPaymentsTable scholarshipId={scholarship_id} editable={false} />
      <SubTitle>{tScholarshipKey('Ajustes')}</SubTitle>
      <ScholarshipAdjustmentsTable scholarshipAdjustments={scholarship_adjustments} editable />
      {renderPaymentAdustmentButton()}
      {renderAdjustmentModal()}
    </FormContainer>
  );
};

const mapStateToProps = (state: AppState) => ({
  scholarshipData: selectGetScholarship(state),
  allowPaymentAdjustment: selectUserCan(state)('allow_payment_adjustment'),
});

const mapDispatchToProps = (dispatch: any) => ({
  getScholarshipPayments: (scholarship_id: number): void =>
    dispatch(getScholarshipPaymentAdjustmentAction(scholarship_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentFormAgreementPayments);
