class ScholarshipAbandonmentReasonCode {
  static FALTA_MOTIVACION = 'FMO';
  static FALTA_HABILIDADES = 'FHC';
  static DIFICULTADES_FAMILIARES = 'DFA';
  static PROBLEMAS_SALUD = 'PDS';
  static INCORPORACION_MUNDO_LABORAL = 'IML';
  static MUDANZA = 'MUD';
  static MOTIVOS_FAMILIARES = 'MFC';
  static MOTIVOS_ECONOMICOS = 'MEC';
  static FINAL_ITINERARIO_ALUMNI_ZING = 'FAL';
  static FIN_CURSO_CONTINUA = 'FCN';
  static DIFICULTADES_COMPETENCIAS = 'DCA';
  static SALUD_FISICA = 'SFI';
  static SALUD_MENTAL = 'SME';
  static INSATISFACCION_CENTRO = 'ICT';
  static INSATISFACCION_ESTUDIOS = 'IES';
  static DESVINCULACION = 'DES';
  static OTROS = 'OTR';

  constructor(private code: string) {}

  public value(): string {
    return this.code;
  }

  public isDesvinculacion(): boolean {
    return this.code === ScholarshipAbandonmentReasonCode.DESVINCULACION;
  }
}

export default ScholarshipAbandonmentReasonCode;
