import * as yup from 'yup';
import { IdTypeCodeNamePair } from '../redux/common/definitions';
import { ScholarshipFutureActionsType } from '../redux/scholarship/definitions';
import ScholarshipFutureActionCode from '../types/Scholarships/ScholarshipFutureActionCode';

const requiredText = 'El valor es obligatorio';

const followSchemaGeneric = {
  requested: yup.string().required(requiredText),
  why_not_requested: yup.string().nullable(),
  awarded: yup
    .string()
    .nullable()
    .when('requested', {
      is: requested => !!+requested,
      then: yup.string().required(requiredText),
    })
    .nullable(),
  assigned_amount: yup
    .number()
    .when('awarded', {
      is: awarded => !!+awarded,
      then: yup.number().typeError(requiredText),
    })
    .nullable(),
  income_received: yup
    .string()
    .when('awarded', {
      is: awarded => !!+awarded,
      then: yup.string().required(requiredText),
    })
    .nullable(),
  income_received_at: yup.string().nullable(),
  has_work_quaterly: yup.string().required(requiredText),
  has_work_relation_study: yup
    .string()
    .when('has_work_quaterly', {
      is: has_work_quaterly => !!+has_work_quaterly,
      then: yup.string().required(requiredText),
    })
    .nullable(),
  has_quaterly_internship: yup.string().required(requiredText),
  company_name: yup.string().nullable(),
  employee_role: yup.string().nullable(),
  contract_type: yup.string().nullable(),
  workday_type: yup.string().nullable(),
  working_start_month: yup.string().nullable(),
  working_start_year: yup.string().nullable(),
  current_incomes: yup.string().nullable(),
};

const followSchemaGenericEntityInstitut = {
  ...followSchemaGeneric,
  keep_studying: yup.string().required(requiredText),
  scholarship_abandoment_reasons_id: yup
    .string()
    .when('keep_studying', {
      is: keep_studying => !+keep_studying,
      then: yup.string().required(requiredText),
    })
    .nullable(),
  scholarship_abandoment_reasons_others: yup
    .string()
    .when('keep_studying', {
      is: keep_studying => !+keep_studying,
      then: yup.string().required(requiredText),
    })
    .nullable(),
  significant_situation_change: yup.string().required(requiredText),
  scholarship_change_reasons_id: yup
    .string()
    .when('significant_situation_change', {
      is: significant_situation_change => !!+significant_situation_change,
      then: yup.string().required(requiredText),
    })
    .nullable(),

  support_for_approved: yup.string().required(requiredText),
  scholarship_supports_id: yup
    .string()
    .when('support_for_approved', {
      is: support_for_approved => !!+support_for_approved,
      then: yup.string().required(requiredText),
    })
    .nullable(),
  scholarship_supports_reason_others: yup
    .string()
    .when('scholarship_supports_id', {
      is: scholarship_supports_id => +scholarship_supports_id === 4,
      then: yup.string().required(requiredText),
    })
    .nullable(),
  scholarship_results_id: yup
    .string()
    .when('has_scholarship_state', {
      is: has_scholarship_state => !!+has_scholarship_state,
      then: yup.string().required(requiredText),
    })
    .nullable(),
  state_scholarship_amount: yup
    .number()
    .nullable(true)
    .transform((v, o) => (o === '' ? null : v))
    .when('scholarship_results_id', {
      is: scholarship_results_id => +scholarship_results_id === 1,
      then: yup.number().required(requiredText),
    }),
};

export const entityFollowUpSchema = yup.object().shape({
  ...followSchemaGenericEntityInstitut,
  scholarship_student_meetings_id: yup.string().required(requiredText),
  scholarship_student_meetings_justify: yup
    .string()
    .when('scholarship_student_meetings_id', {
      is: scholarship_student_meetings_id => +scholarship_student_meetings_id === 5,
      then: yup.string().required(requiredText),
    })
    .nullable(),
});

export const instituteFollowUpSchema = yup.object().shape({
  ...followSchemaGenericEntityInstitut,
  has_mentor: yup.string().required(requiredText),
  scholarship_valoration: yup
    .string()
    .when('has_mentor', {
      is: has_mentor => !!+has_mentor,
      then: yup.string().required(requiredText),
    })
    .nullable(),
});

export const lastFollowUpSchema = (futureActions: IdTypeCodeNamePair<ScholarshipFutureActionsType>[]) => {
  const isNoContinuaYAbandona = (scholarship_future_actions_id: number) => {
    const scholarshipFutureActionsCode = ScholarshipFutureActionCode.findCode(
      futureActions,
      scholarship_future_actions_id,
    );
    if (!scholarshipFutureActionsCode) {
      return false;
    }

    return scholarshipFutureActionsCode.isNoContinuaYAbandona();
  };

  return yup.object().shape({
    ...followSchemaGeneric,
    scholarship_future_actions_id: yup
      .string()
      .when('keep_studying', {
        is: keep_studying => !keep_studying,
        then: yup.string().required(requiredText),
      })
      .nullable(),
    scholarship_abandoment_reasons_id: yup
      .string()
      .when('scholarship_future_actions_id', {
        is: scholarship_future_actions_id => isNoContinuaYAbandona(scholarship_future_actions_id),
        then: yup.string().required(requiredText),
      })
      .nullable(),
    scholarship_abandoment_reasons_others: yup
      .string()
      .when('scholarship_future_actions_id', {
        is: scholarship_future_actions_id => isNoContinuaYAbandona(scholarship_future_actions_id),
        then: yup.string().required(requiredText),
      })
      .nullable(),
    scholarship_student_changes_id: yup.string().required(requiredText),
    scholarship_student_personal_changes_reason_others: yup
      .string()
      .when('scholarship_student_changes_id', {
        is: scholarship_student_changes_id => +scholarship_student_changes_id === 5,
        then: yup.string().required(requiredText),
      })
      .nullable(),
    per_scholarship_academic_levels_id: yup.string().required(requiredText),
    scholarship_academic_levels_id: yup.string().required(requiredText),
    scholarship_academy_supports_id: yup.string().required(requiredText),
    scholarship_opportunities_id: yup.string().required(requiredText),
    special_academic_center: yup.string().required(requiredText),
    special_academic_center_reasons: yup
      .string()
      .when('special_academic_center', {
        is: special_academic_center => !+special_academic_center,
        then: yup.string().required(requiredText),
      })
      .nullable(),
    academic_center_social_risk: yup.string().required(requiredText),
    academic_center_social_risk_reasons: yup
      .string()
      .when('academic_center_social_risk', {
        is: academic_center_social_risk => !+academic_center_social_risk,
        then: yup.string().required(requiredText),
      })
      .nullable(),
    scholarship_personal_skills_id: yup.string().required(requiredText),
    zing_programme_valoration: yup.string().required(requiredText),
    scholarship_valorations_reasons: yup.string().required(requiredText),
    zing_programme_support_valoration: yup.string().required(requiredText),
    valoration_nouscims_support_reason: yup.string().required(requiredText),
    end_scholarship_future_actions_id: yup.string().required(requiredText),
    scholarship_personal_skills_reason_others: yup
      .string()
      .when('scholarship_personal_skills_id', {
        is: scholarship_personal_skills_id => +scholarship_personal_skills_id === 9,
        then: yup.string().required(requiredText),
      })
      .nullable(),
    mentoring_participation: yup.string(),
    mentor_evaluation: yup.string(),
    mentor_relationship_evaluation: yup.string(),
    recommend_academic_center: yup.string().required(requiredText),
    recommend_scholarship: yup.string().required(requiredText),
  });
};
