import * as yup from 'yup';
import { tErrorKey } from '../helpers/translate';
import { ScheduledAppointmentState } from '../redux/project/definitions';
import { paymentSettingsForm } from './paymentSettingsForm';

const requiredText = tErrorKey('Este campo es obligatorio');

export const tracingSchema = yup.object().shape({
  kick_off: yup
    .string()
    .typeError('La fecha de inicio de proyecto es obligatoria')
    .required(requiredText),
  date_end_project: yup
    .string()
    .typeError('La fecha de fin de proyecto es obligatoria')
    .required(requiredText),
});

export const agreementSchema = yup.object().shape({
  amount: yup
    .number()
    .typeError('Tiene que ser un valor númerico.')
    .required(requiredText),
  ...paymentSettingsForm,
});

export const projectScheduleItemSchema = yup.object().shape({
  tracking_date: yup
    .string()
    .trim()
    .required('La fecha es obligatoria'),
  state: yup.string().required(requiredText),
  partial_amount: yup
    .number()
    .typeError('Tiene que ser un valor númerico')
    .when('state', {
      is: ScheduledAppointmentState.PAYMENT,
      then: yup.number().typeError('Tiene que ser un valor númerico'),
    }),
});

export const projectScheduleItemPhoneCheckSchema = yup.object().shape({
  action_resum: yup.string(),
});

export const modifyScheduleSchema = yup.object().shape({
  tracking_end_date: yup.string().required(requiredText),
  file_id: yup.number(),
  new_tracking_end_date: yup.string(),
  project_amount: yup.number(),
  amount: yup.string(),
});
