class FollowUpValoration {
  static MUY_POSITIVA = 'Muy positiva';
  static POSITIVA = 'Positiva';
  static REGULAR = 'Regular';
  static NEGATIVA = 'Negativa';

  constructor(private code: string) {}

  public value(): string {
    return this.code;
  }

  public static toArray(): string[] {
    return [
      FollowUpValoration.MUY_POSITIVA,
      FollowUpValoration.POSITIVA,
      FollowUpValoration.REGULAR,
      FollowUpValoration.NEGATIVA,
    ];
  }
}

export default FollowUpValoration;
