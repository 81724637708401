import { MenuItem } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { Control, FieldErrors, FieldValues } from 'react-hook-form/dist/types';
import { ObjectSchema, Shape } from 'yup';
import FormContainer from '../../../../components/Forms/FormContainer';
import TextInputController from '../../../../components/Inputs/TextInputController/TextInputController';
import RadioButtonGroup from '../../../../components/RadioButtons/RadioButtonGroup';
import { zeroToTenOptions } from '../../../../components/RadioButtons/RadioButtonOptions';
import SelectController from '../../../../components/Select/SelectController';
import { tScholarshipKey } from '../../../../helpers/translate';
import { ScholarshipFollow } from '../../../../redux/scholarship/definitions';
import FollowUpValoration from '../../../../types/Scholarships/FollowUpValoration';
import styles from './followForm.module.scss';

interface ScholarshipZingProps<T extends FieldValues> {
  scholarshipFollow: ScholarshipFollow;
  readOnly?: boolean;
  errors: FieldErrors<T> | any;
  control: Control<T>;
  schema: ObjectSchema<T> | ObjectSchema<Shape<object, any>>;
  register?: ReturnType<typeof useForm>['register'];
  setDataFollow: (subKey: string, value: string | number | null) => void;
}

const ScholarshipZing = <T extends FieldValues>({
  scholarshipFollow,
  readOnly,
  errors,
  control,
  schema,
  register,
  setDataFollow,
}: ScholarshipZingProps<T>): ReactElement => {
  const {
    zing_programme_valoration,
    scholarship_valorations_reasons,
    recommend_scholarship,
    zing_programme_support_valoration,
    valoration_nouscims_support_reason,
  } = scholarshipFollow;

  const handleSelectChange = (target: any) => {
    if (target.name) {
      setDataFollow(target.name, target.value);
    }
  };

  return (
    <FormContainer title={tScholarshipKey('Valoración de la Beca Zing')}>
      <SelectController
        control={control}
        name="zing_programme_valoration"
        label={tScholarshipKey('valoración-jovén-beca-zing')}
        defaultValue={zing_programme_valoration}
        size="60"
        validator={!readOnly}
        disabled={readOnly}
        schema={schema}
        errors={errors}
        onClick={e => handleSelectChange(e.target)}
      >
        {FollowUpValoration.toArray().map((element: string) => (
          <MenuItem key={element} value={element}>
            {tScholarshipKey(`follow_up.valoration.${element}`)}
          </MenuItem>
        ))}
      </SelectController>
      <TextInputController
        control={control}
        name="scholarship_valorations_reasons"
        label={tScholarshipKey('Explica el porqué de tu respuesta anterior')}
        defaultValue={scholarship_valorations_reasons}
        size="100"
        validator={!readOnly}
        schema={schema}
        errors={errors}
        disabled={readOnly}
        multiline
        rows={3}
        rowsMax={3}
        onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
          setDataFollow('scholarship_valorations_reasons', e.target.value)
        }
      />
      <div className={styles.containerQuestion}>
        <RadioButtonGroup
          name="recommend_scholarship"
          errors={errors.recommend_scholarship}
          disabled={readOnly}
          questionText={tScholarshipKey('recomendar-beca-zinger')}
          options={zeroToTenOptions}
          value={recommend_scholarship?.toString() || '0'}
          register={register}
          onChange={e => {
            setDataFollow('recommend_scholarship', +e.target.value);
          }}
        />
        <SelectController
          control={control}
          name="zing_programme_support_valoration"
          label={tScholarshipKey('apoyo-equipo-zing')}
          defaultValue={zing_programme_support_valoration}
          size="60"
          validator={!readOnly}
          disabled={readOnly}
          schema={schema}
          errors={errors}
          onClick={e => handleSelectChange(e.target)}
        >
          {FollowUpValoration.toArray().map((element: string) => (
            <MenuItem key={element} value={element}>
              {tScholarshipKey(`follow_up.valoration.${element}`)}
            </MenuItem>
          ))}
        </SelectController>
      </div>
      <TextInputController
        control={control}
        name="valoration_nouscims_support_reason"
        label={tScholarshipKey('Explicanos el porqué de tu respuesta anterior') + ':'}
        defaultValue={valoration_nouscims_support_reason}
        size="100"
        disabled={readOnly}
        validator={!readOnly}
        schema={schema}
        errors={errors}
        multiline
        rows={3}
        rowsMax={3}
        onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
          setDataFollow('valoration_nouscims_support_reason', e.target.value)
        }
      />
    </FormContainer>
  );
};

export default ScholarshipZing;
