import { ButtonProps, MenuItem } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import FormContainer from '../../../components/Forms/FormContainer';
import LayoutForm from '../../../components/Layout/LayoutForm';
import ActionsMenu from '../../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import Loading from '../../../components/Loading/Loading';
import SelectController from '../../../components/Select/SelectController';
import { tFormKey, tKey } from '../../../helpers/translate';
import {
  getScholarshipSetting,
  scholarshipSetDataSettings,
  updateScholarshipSettingAction,
} from '../../../redux/accounting/actions';
import { ScholarshipSetting } from '../../../redux/accounting/definitions';
import { selectScholarshipSetting } from '../../../redux/accounting/selectors';
import { GenericMetadata } from '../../../redux/metadata/definitions';
import { selectGenericMetadata } from '../../../redux/metadata/selectors';
import { AppState } from '../../../redux/root-reducer';
import { AccoutingDefaultCenterCostSchema } from '../../../validations/formSchema';
import AccountingSubmenu from '../AccountingSubmenu';
import styles from './costCenterDefaults.module.scss';
import { CostCenterCode } from './SwitchCostCenter';

interface CostCenterDefaultsProps {
  loading: boolean;
  section: CostCenterCode;
  scholarshipSetting: ScholarshipSetting;
  genericMetadata: GenericMetadata;
  setData: (key: string, value: string | number) => void;
  updateScholarshipsetting: (value: ScholarshipSetting) => void;
  getScholarshipsetting: () => void;
}

const CostCenterDefaults: React.FC<CostCenterDefaultsProps> = ({
  loading,
  section,
  scholarshipSetting,
  genericMetadata,
  setData,
  updateScholarshipsetting,
  getScholarshipsetting,
}) => {
  const {
    ent_center_cost_channel_code,
    ent_center_cost_delegation_code,
    ent_center_cost_area_code,
    ent_center_cost_project_code,
    ins_center_cost_channel_code,
    ins_center_cost_delegation_code,
    ins_center_cost_area_code,
    ins_center_cost_project_code,
    adm_man_center_cost_channel_code,
    adm_man_center_cost_delegation_code,
    adm_man_center_cost_area_code,
    adm_man_center_cost_project_code,
  } = scholarshipSetting;

  const { channels, delegations, areas, projects } = genericMetadata;

  const entChildSelects = [
    'ent_center_cost_delegation_code',
    'ent_center_cost_area_code',
    'ent_center_cost_project_code',
  ];

  const insChildSelects = [
    'ins_center_cost_delegation_code',
    'ins_center_cost_area_code',
    'ins_center_cost_project_code',
  ];

  const admManChildSelects = [
    'adm_man_center_cost_delegation_code',
    'adm_man_center_cost_area_code',
    'adm_man_center_cost_project_code',
  ];

  const emptyValue: JSX.Element[] = [
    <MenuItem key="empty" value="">
      ----
    </MenuItem>,
  ];

  const { errors, control, handleSubmit, setValue } = useForm<ScholarshipSetting>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: AccoutingDefaultCenterCostSchema,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSelect = (target: any) => {
    if (target.name) {
      setData(target.name, target.value);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChildSelect = (target: any, child: string[]) => {
    if (target.name) {
      setData(target.name, target.value);
      child.forEach(input => {
        setData(input, '');
        setValue(input, '');
      });
    }
  };

  useEffect(() => {
    getScholarshipsetting();
  }, [getScholarshipsetting]);

  const onSave = () => {
    updateScholarshipsetting(scholarshipSetting);
  };

  const buttonsSave: ButtonProps[] = [
    {
      children: tKey('Guardar'),
      onClick: handleSubmit(onSave),
      startIcon: <Save />,
      disableElevation: true,
      variant: 'contained',
      color: 'primary',
      fullWidth: true,
    },
  ];

  return (
    <LayoutForm
      leftSubmenu={<AccountingSubmenu selected={section} />}
      rightSubmenu={<ActionsMenu actionsButtons={buttonsSave} />}
    >
      {loading ? (
        <Loading big />
      ) : (
        <div className={styles.costCenterDefaultForm}>
          <FormContainer title={tKey('Becas de Entidad')}>
            <SelectController
              size="50"
              control={control}
              errors={errors}
              schema={AccoutingDefaultCenterCostSchema}
              defaultValue={ent_center_cost_channel_code}
              name="ent_center_cost_channel_code"
              label={tFormKey('Canal')}
              onClick={e => handleChildSelect(e.target, entChildSelects)}
            >
              {emptyValue.concat(
                channels.map(type => (
                  <MenuItem key={type.code} value={type.code}>
                    {type.name} ({type.code})
                  </MenuItem>
                )),
              )}
            </SelectController>
            <SelectController
              size="50"
              control={control}
              errors={errors}
              schema={AccoutingDefaultCenterCostSchema}
              defaultValue={ent_center_cost_delegation_code}
              name="ent_center_cost_delegation_code"
              label={tFormKey('Delegación')}
              onClick={e => handleChildSelect(e.target, ['ent_center_cost_area_code', 'ent_center_cost_project_code'])}
            >
              {emptyValue.concat(
                delegations
                  ?.filter(e => e.channel_code === ent_center_cost_channel_code || e.channel_code === null)
                  .map(type => (
                    <MenuItem key={type.code} value={type.code}>
                      {type.name}
                    </MenuItem>
                  )),
              )}
            </SelectController>
            <SelectController
              size="50"
              control={control}
              errors={errors}
              schema={AccoutingDefaultCenterCostSchema}
              defaultValue={ent_center_cost_area_code}
              name="ent_center_cost_area_code"
              label={tFormKey('Área')}
              onClick={e => handleChildSelect(e.target, ['ent_center_cost_project_code'])}
            >
              {emptyValue.concat(
                areas
                  ?.filter(e => e.delegation_code === ent_center_cost_delegation_code || e.delegation_code === null)
                  .map(type => (
                    <MenuItem key={type.code || '0'} value={type.code}>
                      {type.name}
                    </MenuItem>
                  )),
              )}
            </SelectController>
            <SelectController
              size="50"
              control={control}
              errors={errors}
              schema={AccoutingDefaultCenterCostSchema}
              defaultValue={ent_center_cost_project_code}
              name="ent_center_cost_project_code"
              label={tFormKey('Proyecto')}
              onClick={e => handleSelect(e.target)}
            >
              {emptyValue.concat(
                projects
                  ?.filter(e => e.area_code === ent_center_cost_area_code || e.area_code === null)
                  .map(type => (
                    <MenuItem key={type.code || '0'} value={type.code}>
                      {type.name}
                    </MenuItem>
                  )),
              )}
            </SelectController>
          </FormContainer>
          <FormContainer title={tKey('Becas de Instituto')}>
            <SelectController
              size="50"
              control={control}
              errors={errors}
              schema={AccoutingDefaultCenterCostSchema}
              defaultValue={ins_center_cost_channel_code}
              name="ins_center_cost_channel_code"
              label={tFormKey('Canal')}
              onClick={e => handleChildSelect(e.target, insChildSelects)}
            >
              {emptyValue.concat(
                channels.map(type => (
                  <MenuItem key={type.code} value={type.code}>
                    {type.name} ({type.code})
                  </MenuItem>
                )),
              )}
            </SelectController>
            <SelectController
              size="50"
              control={control}
              errors={errors}
              schema={AccoutingDefaultCenterCostSchema}
              defaultValue={ins_center_cost_delegation_code}
              name="ins_center_cost_delegation_code"
              label={tFormKey('Delegación')}
              onClick={e =>
                handleChildSelect(e.target, ['ins_center_cost_project_code', 'ins_center_cost_project_code'])
              }
            >
              {emptyValue.concat(
                delegations
                  ?.filter(e => e.channel_code === ins_center_cost_channel_code || e.channel_code === null)
                  .map(type => (
                    <MenuItem key={type.code} value={type.code}>
                      {type.name}
                    </MenuItem>
                  )),
              )}
            </SelectController>
            <SelectController
              size="50"
              control={control}
              errors={errors}
              schema={AccoutingDefaultCenterCostSchema}
              defaultValue={ins_center_cost_area_code}
              name="ins_center_cost_area_code"
              label={tFormKey('Área')}
              onClick={e => handleChildSelect(e.target, ['ins_center_cost_project_code'])}
            >
              {emptyValue.concat(
                areas
                  ?.filter(e => e.delegation_code === ins_center_cost_delegation_code || e.delegation_code === null)
                  .map(type => (
                    <MenuItem key={type.code || '0'} value={type.code}>
                      {type.name}
                    </MenuItem>
                  )),
              )}
            </SelectController>
            <SelectController
              size="50"
              control={control}
              errors={errors}
              schema={AccoutingDefaultCenterCostSchema}
              defaultValue={ins_center_cost_project_code}
              name="ins_center_cost_project_code"
              label={tFormKey('Proyecto')}
              onClick={e => handleSelect(e.target)}
            >
              {emptyValue.concat(
                projects
                  ?.filter(e => e.area_code === ins_center_cost_area_code || e.area_code === null)
                  .map(type => (
                    <MenuItem key={type.code || '0'} value={type.code}>
                      {type.name}
                    </MenuItem>
                  )),
              )}
            </SelectController>
          </FormContainer>
          <FormContainer title={tKey('Gestión administrativa')}>
            <SelectController
              size="50"
              control={control}
              errors={errors}
              schema={AccoutingDefaultCenterCostSchema}
              defaultValue={adm_man_center_cost_channel_code}
              name="adm_man_center_cost_channel_code"
              label={tFormKey('Canal')}
              onClick={e => handleChildSelect(e.target, admManChildSelects)}
            >
              {emptyValue.concat(
                channels.map(type => (
                  <MenuItem key={type.code} value={type.code}>
                    {type.name} ({type.code})
                  </MenuItem>
                )),
              )}
            </SelectController>
            <SelectController
              size="50"
              control={control}
              errors={errors}
              schema={AccoutingDefaultCenterCostSchema}
              defaultValue={adm_man_center_cost_delegation_code}
              name="adm_man_center_cost_delegation_code"
              label={tFormKey('Delegación')}
              onClick={e =>
                handleChildSelect(e.target, ['adm_man_center_cost_project_code', 'adm_man_center_cost_project_code'])
              }
            >
              {emptyValue.concat(
                delegations
                  ?.filter(e => e.channel_code === adm_man_center_cost_channel_code || e.channel_code === null)
                  .map(type => (
                    <MenuItem key={type.code} value={type.code}>
                      {type.name}
                    </MenuItem>
                  )),
              )}
            </SelectController>
            <SelectController
              size="50"
              control={control}
              errors={errors}
              schema={AccoutingDefaultCenterCostSchema}
              defaultValue={adm_man_center_cost_area_code}
              name="adm_man_center_cost_area_code"
              label={tFormKey('Área')}
              onClick={e => handleChildSelect(e.target, ['adm_man_center_cost_project_code'])}
            >
              {emptyValue.concat(
                areas
                  ?.filter(e => e.delegation_code === adm_man_center_cost_delegation_code || e.delegation_code === null)
                  .map(type => (
                    <MenuItem key={type.code || '0'} value={type.code}>
                      {type.name}
                    </MenuItem>
                  )),
              )}
            </SelectController>
            <SelectController
              size="50"
              control={control}
              errors={errors}
              schema={AccoutingDefaultCenterCostSchema}
              defaultValue={adm_man_center_cost_project_code}
              name="adm_man_center_cost_project_code"
              label={tFormKey('Proyecto')}
              onClick={e => handleSelect(e.target)}
            >
              {emptyValue.concat(
                projects
                  ?.filter(e => e.area_code === adm_man_center_cost_area_code || e.area_code === null)
                  .map(type => (
                    <MenuItem key={type.code || '0'} value={type.code}>
                      {type.name}
                    </MenuItem>
                  )),
              )}
            </SelectController>
          </FormContainer>
        </div>
      )}
    </LayoutForm>
  );
};

const mapStateProps = (state: AppState) => ({
  loading: state.accountingReducer.loading,
  scholarshipSetting: selectScholarshipSetting(state),
  genericMetadata: selectGenericMetadata(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  setData: (key: string, value: string | number) => dispatch(scholarshipSetDataSettings(key, value)),
  updateScholarshipsetting: (value: ScholarshipSetting): void => dispatch(updateScholarshipSettingAction(value)),
  getScholarshipsetting: (): void => dispatch(getScholarshipSetting()),
});

export default connect(mapStateProps, mapDispatchToProps)(CostCenterDefaults);
