/* eslint-disable @typescript-eslint/no-explicit-any */
import { MenuItem } from '@material-ui/core';
import React from 'react';
import { Control, FieldErrors, FieldValues } from 'react-hook-form';
import { connect } from 'react-redux';
import { ObjectSchema, Shape } from 'yup';
import TextInputController from '../../../../../components/Inputs/TextInputController/TextInputController';
import SelectController from '../../../../../components/Select/SelectController';
import { findAndReturnCurrying } from '../../../../../helpers/findAndReturnCurrying';
import { tScholarshipKey } from '../../../../../helpers/translate';
import { validateFields } from '../../../../../helpers/validator';
import { IdCodeNamePair, IdNamePair } from '../../../../../redux/common/definitions';
import { AppState } from '../../../../../redux/root-reducer';
import { scholarshipSetEducationalInfoData } from '../../../../../redux/scholarship/actions';
import { ScholarshipData, ScholarshipMetadata, ScholarshipSteps } from '../../../../../redux/scholarship/definitions';
import { selectGetScholarshipMetadata } from '../../../../../redux/scholarship/selectors';

interface EducationalValorationCourseFormProps<T extends FieldValues> {
  scholarshipData: ScholarshipData;
  isLastCourse: boolean;
  scholarshipMetadata: ScholarshipMetadata;
  errors: FieldErrors<T>;
  schema: ObjectSchema<T> | ObjectSchema<Shape<object, any>>;
  control: Control<T>;
  readOnly: boolean;
  setData: (key: string, value: string | number) => void;
}

const EducationalValorationCourseForm = <T extends FieldValues>({
  scholarshipData,
  isLastCourse,
  scholarshipMetadata,
  errors,
  schema,
  control,
  readOnly,
  setData,
}: EducationalValorationCourseFormProps<T>) => {
  const validatorInfo = {
    data: scholarshipData,
    step: 'educationalValoration' as keyof ScholarshipSteps,
  };

  const { scholarshipType } = scholarshipData;

  const { educationalLevels, educationalTypes, professionalGroups, courses } = scholarshipMetadata;

  const handleSelectChange = (target: any) => {
    setData(target.name, target.value);
  };

  const handleEducationalTypeSelectChange = (target: any) => {
    setData(target.name, target.value);
    const educationalTypeCode = educationalTypes.find((et: IdCodeNamePair) => et.id === target.value)?.code;
    if (isLastCourse) {
      setData('finished_educational_type_code', educationalTypeCode || '');
      setData('last_professional_group_id', '');
      setData('last_scholarship_course_id', '');
      setData('last_course_name', '');
    } else {
      setData('educational_type_code', educationalTypeCode || '');
      setData('professional_group_id', '');
      setData('scholarship_course_id', '');
      setData('course_name', '');
    }
  };

  const handleProfessionalGroupSelectChange = (target: any) => {
    setData(target.name, target.value);
    const lastPrefix = isLastCourse ? 'last_' : '';
    setData(`${lastPrefix}scholarship_course_id`, '');
    setData(`${lastPrefix}course_name`, '');
  };

  const renderEducationalLevel = (): JSX.Element => {
    const fieldName = isLastCourse ? 'last_educational_level_id' : 'educational_level_id';
    const educationalLevelId = isLastCourse
      ? scholarshipData.last_educational_level_id
      : scholarshipData.educational_level_id;
    return (
      <SelectController
        control={control}
        errors={errors}
        size="50"
        schema={schema}
        key={fieldName}
        name={fieldName}
        disabled={readOnly}
        defaultValue={educationalLevelId}
        label={tScholarshipKey(`${scholarshipType}.${fieldName}`)}
        validator={validateFields(scholarshipData, fieldName, validatorInfo.step)}
        onClick={e => handleSelectChange(e.target)}
        clipBoard={!isLastCourse}
        onClipBoard={
          !isLastCourse
            ? findAndReturnCurrying({ data: educationalLevels, keyReturn: 'name', keySearch: 'id' })
            : undefined
        }
      >
        {educationalLevels.map((element: IdNamePair) => (
          <MenuItem key={element.id} value={element.id}>
            {element.name}
          </MenuItem>
        ))}
      </SelectController>
    );
  };

  const renderEducationalType = (): JSX.Element => {
    const fieldName = isLastCourse ? 'finished_educational_type_id' : 'educational_type_id';
    const educationalTypeId = isLastCourse
      ? scholarshipData.finished_educational_type_id
      : scholarshipData.educational_type_id;

    const educationalTypes_filtered = educationalTypes.filter(
      (et: IdCodeNamePair) => et.code !== 'PRI' && et.code !== 'IN2' && et.code !== 'EE',
    );

    return (
      <SelectController
        control={control}
        errors={errors}
        size="50"
        schema={schema}
        key={fieldName}
        name={fieldName}
        disabled={readOnly}
        defaultValue={educationalTypeId}
        label={tScholarshipKey(`${scholarshipType}.${fieldName}`)}
        validator={validateFields(scholarshipData, fieldName, validatorInfo.step)}
        onClick={e => handleEducationalTypeSelectChange(e.target)}
        clipBoard={!isLastCourse}
        onClipBoard={
          !isLastCourse
            ? findAndReturnCurrying({ data: educationalTypes_filtered, keyReturn: 'name', keySearch: 'id' })
            : undefined
        }
      >
        {educationalTypes_filtered.map((element: IdNamePair) => (
          <MenuItem key={element.id} value={element.id}>
            {element.name}
          </MenuItem>
        ))}
      </SelectController>
    );
  };

  const renderProfessionalGroup = (): JSX.Element => {
    const fieldName = isLastCourse ? 'last_professional_group_id' : 'professional_group_id';
    const professionalGroupId = isLastCourse
      ? scholarshipData.last_professional_group_id
      : scholarshipData.professional_group_id;
    const educationalTypeCode = isLastCourse
      ? scholarshipData.finished_educational_type_code
      : scholarshipData.educational_type_code;

    const professional_group_filtered = professionalGroups.filter(
      (pg: IdCodeNamePair) => pg.code === educationalTypeCode,
    );

    return (
      <SelectController
        control={control}
        errors={errors}
        size="50"
        schema={schema}
        key={fieldName}
        name={fieldName}
        disabled={readOnly}
        defaultValue={professionalGroupId}
        label={tScholarshipKey(`${scholarshipType}.${fieldName}`)}
        validator={validateFields(scholarshipData, fieldName, validatorInfo.step)}
        onClick={e => handleProfessionalGroupSelectChange(e.target)}
        clipBoard={!isLastCourse}
        onClipBoard={
          !isLastCourse
            ? findAndReturnCurrying({ data: professional_group_filtered, keyReturn: 'name', keySearch: 'id' })
            : undefined
        }
      >
        {professional_group_filtered.map((element: IdNamePair) => (
          <MenuItem key={element.id} value={element.id}>
            {element.name}
          </MenuItem>
        ))}
      </SelectController>
    );
  };

  const renderCourseText = (): JSX.Element => {
    const fieldName = isLastCourse ? 'last_course_name' : 'course_name';
    const labelText = isLastCourse ? 'last_scholarship_course_id' : 'scholarship_course_id';
    const courseName = isLastCourse ? scholarshipData.last_course_name : scholarshipData.course_name;
    return (
      <TextInputController
        control={control}
        errors={errors}
        size="50"
        label={tScholarshipKey(`${scholarshipType}.${labelText}`)}
        name={fieldName}
        defaultValue={courseName}
        disabled
        schema={schema}
      />
    );
  };

  const renderCourseSelector = (): JSX.Element => {
    const fieldName = isLastCourse ? 'last_scholarship_course_id' : 'scholarship_course_id';
    const scholarshipCourseId = isLastCourse
      ? scholarshipData.last_scholarship_course_id
      : scholarshipData.scholarship_course_id;

    const professionalGroupId = isLastCourse
      ? scholarshipData.last_professional_group_id
      : scholarshipData.professional_group_id;

    const courses_filtered = courses.filter(
      (pg: IdCodeNamePair) => pg.code?.toString() === professionalGroupId?.toString(),
    );

    return (
      <SelectController
        control={control}
        errors={errors}
        size="50"
        schema={schema}
        key={fieldName}
        name={fieldName}
        disabled={readOnly}
        defaultValue={scholarshipCourseId}
        label={tScholarshipKey(`${scholarshipType}.${fieldName}`)}
        validator={validateFields(scholarshipData, fieldName, validatorInfo.step)}
        onClick={e => handleSelectChange(e.target)}
        clipBoard={!isLastCourse}
        onClipBoard={
          !isLastCourse
            ? findAndReturnCurrying({ data: courses_filtered, keyReturn: 'name', keySearch: 'id' })
            : undefined
        }
      >
        {courses_filtered.map((element: IdNamePair) => (
          <MenuItem key={element.id} value={element.id}>
            {element.name}
          </MenuItem>
        ))}
      </SelectController>
    );
  };

  return (
    <>
      {renderEducationalLevel()}
      {renderEducationalType()}
      {renderProfessionalGroup()}
      {readOnly ? renderCourseText() : renderCourseSelector()}
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  scholarshipMetadata: selectGetScholarshipMetadata(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  setData: (key: string, value: string | number): void => dispatch(scholarshipSetEducationalInfoData(key, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EducationalValorationCourseForm);
