import { SubmenuItem } from '../../../components/Layout/Menus/Submenu/Submenu';
import { permissionType } from '../../../constants/permissions_definitions';
import { tKey } from '../../../helpers/translate';
import { UserType } from '../../../redux/user/definitions';

interface OptionsSubmenuProps {
  currentType: UserType;
  permissions: permissionType[];
  isNousCims?: boolean;
}

export const userOptionsSubmenu = (props: OptionsSubmenuProps): SubmenuItem[] => {
  const canISeeStaff = props.permissions.includes('get_staff_users');
  const canISeeStudent = props.permissions.includes('get_student_users');
  const canISeeEntities = props.permissions.includes('get_entities_users');
  const canISeeExternal = props.permissions.includes('get_external_users');
  const canISeeAcademy = props.permissions.includes('get_academy_users');
  const canSearchUsers = props.permissions.includes('allow_search_users');

  return [
    {
      title: tKey('users.staff'),
      route: '/usuarios/staff',
      type: 'route',
      selected: props.currentType === 'staff',
      hidden: !canISeeStaff,
    },
    {
      title: tKey('users.student'),
      route: '/usuarios/student',
      type: 'route',
      selected: props.currentType === 'student',
      hidden: !canISeeStudent,
    },
    {
      title: tKey('users.entity'),
      route: '/usuarios/entity',
      type: 'route',
      selected: props.currentType === 'entity',
      hidden: !canISeeEntities,
    },
    {
      title: tKey('users.external'),
      route: '/usuarios/ext',
      type: 'route',
      selected: props.currentType === 'ext',
      hidden: !canISeeExternal,
    },
    {
      title: tKey('users.academy'),
      route: '/usuarios/academy',
      type: 'route',
      selected: props.currentType === 'academy',
      hidden: !canISeeAcademy,
    },
    {
      title: tKey('users.mailing'),
      route: '/usuarios/mailing',
      type: 'route',
      selected: props.currentType === 'mailing',
      hidden: !props.isNousCims,
    },
    {
      title: tKey('users.search'),
      route: '/usuarios/buscar',
      type: 'route',
      selected: props.currentType === 'search',
      hidden: !canSearchUsers,
    },
  ];
};
