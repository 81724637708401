import { useState } from 'react';
import { deleteUserFromApi, searchUserFromApi, updateSearchUserFromApi } from '../../../../api/user';
import { SearchUserData } from '../../../../redux/user/definitions';
import errorMessage from '../../../../helpers/errorMessage';
import { tFormKey } from '../../../../helpers/translate';

type UseSearchUserType = {
  userData: SearchUserData;
  loading: boolean;
  message: string | null;
  findUser: (email: string) => void;
  updateUser: (data: SearchUserData) => void;
  deleteUser: (user_id: number) => void;
};

const useSearchUser = (): UseSearchUserType => {
  const defaultData = {
    id: 0,
    name: '',
    surname: '',
    surname2: '',
    email: '',
    active_role: '',
    entity: '',
    created_at: '',
  } as SearchUserData;

  const [loading, setLoading] = useState<boolean>(false);
  const [userData, setUserData] = useState<SearchUserData>(defaultData);
  const [message, setMessage] = useState<string | null>(null);

  const findUser = async (email: string) => {
    setLoading(true);
    setUserData({} as SearchUserData);
    setMessage(null);

    searchUserFromApi(email)
      .then((data: SearchUserData) => {
        setUserData(data);
      })
      .catch(error => {
        const error_message = errorMessage(error);
        setMessage(error_message);
      })
      .finally(() => setLoading(false));
  };

  const deleteUser = async (user_id: number) => {
    setLoading(true);
    setMessage(null);
    deleteUserFromApi(user_id)
      .then(() => {
        setMessage(tFormKey('users.search.user_deleted'));
      })
      .catch(error => {
        const error_message = errorMessage(error);
        setMessage(error_message);
      })
      .finally(() => setLoading(false));
  };

  const updateUser = async (data: SearchUserData) => {
    setLoading(true);
    setMessage(null);

    updateSearchUserFromApi(data)
      .then(() => {
        setMessage(`${tFormKey('users.search.user_updated')} ${data.email}`);
      })
      .catch(error => {
        const error_message = errorMessage(error);
        setMessage(error_message);
      })
      .finally(() => setLoading(false));
  };

  return {
    userData,
    loading,
    message,
    findUser,
    updateUser,
    deleteUser,
  };
};

export default useSearchUser;
