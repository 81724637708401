class Gender {
  static MUJER = 'Mujer';
  static HOMBRE = 'Hombre';
  static NO_BINARIO = 'No binario/a';
  static NO_RESPONDE = 'No quiero responder';
  static OTROS = 'Otros';

  constructor(private code: string) {}

  public value(): string {
    return this.code;
  }

  public static toArray(): string[] {
    return [Gender.HOMBRE, Gender.MUJER, Gender.NO_BINARIO, Gender.NO_RESPONDE, Gender.OTROS];
  }
}

export default Gender;
