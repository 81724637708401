import { APIValidationError } from '../../api/api';
import { DocumentationItem } from '../common/definitions';
import { ScholarshipDataConsts } from './action_types';
import {
  CleanScholarshipDataAction,
  ExportScholarshipReportsAction,
  ExportScholarshipReportsKoAction,
  ExportScholarshipReportsOkAction,
  ExportSourceType,
  FollowUpsType,
  GetConfigurationFollowupAction,
  GetConfigurationFollowupKoAction,
  GetConfigurationFollowupOkAction,
  GetDocumentScholarshipAction,
  GetDocumentScholarshipKoAction,
  GetDocumentScholarshipOkAction,
  Material,
  RemoveDocumentScholarshipAction,
  RemoveDocumentScholarshipKoAction,
  RemoveDocumentScholarshipOkAction,
  RunConfigurationFollowupAction,
  RunConfigurationFollowupKoAction,
  RunConfigurationFollowupOkAction,
  SaveFollowUpAction,
  ScholarshipAddMaterialAction,
  ScholarshipAddMaterialOkAction,
  ScholarshipData,
  ScholarshipDownloadDocumentAction,
  ScholarshipDownloadDocumentKoAction,
  ScholarshipDownloadDocumentOkAction,
  ScholarshipFollow,
  ScholarshipGetAction,
  ScholarshipGetDocumentationAction,
  ScholarshipGetDocumentationKoAction,
  ScholarshipGetDocumentationOkAction,
  ScholarshipGetKoAction,
  ScholarshipGetMetadataAction,
  ScholarshipGetMetadataKoAction,
  ScholarshipGetMetadataOkAction,
  ScholarshipGetOkAction,
  ScholarshipMaterialKoAction,
  ScholarshipMetadata,
  ScholarshipRemoveMaterialAction,
  ScholarshipRemoveMaterialOkAction,
  ScholarshipSetApprovalInfoDataAction,
  ScholarshipSetAssessmentInfoDataAction,
  ScholarshipSetCostInfoDataAction,
  ScholarshipSetDataAction,
  ScholarshipSetDataFollowAction,
  ScholarshipSetEconomicalInfoDataAction,
  ScholarshipSetEducationalInfoDataAction,
  ScholarshipSetInterviewInfoDataAction,
  ScholarshipSetMotivationalInfoDataAction,
  ScholarshipSetPersonalInfoDataAction,
  ScholarshipSetSituationInfoDataAction,
  ScholarshipSubmitInformationAction,
  ScholarshipSubmitInformationKoAction,
  ScholarshipSubmitInformationOkAction,
  ScholarshipType,
  ScholarshipUpdateMaterialAction,
  ScholarshipUpdateMaterialOkAction,
  ScholarshipValidationErrorsAction,
  SendConfigurationFollowupAction,
  SendConfigurationFollowupKoAction,
  SendConfigurationFollowupOkAction,
  SetEntityPaymentDateFilterAction,
  SetScholarshipStepAction,
  SwitchBlockPaymentAction,
  SwitchBlockPaymentKoAction,
  SwitchBlockPaymentOkAction,
  UploadDocumentAction,
  UploadDocumentKoAction,
  UploadDocumentOkAction,
  UploadDocumentTrackingAction,
} from './definitions';

export function scholarshipGet(id: number): ScholarshipGetAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_GET,
    id,
  };
}

export function scholarshipGetOk(
  scholarshipData: ScholarshipData,
  metadata: ScholarshipMetadata | null,
): ScholarshipGetOkAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_GET_OK,
    scholarshipData,
    metadata,
  };
}

export function scholarshipGetKo(error: string): ScholarshipGetKoAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_GET_KO,
    error,
  };
}

export function scholarshipValidationErrors(validationErrors: APIValidationError): ScholarshipValidationErrorsAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_VALIDATION_ERRORS,
    validationErrors,
  };
}

export function scholarshipGetMetadata(): ScholarshipGetMetadataAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_GET_METADATA,
  };
}

export function scholarshipGetMetadataOk(data: ScholarshipMetadata): ScholarshipGetMetadataOkAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_GET_METADATA_OK,
    scholarshipMetadata: data,
  };
}

export function scholarshipGetMetadataKo(error: string): ScholarshipGetMetadataKoAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_GET_METADATA_KO,
    error,
  };
}

export function scholarshipSubmitInformation(data: ScholarshipData): ScholarshipSubmitInformationAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_SUBMIT_INFORMATION,
    data,
  };
}

export function scholarshipSubmitInformationOk(scholarshipData: ScholarshipData): ScholarshipSubmitInformationOkAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_SUBMIT_INFORMATION_OK,
    scholarshipData,
  };
}

export function scholarshipSubmitInformationKo(error: string): ScholarshipSubmitInformationKoAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_SUBMIT_INFORMATION_KO,
    error,
  };
}

export function uploadDocument(
  documentation_id: number,
  data: File,
  scholarshipId: number,
  multiDocument?: boolean,
): UploadDocumentAction {
  return {
    type: ScholarshipDataConsts.UPLOAD_SCHOLARSHIP_DOCUMENT,
    documentation_id,
    data,
    scholarshipId,
    multiDocument,
  };
}

export function uploadDocumentOk({ documents }: { documents: DocumentationItem[] }): UploadDocumentOkAction {
  return {
    type: ScholarshipDataConsts.UPLOAD_SCHOLARSHIP_DOCUMENT_OK,
    documents,
  };
}

export function uploadDocumentKo(error: string): UploadDocumentKoAction {
  return {
    type: ScholarshipDataConsts.UPLOAD_SCHOLARSHIP_DOCUMENT_KO,
    error,
  };
}

export function getScholarshipDocumentation(id: number): ScholarshipGetDocumentationAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_GET_DOCUMENTATION,
    id,
  };
}

export function getScholarshipDocumentationOk(documentation: DocumentationItem[]): ScholarshipGetDocumentationOkAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_GET_DOCUMENTATION_OK,
    documentation,
  };
}

export function getScholarshipDocumentationKo(error: string): ScholarshipGetDocumentationKoAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_GET_DOCUMENTATION_KO,
    error,
  };
}

export function downloadDocument(
  file_id: number,
  name: string,
  scholarshipId: number,
): ScholarshipDownloadDocumentAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_DOWNLOAD_DOCUMENT,
    file_id,
    name,
    scholarshipId,
  };
}

export function downloadDocumentOk(data: Blob, name: string): ScholarshipDownloadDocumentOkAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_DOWNLOAD_DOCUMENT_OK,
    data,
    name,
  };
}

export function downloadDocumentKo(error: string): ScholarshipDownloadDocumentKoAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_DOWNLOAD_DOCUMENT_KO,
    error,
  };
}

export function getDocument(file_id: number, name: string, scholarshipId: number): GetDocumentScholarshipAction {
  return {
    type: ScholarshipDataConsts.GET_SCHOLARSHIP_DOCUMENT,
    file_id,
    scholarshipId,
    name,
  };
}

export function getDocumentOk(dataDoc: Blob, name: string): GetDocumentScholarshipOkAction {
  return {
    type: ScholarshipDataConsts.GET_SCHOLARSHIP_DOCUMENT_OK,
    dataDoc,
    name,
  };
}

export function getDocumentKo(error: string): GetDocumentScholarshipKoAction {
  return {
    type: ScholarshipDataConsts.GET_SCHOLARSHIP_DOCUMENT_KO,
    error,
  };
}

export function removeDocument(file_id: number, scholarshipId: number): RemoveDocumentScholarshipAction {
  return {
    type: ScholarshipDataConsts.REMOVE_SCHOLARSHIP_DOCUMENT,
    file_id,
    scholarshipId,
  };
}

export function removeDocumentOk({ documents }: { documents: DocumentationItem[] }): RemoveDocumentScholarshipOkAction {
  return {
    type: ScholarshipDataConsts.REMOVE_SCHOLARSHIP_DOCUMENT_OK,
    documents,
  };
}

export function removeDocumentKo(error: string): RemoveDocumentScholarshipKoAction {
  return {
    type: ScholarshipDataConsts.REMOVE_SCHOLARSHIP_DOCUMENT_KO,
    error,
  };
}

export function cleanScholarshipData(): CleanScholarshipDataAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_CLEAN_DATA,
  };
}

export function scholarshipSetData(key: string, value: string | number | null): ScholarshipSetDataAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_SET_DATA,
    key,
    value,
  };
}

export function scholarshipSetPersonalInfoData(
  key: string,
  value: string | number,
): ScholarshipSetPersonalInfoDataAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_SET_DATA_PERSONAL_INFO,
    key,
    value,
  };
}

export function scholarshipSetMotivationalInfoData(
  key: string,
  value: string | number | null,
): ScholarshipSetMotivationalInfoDataAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_SET_DATA_MOTIVATIONAL_INFO,
    key,
    value,
  };
}

export function scholarshipSetSituationInfoData(
  key: string,
  value: string | number | null,
): ScholarshipSetSituationInfoDataAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_SET_DATA_SITUATION_INFO,
    key,
    value,
  };
}

export function scholarshipSetEducationalInfoData(
  key: string,
  value: string | number | null,
): ScholarshipSetEducationalInfoDataAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_SET_DATA_EDUCATIONAL_INFO,
    key,
    value,
  };
}

export function scholarshipSetEconomicalInfoData(
  key: string,
  value: string | number | null,
): ScholarshipSetEconomicalInfoDataAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_SET_DATA_ECONOMICAL_INFO,
    key,
    value,
  };
}

export function scholarshipSetCostInfoData(
  key: string,
  value: string | number | null,
): ScholarshipSetCostInfoDataAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_SET_DATA_COST_INFO,
    key,
    value,
  };
}

export function scholarshipSetInterviewInfoData(
  key: string,
  value: string | number | null,
): ScholarshipSetInterviewInfoDataAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_SET_DATA_INTERVIEW_INFO,
    key,
    value,
  };
}

export function scholarshipSetAssessmentInfoData(
  key: string,
  value: string | number | null,
): ScholarshipSetAssessmentInfoDataAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_SET_DATA_ASSESSMENT_INFO,
    key,
    value,
  };
}

export function scholarshipSetApprovalInfoData(
  key: string,
  value: string | number | null,
): ScholarshipSetApprovalInfoDataAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_SET_DATA_APPROVAL_INFO,
    key,
    value,
  };
}

export function scholarshipSetDataFollowAction(
  key: FollowUpsType,
  subKey: string,
  value: string | number | null,
): ScholarshipSetDataFollowAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_SET_DATA_FOLLOW,
    key,
    value,
    subKey,
  };
}

// SEND CONFIGURATION FOLLOWUP

export function sendConfigurationFollowup(data: any): SendConfigurationFollowupAction {
  return {
    type: ScholarshipDataConsts.SEND_CONFIGURATION_FOLLOWUP,
    data,
  };
}

export function sendConfigurationFollowupOk(): SendConfigurationFollowupOkAction {
  return {
    type: ScholarshipDataConsts.SEND_CONFIGURATION_FOLLOWUP_OK,
  };
}

export function sendConfigurationFollowupKo(error: string): SendConfigurationFollowupKoAction {
  return {
    type: ScholarshipDataConsts.SEND_CONFIGURATION_FOLLOWUP_KO,
    error,
  };
}

// RUN MANUALLY CONFIGURATION FOLLOWUP

export function runConfigurationFollowup(): RunConfigurationFollowupAction {
  return {
    type: ScholarshipDataConsts.RUN_CONFIGURATION_FOLLOWUP,
  };
}

export function runConfigurationFollowupOk(): RunConfigurationFollowupOkAction {
  return {
    type: ScholarshipDataConsts.RUN_CONFIGURATION_FOLLOWUP_OK,
  };
}

export function runConfigurationFollowupKo(error: string): RunConfigurationFollowupKoAction {
  return {
    type: ScholarshipDataConsts.RUN_CONFIGURATION_FOLLOWUP_KO,
    error,
  };
}

// GET CONFIGURATION FOLLOWUP

export function getConfigurationFollowup(): GetConfigurationFollowupAction {
  return {
    type: ScholarshipDataConsts.GET_CONFIGURATION_FOLLOWUP,
  };
}

export function getConfigurationFollowupOk({ data }: { data: any }): GetConfigurationFollowupOkAction {
  return {
    type: ScholarshipDataConsts.GET_CONFIGURATION_FOLLOWUP_OK,
    configurationFollowupData: data,
  };
}

export function getConfigurationFollowupKo(error: string): GetConfigurationFollowupKoAction {
  return {
    type: ScholarshipDataConsts.GET_CONFIGURATION_FOLLOWUP_KO,
    error,
  };
}

// UPDATE FOLLOW UP
export function saveFollowUp(follow_up: ScholarshipFollow): SaveFollowUpAction {
  return {
    type: ScholarshipDataConsts.SAVE_FOLLOW_UP,
    follow_up,
  };
}

// SWITCH BLOCK PAYMENT

export function switchBlockPayment(id: number): SwitchBlockPaymentAction {
  return {
    type: ScholarshipDataConsts.SWITCH_BLOCK_PAYMENT,
    id,
  };
}

export function switchBlockPaymentOk(response: boolean): SwitchBlockPaymentOkAction {
  return {
    type: ScholarshipDataConsts.SWITCH_BLOCK_PAYMENT_OK,
    response,
  };
}

export function switchBlockPaymentKo(error: string): SwitchBlockPaymentKoAction {
  return {
    type: ScholarshipDataConsts.SWITCH_BLOCK_PAYMENT_KO,
    error,
  };
}

// ADD/REMOVE MATERIALS

export function addScholarshipMaterial(material: Material): ScholarshipAddMaterialAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_ADD_MATERIAL,
    material,
  };
}

export function addScholarshipMaterialOk(material: Material): ScholarshipAddMaterialOkAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_ADD_MATERIAL_OK,
    material,
  };
}

export function updateScholarshipMaterial(material: Material): ScholarshipUpdateMaterialAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_UPDATE_MATERIAL,
    material,
  };
}

export function updateScholarshipMaterialOk(material: Material): ScholarshipUpdateMaterialOkAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_UPDATE_MATERIAL_OK,
    material,
  };
}

export function removeScholarshipMaterial(
  scholarship_id: number,
  material_id: number,
): ScholarshipRemoveMaterialAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_REMOVE_MATERIAL,
    scholarship_id,
    material_id,
  };
}

export function removeScholarshipMaterialOk(
  scholarship_id: number,
  material_id: number,
): ScholarshipRemoveMaterialOkAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_REMOVE_MATERIAL_OK,
    scholarship_id,
    material_id,
  };
}

export function scholarshipMaterialKo(error: string): ScholarshipMaterialKoAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_MATERIAL_KO,
    error,
  };
}

export function exportScholarshipsReports(
  source: ExportSourceType,
  scholarship_type: ScholarshipType,
  academic_year: string,
): ExportScholarshipReportsAction {
  return {
    type: ScholarshipDataConsts.EXPORT_SCHOLARSHIP_REPORTS,
    source,
    scholarship_type,
    academic_year,
  };
}

export function exportScholarshipsReportsOk(
  source: ExportSourceType,
  scholarship_type: ScholarshipType,
  data: Blob,
): ExportScholarshipReportsOkAction {
  return {
    type: ScholarshipDataConsts.EXPORT_SCHOLARSHIP_REPORTS_OK,
    source,
    scholarship_type,
    data,
  };
}

export function exportScholarshipsReportsKo(error: string): ExportScholarshipReportsKoAction {
  return {
    type: ScholarshipDataConsts.EXPORT_SCHOLARSHIP_REPORTS_KO,
    error,
  };
}

export function uploadDocumentTracking(
  documentation_id: number,
  data: File,
  scholarshipId: number,
  trackingId: number,
): UploadDocumentTrackingAction {
  return {
    type: ScholarshipDataConsts.UPLOAD_SCHOLARSHIP_DOCUMENT_TRACKING,
    documentation_id,
    data,
    trackingId,
    scholarshipId,
  };
}

export function setEntityPaymentDateFilter(paymentDate: moment.Moment | null): SetEntityPaymentDateFilterAction {
  return {
    type: ScholarshipDataConsts.SET_ENTITY_PAYMENT_DATE_FILTER,
    paymentDate,
  };
}

export function setScholarshipStep(step: number): SetScholarshipStepAction {
  return {
    type: ScholarshipDataConsts.SET_SCHOLARSHIP_STEP,
    step,
  };
}
