import * as yup from 'yup';
import { tErrorKey } from '../helpers/translate';

const requiredText = tErrorKey('Este campo es obligatorio');

export const assessmentInfo = yup.object().shape({
  scholarship_risk_comm: yup.string().required(requiredText),
  scholarship_proposal: yup.string().required(requiredText),
  scholarship_proposal_comm: yup.string().required(requiredText),
  scholarship_contrib: yup.string().required(requiredText),
  scholarship_contrib_comm: yup.string().required(requiredText),
  scholarship_num_nouscims: yup.string().matches(/^[1-5]{1}$/, requiredText),
  scholarship_committee: yup.string().required(requiredText),
  scholarship_tracking: yup.string().required(requiredText),
  scholarship_motivation: yup.string().matches(/^[1-5]{1}$/, requiredText),
  scholarship_motivation_com: yup.string().required(requiredText),
  scholarship_skill: yup.string().matches(/^[1-5]{1}$/, requiredText),
  scholarship_skills_comm: yup.string().required(requiredText),
  scholarship_socialrisc: yup.string().matches(/^[1-5]{1}$/, requiredText),
  scholarship_socialrisc_com: yup.string().required(requiredText),
  scholarship_impact: yup.string().matches(/^[1-5]{1}$/, requiredText),
  scholarship_impact_comm: yup.string().required(requiredText),
  scholarship_eco_sit: yup.string().matches(/^[1-5]{1}$/, requiredText),
  scholarship_eco_sit_comm: yup.string().required(requiredText),
  scholarship_risk: yup.string().matches(/^[1-5]{1}$/, requiredText),
  top_student: yup.string().matches(/^[0-1]{1}$/, requiredText),
  scholarship_type: yup.string().required(requiredText),
  referent_id: yup.number().nullable(),
});
