import { MenuItem } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { Control, FieldErrors, FieldValues } from 'react-hook-form/dist/types';
import { ObjectSchema, Shape } from 'yup';
import TextInputController from '../../../../../components/Inputs/TextInputController/TextInputController';
import RadioButtonGroup from '../../../../../components/RadioButtons/RadioButtonGroup';
import { yesNoTranslatedOptions } from '../../../../../components/RadioButtons/RadioButtonOptions';
import SelectController from '../../../../../components/Select/SelectController';
import { tScholarshipKey } from '../../../../../helpers/translate';
import { IdNamePair } from '../../../../../redux/common/definitions';
import { ScholarshipFollow, ScholarshipMetadata } from '../../../../../redux/scholarship/definitions';
import FollowUpCode from '../../../../../types/Scholarships/followUpCode';
import styles from '../followForm.module.scss';
import FollowUpSatisfaction from '../../../../../types/Scholarships/FollowUpSatisfaction';
import FollowUpValoration from '../../../../../types/Scholarships/FollowUpValoration';

interface AcademicResultSituationViewProps<T extends FieldValues> {
  scholarshipFollow: ScholarshipFollow;
  scholarshipMetadata: ScholarshipMetadata;
  readOnly: boolean;
  errors: FieldErrors<T> | any;
  control: Control<T>;
  scholarshipType: string;
  isEntity: boolean;
  followUpCode: FollowUpCode;
  schema: ObjectSchema<T> | ObjectSchema<Shape<object, any>>;
  register?: ReturnType<typeof useForm>['register'];
  setDataFollow: (subKey: string, value: string | number | null) => void;
}

const AcademicResultSituationView = <T extends FieldValues>({
  scholarshipFollow,
  scholarshipMetadata,
  readOnly,
  control,
  errors,
  scholarshipType,
  isEntity,
  followUpCode,
  schema,
  register,
  setDataFollow,
}: AcademicResultSituationViewProps<T>): ReactElement => {
  const {
    significant_situation_change,
    scholarship_change_reasons_id,
    scholarship_student_meetings_id,
    scholarship_student_meetings_justify,
    support_for_approved,
    scholarship_supports_id,
    scholarship_supports_reason_others,
    has_mentor,
    scholarship_valoration,
  } = scholarshipFollow;
  const { changeReasonsStudent, studentMeets, supports } = scholarshipMetadata;

  const handleSelectChange = (target: any) => {
    if (target.name) {
      setDataFollow(target.name, target.value);
    }
  };

  const renderMentorView = (): JSX.Element => {
    const valorations = isEntity ? FollowUpSatisfaction.toArray() : FollowUpValoration.toArray();
    return (
      <>
        <RadioButtonGroup
          name="has_mentor"
          errors={errors.has_mentor}
          disabled={readOnly}
          questionText={tScholarshipKey(`follow_up.${scholarshipType}.has_mentor`)}
          options={yesNoTranslatedOptions()}
          value={has_mentor?.toString() || '0'}
          register={register}
          onChange={e => {
            setDataFollow('has_mentor', +e.target.value);
          }}
        />
        <SelectController
          control={control}
          name="scholarship_valoration"
          label={tScholarshipKey(`follow_up.${scholarshipType}.scholarship_valoration`)}
          validator={!readOnly && has_mentor === 1}
          defaultValue={scholarship_valoration}
          schema={schema}
          errors={errors}
          disabled={readOnly || !has_mentor}
          onClick={e => handleSelectChange(e.target)}
        >
          {valorations.map((element: string) => (
            <MenuItem key={element} value={element}>
              {tScholarshipKey(`follow_up.valoration.${element}`)}
            </MenuItem>
          ))}
        </SelectController>
      </>
    );
  };

  const renderMeetingJustify = (): JSX.Element | undefined => {
    if (scholarship_student_meetings_id === 5) {
      return (
        <TextInputController
          control={control}
          name="scholarship_student_meetings_justify"
          label={tScholarshipKey('follow_up.scholarship_student_meetings_justify')}
          defaultValue={scholarship_student_meetings_justify}
          size="100"
          schema={schema}
          errors={errors}
          multiline
          rows={3}
          rowsMax={3}
          validator={!readOnly && true}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
            setDataFollow('scholarship_student_meetings_justify', e.target.value)
          }
        />
      );
    }
    return undefined;
  };

  const renderEntityView = (): JSX.Element | undefined => {
    if (isEntity) {
      return (
        <>
          <SelectController
            control={control}
            name="scholarship_student_meetings_id"
            label={tScholarshipKey(
              `follow_up.${followUpCode
                .followUpType()
                .value()
                .toLowerCase()}.scholarship_student_meetings_id`,
            )}
            defaultValue={scholarship_student_meetings_id}
            size="60"
            schema={schema}
            validator
            errors={errors}
            disabled={readOnly}
            onClick={e => handleSelectChange(e.target)}
          >
            {studentMeets.map((element: IdNamePair) => (
              <MenuItem key={element.id} value={element.id}>
                {element.name}
              </MenuItem>
            ))}
          </SelectController>
          {renderMeetingJustify()}
        </>
      );
    }
    return undefined;
  };

  return (
    <>
      <div className={styles.container}>
        <RadioButtonGroup
          name="significant_situation_change"
          errors={errors.significant_situation_change}
          disabled={readOnly}
          questionText={tScholarshipKey(`follow_up.${scholarshipType}.significant_situation_change`)}
          options={yesNoTranslatedOptions()}
          value={significant_situation_change?.toString() || '0'}
          register={register}
          onChange={e => {
            setDataFollow('significant_situation_change', +e.target.value);
          }}
        />
        <SelectController
          control={control}
          name="scholarship_change_reasons_id"
          label={tScholarshipKey('follow_up.scholarship_change_reasons_id')}
          defaultValue={scholarship_change_reasons_id}
          schema={schema}
          errors={errors}
          disabled={readOnly || !significant_situation_change}
          validator={!readOnly && significant_situation_change === 1}
          onClick={e => handleSelectChange(e.target)}
        >
          {changeReasonsStudent.map((element: IdNamePair) => (
            <MenuItem key={element.id} value={element.id}>
              {element.name}
            </MenuItem>
          ))}
        </SelectController>
        {renderMentorView()}
      </div>
      {renderEntityView()}
      <div className={styles.container}>
        <RadioButtonGroup
          name="support_for_approved"
          errors={errors.support_for_approved}
          disabled={readOnly}
          questionText={tScholarshipKey(`follow_up.${scholarshipType}.support_for_approved`)}
          options={yesNoTranslatedOptions()}
          value={support_for_approved?.toString() || '0'}
          register={register}
          onChange={e => {
            setDataFollow('support_for_approved', +e.target.value);
          }}
        />
        <SelectController
          control={control}
          name="scholarship_supports_id"
          label={tScholarshipKey('follow_up.scholarship_supports_id')}
          defaultValue={scholarship_supports_id}
          schema={schema}
          validator={!readOnly && !!support_for_approved}
          errors={errors}
          disabled={readOnly || !support_for_approved}
          onClick={e => handleSelectChange(e.target)}
        >
          {supports.map((element: IdNamePair) => (
            <MenuItem key={element.id} value={element.id}>
              {element.name}
            </MenuItem>
          ))}
        </SelectController>
        {scholarship_supports_id === 4 && (
          <TextInputController
            control={control}
            name="scholarship_supports_reason_others"
            label={tScholarshipKey('follow_up.scholarship_supports_reason_others')}
            defaultValue={scholarship_supports_reason_others}
            size="100"
            validator={!readOnly}
            schema={schema}
            errors={errors}
            multiline
            rows={3}
            rowsMax={3}
            disabled={readOnly}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
              setDataFollow('scholarship_supports_reason_others', e.target.value)
            }
          />
        )}
      </div>
    </>
  );
};

export default AcademicResultSituationView;
