import { Action } from 'redux';
import { APIError, APIValidationError } from '../../api/api';
import { ColumnTableProps } from '../../components/Tables/TableEditableRow/TableEditableRow';
import RoleCode from '../../types/Roles/roleCode';
import { AboutUsResource } from '../aboutUs/definitions';
import { Tracking } from '../area/definitions';
import { DocumentationItem, FileItem, IdNamePair, ResourceImageData } from '../common/definitions';
import { ShowSnackbarAction } from '../FeedbackAPI/definitions';
import {
  CodeSectionProject,
  CustomFieldChildDependency,
  CustomFieldDataTypes,
  CustomFieldOption,
  SizeConfiguratorData,
} from '../kpis/definitions';
import { StepItem } from '../scholarship/definitions';
import {
  AllowedUsersAndCandidates,
  DeleteData,
  DirectoryMetadata,
  FilterData,
  GenericFieldUpload,
  RemoveDocData,
} from './action';
import { ProjectConst } from './action_type';

type Control = 'completed' | 'not finished';
interface Sections {
  original_data: Control;
  project_data: Control;
  fnc_data: Control;
  entities: Control;
  economic_data: Control;
  tracking: Control;
  progress_indicators: Control;
}

export interface NewScheduleProject {
  project_id: number;
  file_id: number | null;
  name: string;
  type: string;
  amount: number | string;
  user_id: number;
  new_tracking_end_date: number | string | null | undefined;
  tracking_payment_date: number | string | null | undefined;
}
export interface PaymentInfo {
  id: number;
  account_number: string;
  account_numbers: string[];
  provider_code: string | null;
  name: string;
  amount: number;
  payment_date: string;
  document_number: string;
  center_cost_area_code: string;
  center_cost_channel_code: string;
  center_cost_delegation_code: string;
  center_cost_project_code: string;
  agreement_number: string;
  description_project: string;
  concept: string;
  payment_field: string | null;
  project_schedule_id: number | null;
  payment_field_id: number | null;
  invoice_id: number | null;
  invoices: { id: number; reference_number: string }[];
  file_id: number | null;
  file_name: string | null;
  payment_info: string | null;
  to_pay_amount: number | null;
}
interface ProjectSteps {
  complete: boolean;
  logica_proyecto: StepItem;
  apectos_clave: StepItem;
  validaciones: StepItem;
  kpis: StepItem;
  kpis_gf: StepItem;
  new_kpis: StepItem;
  firma_seguimiento: StepItem;
  informe_seguimiento: StepItem;
  evaluacion_proyecto: StepItem;
  evaluacion_personal: StepItem;
  datos_basicos: StepItem;
  asistente_convenios: StepItem;
  calendario: StepItem;
  firma_convenio: StepItem;
  ficha_personal: StepItem;
  ficha_seguimiento: StepItem;
  seguimiento_pago: StepItem;
}

export interface ProjectListItem {
  id: number;
  name: string;
  description: string;
  entity_name: string;
  subarea: string;
  state_name: string;
  state_code: string;
  lab_project: string;
  forms_done: number;
}

export interface ProjectListData {
  projects: ProjectListItem;
  resume: {
    states: LookUpState;
    subareas: LookUpSubarea;
  };
}

export interface ProjectData {
  id: number;
  name: string;
  description: string;
  entity_id: string;
  subarea_id: string;
  untracked_project: boolean;
  state_id: number;
  state_code: StateProject;
  area?: string;
  subarea?: string;
  entity_name?: string;
  trackings: number;
  state: string;
  entity_owner_id: number;
  area_code: string;
  state_name: string;
  documents: DocumentationItem[];
  scheduled_appointments: ScheduledAppointmentItem[];
  owner_id?: number;
  owner_rol: string;
  owner_name: string;
  allowed_users: {
    allowed_user_id: number;
    allowed_user_name: string;
  }[];
  amount: number;
  requires_follow_up: number | string;
  payment_settings: PaymentSettingsData;
  agreement_number: string | number;
  forms_done: number;
  follow_period: string | null;
  vulnerability_situation: string;
  structural_reasons: string;
  place: string;
  roles_map: string;
  responsibles_projects: IdNamePair[];
  active_schedule_item_id: number | null;
  steps: ProjectSteps;
  can_pay: boolean;
  loadingImages: boolean;
  images: ResourceImageData[];
}

export enum ScheduledAppointmentState {
  KICK_OFF = 'kickoff',
  CHECK = 'check',
  FNC_CHECK = 'phone_check',
  PAYMENT = 'payment',
  FINISH = 'finnish',
  BASE_LINE = 'baseline',
}

export enum SchedulePeriods {
  NONE = '0',
  QUARTERLY = '3',
  BIANNUAL = '6',
  ANNUAL = '12',
}
export interface ScheduledAppointmentItem {
  id: number;
  project_id: number;
  tracking_date: string | null;
  state: ScheduledAppointmentState;
  has_payment: boolean | number;
  partial_amount: number;
  complete: boolean | number;
  action_resum: string | null;
  period: string;
  tracking_message: string | null;
  project_schedule_id: number | null;
  file_id: number | null;
  filename: string | null;
  kpis_id: number | null;
  payment_active: boolean | null;
  document_id: number | null;
}

export interface OriginalData {
  name: string;
  description: string;
  documents: DocumentationItem[];
}

export interface ProjectDescription {
  name: string;
  description: string;
  country: string;
  zone: string;
  region: string;
}
export interface OwnersCandidatesResponse {
  candidates: IdNamePair[];
  owners: IdNamePair[];
}

export interface FNCData {
  area_name: string;
  sub_area_name: string;
  id_responsible: number;
  id_members: number[];
  id_member: number;
}

export interface EconomicData {
  total_cost: number;
  grant_request: number;
  grant_request_percent: number;
}
export interface AreaSubArea {
  id: number;
  name: string;
}

export type Frequency = 'anual' | 'semestral' | 'trimestral' | 'monthly';

export interface Section {
  datos_basicos: string;
  logica_proyecto: string;
  aspectos_clave: string;
  validaciones: string;
  kpis: string;
  asistente_convenios: string;
  calendario: string;
  firma_convenio: string;
  ficha_persona: string;
  ficha_seguimiento: string;
  informe_seguimiento: string;
  seguimiento_pago: string;
  eval_proyecto: string;
  eval_personal: string;
}
export type ProjectProp = ProjectDescription | EntityProject | FNCData | EconomicData | Tracking;

export interface EntityProject {
  name_project: string;
  name_entity: string;
  id_entity: number;
  entities: number[];
}
export interface Project {
  section: Sections;
  id: number;
  original_data: OriginalData;
  project_data: ProjectDescription;
  entity_project: EntityProject;
  fnc_data: FNCData;
  economic_data: EconomicData;
  tracking: Tracking;
}

export interface FollowUp {
  project_id?: number;
  created_at: number;
  full_name?: string;
  documentsIDs?: number[];
  documents: DocumentationItem[];
  user_id?: number;
  id?: number;
  title: string;
  description: string;
  trackings?: number;
}

export interface DocumentsFollowup {
  id: number;
  name: string;
}

export interface PaymentSettingsData {
  center_cost_channel_code: string | null;
  center_cost_delegation_code: string | null;
  center_cost_area_code: string | null;
  center_cost_project_code: string | null;

  agreement_number: string | null;
  description: string | null;
  concept: string | null;
}
export interface ConfiguratorData {
  id: number;
  code: string;
  description: string;
  field_data_types: CustomFieldDataTypes;
  mandatory: boolean;
  custom_field_type: string;
  options: CustomFieldOption[];
  kpi_indicator_type_id: number | null;
  kpi_indicator_type: string | null;
  position: number;
  size: SizeConfiguratorData;
  selected: boolean;
  project_default: number;
  radio_vertically: number;
  radio_title_left_position: number;
  radio_value_label_position: string;
  read_only: boolean;
  origin_custom_field_id: number | null;
  origin_condition: string | null;
  origin_value: string | null;
  child_dependencies: CustomFieldChildDependency[];
}

export type FormProjectFields = Omit<ConfiguratorData, 'code' | 'description'> & {
  label: string;
  name: string;
  value: string | null;
  value_file: DocumentationItem[];
};

export type ProjectChangeStateOk =
  | 'p02p04'
  | 'p04p05'
  | 'p05p07'
  | 'p07p08'
  | 'p07p09'
  | 'p08p09'
  | 'p09p10'
  | 'p10p21'
  | 'p13p14'
  | 'p14p22'
  | 'p15p22'
  | 'p17p18'
  | 'p18p19'
  | 'p19p20'
  | 'p23p22';

export type ProjectChangeStateKo =
  | 'p05p04'
  | 'p08p07'
  | 'p09p07'
  | 'p10p09'
  | 'p14p13'
  | 'p15p14'
  | 'p18p17'
  | 'p19p18'
  | 'error';

export enum StateProject {
  ID_2 = 'P02',
  EVALUATION_1 = 'P04',
  EVALUATION_2 = 'P05',
  AGREEMENT = 'P07',
  AGREEMENT_REVIEW = 'P08',
  AGREEMENT_SIGNING = 'P09',
  SIGNATURE_PENDING = 'P10',
  TRACKING = 'P13',
  TRACKING_VALIDATION = 'P14',
  PAYMENT = 'P15',
  FOLLOWUP_EVALUATION_2 = 'P17',
  FOLLOWUP_EVALUATION_3 = 'P18',
  FOLLOWUP_EVALUATION_4 = 'P19',
  END = 'P20',
  KICK_OFF = 'P21',
  TRACKING_FINISHED = 'P22',
  ARCHIVED = 'P99',
  TRACKING_FNC = 'P23',
}

export const lookUpSubArea = {
  Salud: 'Salud',
  Oncología: 'Oncología',
  Vocación: 'Vocación',
  Red: 'Red',
  'Activación de talento': 'Activación de talento',
  Formación: 'Formación',
  Acompañamiento: 'Acompañamiento',
  'Desarrollo económico': 'Desarrollo económico',
  Educación: 'Educación',
  Pobreza: 'Pobreza',
  Komtu: 'Komtu',
};

export const lookUpState = {
  'En creación': 'En creación',
  Definicion: 'Definición',
  Identificacion: 'Identificación',
  'Evaluación I': 'Evaluación I',
  'Evaluación II': 'Evaluación II',
  Convenio: 'Convenio',
  'Revisión convenio': 'Revisión convenio',
  'Firma convenio': 'Firma convenio',
  'Revisión y Firma': 'Revisión y Firma',
  'Solicitud fichas personal': 'Solicitud fichas personal',
  'Validación fichas personal': 'Validación fichas personal',
  Seguimiento: 'Seguimiento',
  'Validar seguimiento': 'Validar seguimiento',
  Pago: 'Pago',
  'Seguimiento final y Evaluación I': 'Seguimiento final y Evaluación I',
  'Seguimiento final y Evaluación II': 'Seguimiento final y Evaluación II',
  'Seguimiento final y Evaluación III': 'Seguimiento final y Evaluación III',
  'Seguimiento final y Evaluación IV': 'Seguimiento final y Evaluación IV',
  Finalizado: 'Finalizado',
  Archivado: 'Archivado',
};

export interface GenericData {
  columns: ColumnTableProps[];
  rows: any[];
  dataType: 'table';
}

export interface CustomFieldsForm {
  fields: {
    documents: FormProjectFields[];
    elements: FormProjectFields[];
  };
  participante: GenericData;
  steps: ProjectSteps;
}

export type LookUpState = typeof lookUpState;
export type LookUpSubarea = typeof lookUpSubArea;

export const initialState = {
  loading: false as boolean,
  loadingSchedules: false as boolean,
  error: null as string | null,
  projectsList: [] as ProjectListItem[],
  projectData: {} as ProjectData,
  newScheduleData: {} as NewScheduleProject,
  validationErrors: null as APIValidationError | null,
  documentation: [] as DocumentationItem[],
  documents: [] as DocumentationItem[],
  oneResource: {} as AboutUsResource,
  areasSubareasList: [] as AreaSubArea[],
  documentsArr: [] as DocumentsFollowup[],
  configuratorData: [] as ConfiguratorData[],
  configuratorNotSelectedData: [] as ConfiguratorData[],
  configuratorSelectedData: [] as ConfiguratorData[],
  followUp: {} as FollowUp,
  tableDocsData: {} as DocumentationItem[],
  createView: false as boolean,
  editDeleteView: false as boolean,
  followUpsList: [] as FollowUp[],
  metadata: {} as any,
  loadingFollowUp: false as boolean,
  loadingListFollowUp: false as boolean,
  loadingDelete: false as boolean,
  loadingFields: false as boolean,
  directoryMetadata: {} as DirectoryMetadata,
  actorsDefault: [] as IdNamePair[],
  actorsFiltered: [] as IdNamePair[],
  resume: {} as any,
  states: {} as LookUpState,
  subareas: {} as LookUpSubarea,
  usersDataFiltered: [] as UserFilter[],
  fieldsData: {} as CustomFieldsForm,
  currentProjectScheduleItem: {} as ScheduledAppointmentItem,
  areasTemplates: [] as Tracking[],
  documentsAnnexes: [] as DocumentationItem[],
  paymentData: {} as PaymentInfo,
};

export interface UserFilter {
  id: number;
  email: string;
  name: string;
  avatar: string;
}

export interface UsersFilter {
  [index: number]: {
    id: number;
    email: string;
    name: string;
    avatar: string;
  };
}

export type AnnexesFileProject = Pick<NewScheduleProject, 'name' | 'project_id' | 'type'> & { file: File };

export const filterTrackingPhase = {};

export type ProjectState = typeof initialState;

//GET ALL PROJECTS
export interface GetProjectsAction extends Action {
  type: ProjectConst.GET_PROJECTS;
}

export interface GetProjectsOkAction extends Action {
  type: ProjectConst.GET_PROJECTS_OK;
  projectsList: ProjectListItem[];
  states: LookUpState;
  subareas: LookUpSubarea;
}

export interface GetProjectsKoAction extends Action {
  type: ProjectConst.GET_PROJECTS_KO;
  error: string;
}

//GET PROJECTS BY ID
export interface GetOneProjectAction extends Action {
  type: ProjectConst.GET_ONE_PROJECT;
  id: string;
}

export interface GetOneProjectOkAction extends Action {
  type: ProjectConst.GET_ONE_PROJECT_OK;
  projectData: ProjectData;
}

export interface GetOneProjectKoAction extends Action {
  type: ProjectConst.GET_ONE_PROJECT_KO;
  error: string;
}

//CREATE PROJECT
export interface CreateProjectAction extends Action {
  type: ProjectConst.CREATE_PROJECT;
  projectData: ProjectData;
}

export interface CreateProjectOkAction extends Action {
  type: ProjectConst.CREATE_PROJECT_OK;
  projectData: ProjectData;
}

export interface CreateProjectKoAction extends Action {
  type: ProjectConst.CREATE_PROJECT_KO;
  error: string;
}

//EDIT PROJECT
export interface EditProjectAction extends Action {
  type: ProjectConst.EDIT_PROJECT;
  projectData: ProjectData;
  idProject: number;
}

export interface EditProjectOkAction extends Action {
  type: ProjectConst.EDIT_PROJECT_OK;
  projectData: ProjectData;
}

export interface EditProjectKoAction extends Action {
  type: ProjectConst.EDIT_PROJECT_KO;
  error: string;
  projectData: ProjectData;
}

//DELETE PROJECT
export interface DeleteProjectAction extends Action {
  type: ProjectConst.DELETE_PROJECT;
  id: number;
}

export interface DeleteProjectOkAction extends Action {
  type: ProjectConst.DELETE_PROJECT_OK;
  id: number;
}

export interface DeleteProjectKoAction extends Action {
  type: ProjectConst.DELETE_PROJECT_KO;
  error: string;
}

// VALIDATIONS ERRORS
export interface ProjectValidationErrorsAction extends Action {
  type: ProjectConst.PROJECT_VALIDATION_ERRORS;
  validationErrors: APIValidationError;
}

// GET AREAS SUBAREAS

export interface GetAreasSubAreasAction extends Action {
  type: ProjectConst.GET_AREAS_SUBAREAS;
}
export interface GetAreasSubAreasOkAction extends Action {
  type: ProjectConst.GET_AREAS_SUBAREAS_OK;
  areasSubareasList: AreaSubArea[];
}
export interface GetAreasSubAreasKoAction extends Action {
  type: ProjectConst.GET_AREAS_SUBAREAS_KO;
  error: string;
}

// ADD DOCUMENT

export interface AddDocumentAction extends Action {
  type: ProjectConst.ADD_DOCUMENT;
  file: File;
  description: string;
  idProject: number;
}
export interface AddDocumentOkAction extends Action {
  type: ProjectConst.ADD_DOCUMENT_OK;
  documentation: DocumentationItem[];
}
export interface AddDocumentKoAction extends Action {
  type: ProjectConst.ADD_DOCUMENT_KO;
  error: string;
}

// GET DOCUMENTATION

export interface GetDocumentationAction extends Action {
  type: ProjectConst.GET_DOCUMENTATION;
  id: number;
}
export interface GetDocumentationOkAction extends Action {
  type: ProjectConst.GET_DOCUMENTATION_OK;
  documentation: DocumentationItem[];
}
export interface GetDocumentationKoAction extends Action {
  type: ProjectConst.GET_DOCUMENTATION_KO;
  error: string;
}

// DOWNLOAD FILE

export interface DownloadFileAction extends Action {
  type: ProjectConst.DOWNLOAD_DOCUMENT;
  id: number;
  file_id: number;
  name: string;
}
export interface DownloadFileOkAction extends Action {
  type: ProjectConst.DOWNLOAD_DOCUMENT_OK;
  data: Blob;
  name: string;
}
export interface DownloadFileKoAction extends Action {
  type: ProjectConst.DOWNLOAD_DOCUMENT_KO;
  error: string;
}

// GET FILE

export interface GetFileAction extends Action {
  type: ProjectConst.GET_DOCUMENT;
  id: number;
  name: string;
  file_id: number;
}
export interface GetFileOkAction extends Action {
  type: ProjectConst.GET_DOCUMENT_OK;
  data: Blob;
}
export interface GetFileKoAction extends Action {
  type: ProjectConst.GET_DOCUMENT_KO;
  error: string;
}

// DELETE FILE

export interface DeleteFileAction extends Action {
  type: ProjectConst.DELETE_DOCUMENT;
  file_id: number;
  id: number;
}
export interface DeleteFileOkAction extends Action {
  type: ProjectConst.DELETE_DOCUMENT_OK;
  documentation: DocumentationItem[];
}
export interface DeleteFileKoAction extends Action {
  type: ProjectConst.DELETE_DOCUMENT_KO;
  error: string;
}

// CHANGE STATE
export interface ProjectChangeStateAction extends Action {
  type: ProjectConst.PROJECT_CHANGE_STATE;
  id: number;
  states: ProjectChangeStateOk | ProjectChangeStateKo;
  comments?: string;
}

export interface ProjectChangeStateOkAction extends Action {
  type: ProjectConst.PROJECT_CHANGE_STATE_OK;
  state_code: StateProject;
  state_name: string;
}

export interface ProjectChangeStateKoAction extends Action {
  type: ProjectConst.PROJECT_CHANGE_STATE_KO;
  error: string;
}

export interface GetFollowUpAction extends Action {
  type: ProjectConst.GET_FOLLOWUP;
  id: number;
}

export interface GetFollowUpOkAction extends Action {
  type: ProjectConst.GET_FOLLOWUP_OK;
  followUp: FollowUp;
}

export interface GetFollowUpKoAction extends Action {
  type: ProjectConst.GET_FOLLOWUP_KO;
  error: string;
}

export interface AddFollowUpAction extends Action {
  type: ProjectConst.ADD_FOLLOWUP;
  followUpData: FollowUp;
}

export interface AddFollowUpOkAction extends Action {
  type: ProjectConst.ADD_FOLLOWUP_OK;
  followUp: FollowUp;
}

export interface AddFollowUpKoAction extends Action {
  type: ProjectConst.ADD_FOLLOWUP_KO;
  error: string;
}

export interface GetListFollowUpsAction extends Action {
  type: ProjectConst.GET_LIST_FOLLOWUPS;
  projectId: number;
}

export interface GetListFollowUpsOkAction extends Action {
  type: ProjectConst.GET_LIST_FOLLOWUPS_OK;
  followUpsList: FollowUp[];
}

export interface GetListFollowUpsKoAction extends Action {
  type: ProjectConst.GET_LIST_FOLLOWUPS_KO;
  error: string;
}

export interface ChangeCreateModalVisible extends Action {
  type: ProjectConst.CHANGE_CREATE_MODAL_VISIBLE;
  createView: boolean;
}

export interface ChangeEditDeleteModalVisible extends Action {
  type: ProjectConst.CHANGE_EDITDELETE_MODAL_VISIBLE;
  editDeleteView: boolean;
}
export interface EditFollowUpAction extends Action {
  type: ProjectConst.EDIT_FOLLOWUP;
  followUpData: FollowUp;
  id: number;
}

export interface EditFollowUpOkAction extends Action {
  type: ProjectConst.EDIT_FOLLOWUP_OK;
  followUp: FollowUp;
}

export interface EditFollowUpKoAction extends Action {
  type: ProjectConst.EDIT_FOLLOWUP_KO;
  error: string;
}

export interface DeleteFollowUpAction extends Action {
  type: ProjectConst.DELETE_FOLLOWUP;
  id: number;
}

export interface DeleteFollowUpOkAction extends Action {
  type: ProjectConst.DELETE_FOLLOWUP_OK;
  id: number;
}

export interface DeleteFollowUpKoAction extends Action {
  type: ProjectConst.DELETE_FOLLOWUP_KO;
  error: string;
}

export interface UploadDocumentationAction extends Action {
  type: ProjectConst.UPLOAD_FOLLOWUP_DOCUMENT;
  file: File;
  name: string;
  actor: string;
}

export interface UploadDocumentationOkAction extends Action {
  type: ProjectConst.UPLOAD_FOLLOWUP_DOCUMENT_OK;
  data: FileItem;
  name: string;
}

export interface UploadDocumentationKoAction extends Action {
  type: ProjectConst.UPLOAD_FOLLOWUP_DOCUMENT_KO;
  error: APIError;
}

export interface ResetDocumentsAction extends Action {
  type: ProjectConst.RESET_DOCUMENTS;
}

export interface DownloadDocumentFollowupAction extends Action {
  type: ProjectConst.DOWNLOAD_FOLLOWUP_DOCUMENT;
  project_id: number;
  file_id: number;
  name: string;
  typeTable: boolean;
}

export interface DownloadDocumentFollowupOkAction extends Action {
  type: ProjectConst.DOWNLOAD_FOLLOWUP_DOCUMENT_OK;
  data: Blob;
  name: string;
}

export interface DownloadDocumentFollowupKoAction extends Action {
  type: ProjectConst.DOWNLOAD_FOLLOWUP_DOCUMENT_KO;
  error: string;
}

export interface RemoveFollowupDocumentAction extends Action {
  type: ProjectConst.DELETE_FOLLOWUP_DOCUMENT;
  file_id: number;
  project_id: number;
}
export interface RemoveFollowupDocumentOkAction extends Action {
  type: ProjectConst.DELETE_FOLLOWUP_DOCUMENT_OK;
  file_id: number;
}
export interface RemoveFollowupDocumentKoAction extends Action {
  type: ProjectConst.DELETE_FOLLOWUP_DOCUMENT_KO;
  error: string;
}

export interface UploadAgreementAction extends Action {
  type: ProjectConst.UPLOAD_AGREEMENT;
  documentation_id: number;
  data: File;
  project_id: number;
}

export interface UploadAgreementOkAction extends Action {
  type: ProjectConst.UPLOAD_AGREEMENT_OK;
  documentation: DocumentationItem[];
}

export interface UploadAgreementKoAction extends Action {
  type: ProjectConst.UPLOAD_AGREEMENT_KO;
  error: string;
}

export interface DeleteAgreementAction extends Action {
  type: ProjectConst.DELETE_AGREEMENT;
  documentation_id: number;
  project_id: number;
}

export interface DeleteAgreementOkAction extends Action {
  type: ProjectConst.DELETE_AGREEMENT_OK;
  documentation: DocumentationItem[];
}

export interface DeleteAgreementKoAction extends Action {
  type: ProjectConst.DELETE_AGREEMENT_KO;
  error: string;
}

export interface ConfirmConfiguratorAction extends Action {
  type: ProjectConst.CONFIRM_CONFIGURATOR;
  id_project: number;
  section: string;
  data: ConfiguratorData[];
}

export interface ConfirmConfiguratorOkAction extends Action {
  type: ProjectConst.CONFIRM_CONFIGURATOR_OK;
}

export interface ConfirmConfiguratorKoAction extends Action {
  type: ProjectConst.CONFIRM_CONFIGURATOR_KO;
  error: string;
}

export interface ResetConfiguratorDataAction extends Action {
  type: ProjectConst.RESET_CONFIGURATOR_DATA;
}

export interface SaveCustomFieldsFormAction extends Action {
  type: ProjectConst.SAVE_CUSTOM_FIELDS_FORM;
  project_id: number;
  project_schedule_id: number;
  section: CodeSectionProject;
  fields: Record<string, string>;
}

export interface AddNewGenericTableRowAction extends Action {
  type: ProjectConst.ADD_NEW_GENERIC_TABLE_ROW;
  setterData: any;
}

export interface SetterGenericTableRowAction extends Action {
  type: ProjectConst.SET_SETTER_GENERIC_TABLE_ROW;
  setterData: any;
  finalStep: boolean;
}

export interface AssignDocsToRowAction extends Action {
  type: ProjectConst.ASSIGN_DOCS_TO_ROW;
  tableDocsData: FollowUp;
}

export interface AssignDocsToRowOkAction extends Action {
  type: ProjectConst.ASSIGN_DOCS_TO_ROW_OK;
  tableDocs: DocumentationItem[];
  tableDocsData: any;
}

export interface AssignDocsToRowKoAction extends Action {
  type: ProjectConst.ASSIGN_DOCS_TO_ROW_KO;
  error: string;
}

export interface RemoveDocsTableAction extends Action {
  type: ProjectConst.DELETE_DOCS_ROW;
  tableDocsData: any;
}

export interface RemoveDocsTableOkAction extends Action {
  type: ProjectConst.DELETE_DOCS_ROW_OK;
  tableDocs: DocumentationItem[];
  tableDocsData: any;
}

export interface RemoveDocsTableKoAction extends Action {
  type: ProjectConst.DELETE_DOCS_ROW_KO;
  error: string;
}

export interface DeleteRowTableAction extends Action {
  type: ProjectConst.DELETE_ROW_TABLE;
  deleteData: DeleteData;
}

export interface DeleteRowTableOkAction extends Action {
  type: ProjectConst.DELETE_ROW_TABLE_OK;
  responseData: GenericData;
  generatedId?: number;
  idStandard?: number;
}

export interface DeleteRowTableKoAction extends Action {
  type: ProjectConst.DELETE_ROW_TABLE_KO;
  error: string;
}

export interface GenericFieldUploadAction extends Action {
  type: ProjectConst.GENERIC_FORM_UPLOAD_FIELD;
  uploadData: GenericFieldUpload;
}
export interface GenericFieldUploadOkAction extends Action {
  type: ProjectConst.GENERIC_FORM_UPLOAD_FIELD_OK;
  documentation: DocumentationItem[];
  fieldName: string;
}
export interface GenericFieldUploadKoAction extends Action {
  type: ProjectConst.GENERIC_FORM_UPLOAD_FIELD_KO;
  error: string;
}

export interface DownloadGenericFileAction extends Action {
  type: ProjectConst.GENERIC_FORM_DOWNLOAD_FILE;
  file_id: number;
  name: string;
}

export interface DownloadGenericFileOkAction extends Action {
  type: ProjectConst.GENERIC_FORM_DOWNLOAD_FILE_OK;
  data: Blob;
  name: string;
}

export interface DownloadGenericFileKoAction extends Action {
  type: ProjectConst.GENERIC_FORM_DOWNLOAD_FILE_KO;
  error: string;
}

export interface GetGenericFileAction extends Action {
  type: ProjectConst.GENERIC_FORM_GET_FILE;
  file_id: number;
}
export interface GetGenericFileOkAction extends Action {
  type: ProjectConst.GENERIC_FORM_GET_FILE_OK;
  data: Blob;
}
export interface GetGenericFileKoAction extends Action {
  type: ProjectConst.GENERIC_FORM_GET_FILE_KO;
  error: string;
}

export interface DeleteGenericFileAction extends Action {
  type: ProjectConst.GENERIC_FORM_DELETE_FILE;
  data: RemoveDocData;
}

export interface DeleteGenericFileOkAction extends Action {
  type: ProjectConst.GENERIC_FORM_DELETE_FILE_OK;
  dataF: RemoveDocData;
}

export interface DeleteGenericFileKoAction extends Action {
  type: ProjectConst.GENERIC_FORM_DELETE_FILE_KO;
  error: string;
}

export interface GetGenericFieldsAction extends Action {
  type: ProjectConst.GENERIC_FORM_GET_FIELDS;
  project_id: number;
  project_schedule_id: number;
  section: CodeSectionProject;
}
export interface GetGenericFieldsOkAction extends Action {
  type: ProjectConst.GENERIC_FORM_GET_FIELDS_OK;
  formFields: CustomFieldsForm;
}
export interface GetGenericFieldsKoAction extends Action {
  type: ProjectConst.GENERIC_FORM_GET_FIELDS_KO;
  error: string;
}

export interface GetDirectoryDataFilterAction extends Action {
  type: ProjectConst.GET_DIRECTORY_DATA_FILTER;
  filterData: FilterData | undefined;
}

export interface GetDirectoryDataFilterOkAction extends Action {
  type: ProjectConst.GET_DIRECTORY_DATA_FILTER_OK;
  filterResponse: any;
}

export interface GetDirectoryDataFilterKoAction extends Action {
  type: ProjectConst.GET_DIRECTORY_DATA_FILTER_KO;
  error: string;
}

export interface GetActorsMetadataAction extends Action {
  type: ProjectConst.GET_ACTORS_METADATA;
  project_id: number;
}

export interface GetActorsMetadataOkAction extends Action {
  type: ProjectConst.GET_ACTORS_METADATA_OK;
  projectActors: OwnersCandidatesResponse;
}

export interface SendActorAction extends Action {
  type: ProjectConst.SEND_ACTOR;
  project_id: number;
  user_id: number;
}

export interface SendActorOkAction extends Action {
  type: ProjectConst.SEND_ACTOR_OK;
  allowedUsersAndCandidates: AllowedUsersAndCandidates;
}

export interface DeleteActorAction extends Action {
  type: ProjectConst.DELETE_ACTOR;
  project_id: number;
  user_id: number;
}

export interface DeleteActorOkAction extends Action {
  type: ProjectConst.DELETE_ACTOR_OK;
  allowedUsersAndCandidates: AllowedUsersAndCandidates;
}

export interface SetProjectDataAction extends Action {
  type: ProjectConst.SET_PROJECT_DATA;
  key: string;
  value: string | number;
}

export interface SetProjectScheduleDataAction extends Action {
  type: ProjectConst.SET_PROJECT_SCHEDULE_DATA;
  key: string;
  value: string | number;
}

export interface GetProjectSchedulesAction extends Action {
  type: ProjectConst.GET_PROJECT_SCHEDULES;
  id: number;
}
export interface GetProjectSchedulesOkAction extends Action {
  type: ProjectConst.GET_PROJECT_SCHEDULES_OK;
  schedules: ScheduledAppointmentItem[];
}

export interface CreateProjectScheduleAction extends Action {
  type: ProjectConst.CREATE_PROJECT_SCHEDULE;
  schedule: ScheduledAppointmentItem;
}
export interface UpdateProjectScheduleAction extends Action {
  type: ProjectConst.UPDATE_PROJECT_SCHEDULE;
  schedule: ScheduledAppointmentItem;
}
export interface DeleteProjectScheduleAction extends Action {
  type: ProjectConst.DELETE_PROJECT_SCHEDULE;
  id: number;
  project_id: number;
}

export interface UpdateProjectCalendarAction extends Action {
  type: ProjectConst.UPDATE_PROJECT_CALENDAR;
  project_id: number;
  schedules: ScheduledAppointmentItem[];
}

export interface ProjectGenericKoAction extends Action {
  type: ProjectConst.PROJECT_GENERIC_KO;
  error: string;
}

export interface EditProjectScheduleItemAction extends Action {
  type: ProjectConst.EDIT_PROJECT_SCHEDULE_ITEM;
  id: number;
  code: string;
}
export interface EditProjectScheduleItemOkAction extends Action {
  type: ProjectConst.EDIT_PROJECT_SCHEDULE_ITEM_OK;
  areasTemplates: Tracking[];
}

export interface SelectProjectScheduleItemAction extends Action {
  type: ProjectConst.SELECT_PROJECT_SCHEDULE_ITEM;
  id: number;
}

export interface UploadFollowUpDocumentAction extends Action {
  type: ProjectConst.UPLOAD_FOLLOW_UP_DOCUMENT;
  file: File;
}

export interface UploadFollowUpDocumentOkAction extends Action {
  type: ProjectConst.UPLOAD_FOLLOW_UP_DOCUMENT_OK;
  fileData: FileItem;
}

export interface UploadFollowUpDocumentKoAction extends Action {
  type: ProjectConst.UPLOAD_FOLLOW_UP_DOCUMENT_KO;
  error: string;
}

export interface RemoveFollowUpDocumentAction extends Action {
  type: ProjectConst.REMOVE_FOLLOW_UP_DOCUMENT;
  file_id: number;
}

export interface RemoveFollowUpDocumentOkAction extends Action {
  type: ProjectConst.REMOVE_FOLLOW_UP_DOCUMENT_OK;
}

export interface RemoveFollowUpDocumentKoAction extends Action {
  type: ProjectConst.REMOVE_FOLLOW_UP_DOCUMENT_KO;
  error: string;
}

export interface DownloadFollowUpDocumentAction extends Action {
  type: ProjectConst.DOWNLOAD_FOLLOW_UP;
  file_id: number;
  filename: string;
}

export interface DownloadFollowUpDocumentOkAction extends Action {
  type: ProjectConst.DOWNLOAD_FOLLOW_UP_OK;
  data: Blob;
  name: string;
}

export interface DownloadFollowUpDocumentKoAction extends Action {
  type: ProjectConst.DOWNLOAD_FOLLOW_UP_KO;
  error: string;
}

export interface UploadScheduleDocumentAction extends Action {
  type: ProjectConst.UPLOAD_SCHEDULE_DOCUMENT;
  file: File;
  code: string;
}

export interface UploadScheduleDocumentOkAction extends Action {
  type: ProjectConst.UPLOAD_SCHEDULE_DOCUMENT_OK;
  fileInfo: FileItem;
}

export interface UploadScheduleDocumentKoAction extends Action {
  type: ProjectConst.UPLOAD_SCHEDULE_DOCUMENT_KO;
  error: string;
}

export interface AddNewScheduleAction extends Action {
  type: ProjectConst.ADD_SCHEDULE;
  project_id: number;
  user_id: number;
}

export interface SetScheduleDataAction extends Action {
  type: ProjectConst.SET_SCHEDULE_DATA;
  key: string;
  value: string | number;
}

// CREATE NEW SCHEDULE
export interface CreateNewScheduleProjectAction extends Action {
  type: ProjectConst.CREATE_NEW_SCHEDULE;
  newScheduleData: NewScheduleProject;
}

export interface CreateNewScheduleProjectOkAction extends Action {
  type: ProjectConst.CREATE_NEW_SCHEDULE_OK;
  scheduleData: NewScheduleProject;
}

export interface CreateNewScheduleProjectKoAction extends Action {
  type: ProjectConst.CREATE_NEW_SCHEDULE_KO;
  error: string;
}
export interface ProjectPaymentAction extends Action {
  type: ProjectConst.PROJECT_PAYMENT;
  project_id: number;
  project_schedule_id: number;
  account_number: string;
}

export interface LinkPaymentAction extends Action {
  type: ProjectConst.LINK_PAYMENT;
  project_id: number;
  project_schedule_id: number;
  invoice_id: number;
  invoice_file_id: number;
}

// SET PAYMENT DATA
export interface SetPaymentData extends Action {
  type: ProjectConst.SET_PAYMENT_DATA;
  data: PaymentInfo;
}

// UPLOAD PAYMENT OR INVOICE FILE
export interface UploadPaymentFileAction extends Action {
  type: ProjectConst.UPLOAD_PAYMENT_FILE;
  file: File;
}

export interface UploadPaymentFileOkAction extends Action {
  type: ProjectConst.UPLOAD_PAYMENT_FILE_OK;
  fileData: FileItem;
}

export interface UploadPaymentFileKoAction extends Action {
  type: ProjectConst.UPLOAD_PAYMENT_FILE_KO;
  error: string;
}

// REMOVE PAYMENT OR INVOICE FILE
export interface RemovePaymentFileAction extends Action {
  type: ProjectConst.REMOVE_PAYMENT_FILE;
  fileId: number;
}

export interface RemovePaymentFileOkAction extends Action {
  type: ProjectConst.REMOVE_PAYMENT_FILE_OK;
}

export interface RemovePaymentFileKoAction extends Action {
  type: ProjectConst.REMOVE_PAYMENT_FILE_KO;
  error: string;
}

export interface GetProjectImagesAction extends Action {
  type: ProjectConst.GET_PROJECT_IMAGES;
  projectId: number;
}

export interface GetProjectImagesOkAction extends Action {
  type: ProjectConst.GET_PROJECT_IMAGES_OK;
  projectImages: ResourceImageData[];
}

export interface AddProjectImageAction extends Action {
  type: ProjectConst.ADD_PROJECT_IMAGE;
  projectId: number;
  name: string;
  description: string;
  image: string;
  userId: number;
}

export interface AddProjectImageOkAction extends Action {
  type: ProjectConst.ADD_PROJECT_IMAGE_OK;
  projectImage: ResourceImageData;
}

export interface EditProjectImageAction extends Action {
  type: ProjectConst.EDIT_PROJECT_IMAGE;
  projectId: number;
  projectImage: ResourceImageData;
  name: string;
  description: string;
  newImage: string;
  userId: number;
}

export interface EditProjectImageOkAction extends Action {
  type: ProjectConst.EDIT_PROJECT_IMAGE_OK;
  projectImage: ResourceImageData;
}

export interface DeleteProjectImageAction extends Action {
  type: ProjectConst.DELETE_PROJECT_IMAGE;
  projectId: number;
  projectImage: ResourceImageData;
}

export interface DeleteProjectImageOkAction extends Action {
  type: ProjectConst.DELETE_PROJECT_IMAGE_OK;
  projectImageId: number;
}

export interface ProjectImagesKoAction extends Action {
  type: ProjectConst.PROJECT_IMAGES_KO;
  error: string;
}

export interface ProjectCancelAgreementAction extends Action {
  type: ProjectConst.PROJECT_CANCEL_AGREEMENT;
  project_id: number;
}

export interface ProjectCancelAgreementOkAction extends Action {
  type: ProjectConst.PROJECT_CANCEL_AGREEMENT_OK;
  state_code: StateProject;
  state_name: string;
}

export interface ProjectCancelAgreementKoAction extends Action {
  type: ProjectConst.PROJECT_CANCEL_AGREEMENT_KO;
  error: string;
}

export type ProjectAction =
  | GetProjectsAction
  | GetProjectsOkAction
  | GetProjectsKoAction
  | GetOneProjectAction
  | GetOneProjectOkAction
  | GetOneProjectKoAction
  | CreateProjectAction
  | CreateProjectOkAction
  | CreateProjectKoAction
  | EditProjectAction
  | EditProjectOkAction
  | EditProjectKoAction
  | DeleteProjectAction
  | DeleteProjectOkAction
  | DeleteProjectKoAction
  | ProjectValidationErrorsAction
  | GetAreasSubAreasAction
  | GetAreasSubAreasOkAction
  | GetAreasSubAreasKoAction
  | AddDocumentAction
  | AddDocumentOkAction
  | AddDocumentKoAction
  | GetDocumentationAction
  | GetDocumentationOkAction
  | GetDocumentationKoAction
  | DeleteFileAction
  | DeleteFileOkAction
  | DeleteFileKoAction
  | GetFileAction
  | GetFileOkAction
  | GetFileKoAction
  | ProjectChangeStateAction
  | ProjectChangeStateOkAction
  | ProjectChangeStateKoAction
  | DownloadFileAction
  | DownloadFileOkAction
  | DownloadFileKoAction
  | GetFollowUpAction
  | GetFollowUpOkAction
  | GetFollowUpKoAction
  | AddFollowUpAction
  | AddFollowUpOkAction
  | AddFollowUpKoAction
  | ChangeCreateModalVisible
  | ChangeEditDeleteModalVisible
  | GetListFollowUpsAction
  | GetListFollowUpsOkAction
  | GetListFollowUpsKoAction
  | EditFollowUpAction
  | EditFollowUpOkAction
  | EditFollowUpKoAction
  | DeleteFollowUpAction
  | DeleteFollowUpOkAction
  | DeleteFollowUpKoAction
  | UploadDocumentationAction
  | UploadDocumentationOkAction
  | UploadDocumentationKoAction
  | ResetDocumentsAction
  | DownloadDocumentFollowupAction
  | DownloadDocumentFollowupOkAction
  | DownloadDocumentFollowupKoAction
  | RemoveFollowupDocumentAction
  | RemoveFollowupDocumentOkAction
  | RemoveFollowupDocumentKoAction
  | UploadAgreementAction
  | UploadAgreementOkAction
  | UploadAgreementKoAction
  | DeleteAgreementAction
  | DeleteAgreementOkAction
  | DeleteAgreementKoAction
  | ConfirmConfiguratorAction
  | ConfirmConfiguratorOkAction
  | ConfirmConfiguratorKoAction
  | ResetConfiguratorDataAction
  | SaveCustomFieldsFormAction
  | AddNewGenericTableRowAction
  | SetterGenericTableRowAction
  | AssignDocsToRowAction
  | AssignDocsToRowOkAction
  | AssignDocsToRowKoAction
  | RemoveDocsTableAction
  | RemoveDocsTableOkAction
  | RemoveDocsTableKoAction
  | DeleteRowTableAction
  | DeleteRowTableOkAction
  | DeleteRowTableKoAction
  | GenericFieldUploadAction
  | GenericFieldUploadOkAction
  | GenericFieldUploadKoAction
  | DownloadGenericFileAction
  | DownloadGenericFileOkAction
  | DownloadGenericFileKoAction
  | GetGenericFileAction
  | GetGenericFileOkAction
  | GetGenericFileKoAction
  | DeleteGenericFileAction
  | DeleteGenericFileOkAction
  | DeleteGenericFileKoAction
  | GetGenericFieldsAction
  | GetGenericFieldsOkAction
  | GetGenericFieldsKoAction
  | GetDirectoryDataFilterAction
  | GetDirectoryDataFilterOkAction
  | GetDirectoryDataFilterKoAction
  | GetActorsMetadataAction
  | GetActorsMetadataOkAction
  | SendActorAction
  | SendActorOkAction
  | DeleteActorAction
  | DeleteActorOkAction
  | SetProjectDataAction
  | SetProjectScheduleDataAction
  | GetProjectSchedulesAction
  | GetProjectSchedulesOkAction
  | CreateProjectScheduleAction
  | UpdateProjectScheduleAction
  | DeleteProjectScheduleAction
  | ProjectGenericKoAction
  | UpdateProjectCalendarAction
  | EditProjectScheduleItemAction
  | EditProjectScheduleItemOkAction
  | SelectProjectScheduleItemAction
  | UploadFollowUpDocumentAction
  | UploadFollowUpDocumentOkAction
  | UploadFollowUpDocumentKoAction
  | RemoveFollowUpDocumentAction
  | RemoveFollowUpDocumentOkAction
  | RemoveFollowUpDocumentKoAction
  | DownloadFollowUpDocumentAction
  | DownloadFollowUpDocumentOkAction
  | DownloadFollowUpDocumentKoAction
  | AddNewScheduleAction
  | UploadScheduleDocumentAction
  | UploadScheduleDocumentOkAction
  | UploadScheduleDocumentKoAction
  | SetScheduleDataAction
  | CreateNewScheduleProjectAction
  | CreateNewScheduleProjectOkAction
  | CreateNewScheduleProjectKoAction
  | ProjectPaymentAction
  | SetPaymentData
  | UploadPaymentFileAction
  | UploadPaymentFileOkAction
  | UploadPaymentFileKoAction
  | RemovePaymentFileAction
  | RemovePaymentFileOkAction
  | RemovePaymentFileKoAction
  | LinkPaymentAction
  | GetProjectImagesAction
  | GetProjectImagesOkAction
  | AddProjectImageAction
  | AddProjectImageOkAction
  | EditProjectImageAction
  | EditProjectImageOkAction
  | DeleteProjectImageAction
  | DeleteProjectImageOkAction
  | ProjectImagesKoAction
  | ProjectCancelAgreementAction
  | ProjectCancelAgreementOkAction
  | ProjectCancelAgreementKoAction;

export type ProjectDispatch = (action: ProjectAction | ShowSnackbarAction) => void;

interface ShowButtons {
  follow_note: boolean;
  save: boolean;
  send: ProjectChangeStateKo | ProjectChangeStateOk;
  refuse: ProjectChangeStateKo | ProjectChangeStateOk;
  roles: string[];
  aprove: ProjectChangeStateKo | ProjectChangeStateOk;
  owner_action: boolean;
  review: ProjectChangeStateKo | ProjectChangeStateOk;
  validate: ProjectChangeStateKo | ProjectChangeStateOk;
  cancel_signature: 'p10p09';
  pay: boolean;
  super: boolean;
}
export interface ButtonsStateControl {
  P02: ShowButtons;
  P03: ShowButtons;
  P04: ShowButtons;
  P05: ShowButtons;
  P07: ShowButtons;
  P08: ShowButtons;
  P09: ShowButtons;
  P10: ShowButtons;
  P11: ShowButtons;
  P12: ShowButtons;
  P13: ShowButtons;
  P14: ShowButtons;
  P15: ShowButtons;
  P16: ShowButtons;
  P17: ShowButtons;
  P18: ShowButtons;
  P19: ShowButtons;
  P20: ShowButtons;
  P21: ShowButtons;
  P22: ShowButtons;
  P23: ShowButtons;
  P99: ShowButtons;
}
const pma = [
  RoleCode.PM_KOMTU,
  RoleCode.PM_BENESTAR_EMOCIONAL,
  RoleCode.PM_DOMUM,
  RoleCode.PM_GLOBAL_DEVELOPMENT,
  RoleCode.PM_GB_SENEGAL,
  RoleCode.PM_OCUPABILITAT,
  RoleCode.PM_FORMACIO,
  RoleCode.PM_XARXA,
  RoleCode.PM_INSERCIO_LABORAL,
];
const dirAArea = [RoleCode.DIR_BENESTAR_EMOCIONAL, RoleCode.DIR_GLOBAL_DEVELOPMENT, RoleCode.DIR_OCUPABILITAT];
const resEnt = [RoleCode.RESPONSABLE_ENTIDAD, RoleCode.RESPONSABLE_PROYECTO];
const dirFnc = [RoleCode.DIRECCION_NOUSCIMS];
const admini = [RoleCode.ADMINISTRACION_NOUSCIMS, RoleCode.SOPORTE_ADMINSTRACION];

export const buttonsDatas = {
  state: {
    P02: {
      follow_note: true,
      save: true,
      send: 'p02p04',
      roles: [...pma, ...dirAArea],
      owner_action: true,
    } as ShowButtons,
    P04: {
      follow_note: true,
      save: true,
      aprove: 'p04p05',
      roles: pma,
      owner_action: false,
    } as ShowButtons,
    P05: {
      follow_note: true,
      save: true,
      aprove: 'p05p07',
      refuse: 'p05p04',
      roles: dirAArea,
      owner_action: false,
    } as ShowButtons,
    P07: {
      follow_note: true,
      save: true,
      send: 'p07p08',
      roles: [...pma, ...dirAArea],
      owner_action: false,
    } as ShowButtons,
    P08: {
      follow_note: true,
      save: false,
      aprove: 'p08p09',
      refuse: 'p08p07',
      roles: admini,
      owner_action: false,
    } as ShowButtons,
    P09: {
      follow_note: false,
      save: false,
      send: 'p09p10',
      refuse: 'p09p07',
      roles: dirFnc,
      owner_action: false,
    } as ShowButtons,
    P10: {
      follow_note: true,
      save: false,
      cancel_signature: 'p10p09',
      roles: [dirFnc, resEnt].flat(),
      owner_action: false,
    } as ShowButtons,
    P13: {
      follow_note: false,
      save: true,
      send: 'p13p14',
      roles: [dirFnc, pma, dirAArea, resEnt].flat(),
      owner_action: false,
      pay: true,
    } as ShowButtons,
    P14: {
      follow_note: true,
      save: true,
      validate: 'p14p22',
      review: 'p14p13',
      roles: [dirFnc, pma, dirAArea].flat(),
      owner_action: true,
      pay: true,
    } as ShowButtons,
    P15: {
      follow_note: true,
      save: false,
      validate: 'p15p22',
      review: 'p15p14',
      roles: admini,
      owner_action: false,
      pay: true,
    } as ShowButtons,
    P17: {
      follow_note: true,
      save: true,
      send: 'p17p18',
      roles: pma,
      owner_action: false,
    } as ShowButtons,
    P18: {
      follow_note: true,
      save: true,
      validate: 'p18p19',
      review: 'p18p17',
      roles: dirAArea,
      owner_action: false,
    } as ShowButtons,
    P19: {
      follow_note: true,
      save: true,
      review: 'p19p18',
      validate: 'p19p20',
      roles: dirFnc,
      owner_action: false,
    } as ShowButtons,
    P21: {
      follow_note: true,
      save: false,
      validate: 'p14p22',
      review: 'p14p13',
      cancel_signature: 'p10p09',
      roles: [dirFnc, pma, dirAArea].flat(),
      owner_action: false,
      pay: false,
    } as ShowButtons,
    P22: {
      follow_note: true,
      save: false,
      validate: 'p14p22',
      review: 'p14p13',
      roles: [dirFnc, pma, dirAArea].flat(),
      owner_action: true,
      pay: false,
    } as ShowButtons,
    P23: {
      follow_note: true,
      save: true,
      send: 'p23p22',
      roles: [dirFnc, pma, dirAArea].flat(),
      owner_action: false,
      pay: false,
    } as ShowButtons,
  } as ButtonsStateControl,
  showButton: function(
    state_code: keyof ButtonsStateControl,
    button: keyof ShowButtons,
    role: string,
    user_id: number,
    owner_rol: string,
    owner_id?: number,
  ) {
    const verifyRole = (role: string) => this.state[state_code].roles.includes(role);

    if (!this.state[state_code]) return true;

    const show = !this.state[state_code][button];

    if (this.state[state_code].super && [...admini, ...dirFnc].includes(role)) return show;

    // En seguimiento sólo puede guardar el owner del proyecto
    if (button === 'save' && state_code === StateProject.TRACKING && owner_id === user_id) return false;

    if (button === 'follow_note') return resEnt.includes(role);

    if (button === 'pay') {
      if (admini.includes(role)) return show;
      return true;
    }

    if (this.state[state_code].owner_action) {
      if (owner_rol !== role) return true;
      if (verifyRole(owner_rol)) return show;
    }
    if (verifyRole(role)) return show;
    return true;
  },

  getTransition: function(
    state_code: keyof ButtonsStateControl,
    button: 'refuse' | 'aprove' | 'send' | 'review' | 'validate' | 'cancel_signature',
  ): ProjectChangeStateKo | ProjectChangeStateOk {
    if (this.state[state_code][button]) return this.state[state_code][button];
    return 'error';
  },
};
