import { MenuItem } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { Control, FieldErrors, FieldValues } from 'react-hook-form/dist/types';
import { ObjectSchema, Shape } from 'yup';
import FormContainer from '../../../../components/Forms/FormContainer';
import TextInputController from '../../../../components/Inputs/TextInputController/TextInputController';
import SelectController from '../../../../components/Select/SelectController';
import { tKey, tScholarshipKey } from '../../../../helpers/translate';
import { IdNamePair } from '../../../../redux/common/definitions';
import { ScholarshipFollow, ScholarshipMetadata } from '../../../../redux/scholarship/definitions';
import FollowUpValoration from '../../../../types/Scholarships/FollowUpValoration';

interface SocialImpactProps<T extends FieldValues> {
  scholarshipFollow: ScholarshipFollow;
  scholarshipMetadata: ScholarshipMetadata;
  isEntity: boolean;
  readOnly?: boolean;
  errors: FieldErrors<T> | any;
  control: Control<T>;
  schema: ObjectSchema<T> | ObjectSchema<Shape<object, any>>;
  setDataFollow: (subKey: string, value: string | number | null) => void;
}

const mentorRelationshipResponses = (): string[] => {
  return [
    'Más confianza en mí mismo/a',
    'Ampliar mi punto de vista y perspectiva de futuro',
    'Mejorar resultados académicos',
    'Ampliar mi red de relaciones sociales y amigos',
    'Descubrir nuevas oportunidades laborales',
  ];
};

const mentoringParticipationResponses = (): string[] => {
  return ['Sí', 'No'];
};

const SocialImpact = <T extends FieldValues>({
  readOnly,
  scholarshipMetadata,
  scholarshipFollow,
  isEntity,
  errors,
  control,
  schema,
  setDataFollow,
}: SocialImpactProps<T>): ReactElement => {
  const {
    scholarship_student_changes_id,
    scholarship_personal_skills_id,
    per_scholarship_academic_levels_id,
    scholarship_student_personal_changes_reason_others,
    scholarship_personal_skills_reason_others,
    mentoring_participation,
    mentor_evaluation,
    mentor_relationship_evaluation,
  } = scholarshipFollow;
  const { studentChangePersonalReasons, satisfactionLeves, studentSatisfactions } = scholarshipMetadata;

  const handleSelectChange = (target: any) => {
    if (target.name) {
      setDataFollow(target.name, target.value);
    }
  };

  const renderMentoringQuestions = (): JSX.Element | undefined => {
    if (isEntity) {
      return;
    }
    return (
      <>
        <SelectController
          control={control}
          name="mentoring_participation"
          label={tScholarshipKey('follow_up.mentoring_participation')}
          defaultValue={mentoring_participation}
          size="50"
          disabled={readOnly}
          validator={!readOnly}
          onClick={e => handleSelectChange(e.target)}
          schema={schema}
          errors={errors}
        >
          {mentoringParticipationResponses().map((element: string) => (
            <MenuItem key={element} value={element}>
              {tKey(element)}
            </MenuItem>
          ))}
        </SelectController>
        <SelectController
          control={control}
          name="mentor_evaluation"
          label={tScholarshipKey('follow_up.mentor_evaluation')}
          defaultValue={mentor_evaluation}
          size="50"
          disabled={readOnly}
          validator={!readOnly}
          onClick={e => handleSelectChange(e.target)}
          schema={schema}
          errors={errors}
        >
          {FollowUpValoration.toArray().map((element: string) => (
            <MenuItem key={element} value={element}>
              {tScholarshipKey(`follow_up.valoration.${element}`)}
            </MenuItem>
          ))}
        </SelectController>
        <SelectController
          control={control}
          name="mentor_relationship_evaluation"
          label={tScholarshipKey('follow_up.mentor_relationship_evaluation')}
          defaultValue={mentor_relationship_evaluation}
          size="50"
          disabled={readOnly}
          validator={!readOnly}
          onClick={e => handleSelectChange(e.target)}
          schema={schema}
          errors={errors}
        >
          {mentorRelationshipResponses().map((element: string) => (
            <MenuItem key={element} value={element}>
              {tScholarshipKey(element)}
            </MenuItem>
          ))}
        </SelectController>
      </>
    );
  };

  return (
    <FormContainer title={tScholarshipKey('Impacto personal y social')}>
      <SelectController
        control={control}
        name="scholarship_student_changes_id"
        label={tScholarshipKey('cambios-personales-curso')}
        defaultValue={scholarship_student_changes_id}
        size="100"
        disabled={readOnly}
        validator={!readOnly}
        onClick={e => handleSelectChange(e.target)}
        schema={schema}
        errors={errors}
      >
        {studentChangePersonalReasons.map((element: IdNamePair) => (
          <MenuItem key={element.id} value={element.id}>
            {element.name}
          </MenuItem>
        ))}
      </SelectController>
      {scholarship_student_changes_id === 5 && (
        <TextInputController
          control={control}
          name="scholarship_student_personal_changes_reason_others"
          label={tScholarshipKey('follow_up.student_personal_changes_reason_others')}
          defaultValue={scholarship_student_personal_changes_reason_others}
          size="100"
          schema={schema}
          validator={!readOnly}
          errors={errors}
          multiline
          rows={3}
          rowsMax={3}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
            setDataFollow('scholarship_student_personal_changes_reason_others', e.target.value)
          }
        />
      )}
      <SelectController
        control={control}
        name="per_scholarship_academic_levels_id"
        label={tScholarshipKey('nivel-satisfacción-formativa')}
        defaultValue={per_scholarship_academic_levels_id}
        size="50"
        disabled={readOnly}
        schema={schema}
        validator={!readOnly}
        errors={errors}
        onClick={e => handleSelectChange(e.target)}
      >
        {satisfactionLeves.map((element: IdNamePair) => (
          <MenuItem key={element.id} value={element.id}>
            {element.name}
          </MenuItem>
        ))}
      </SelectController>
      <SelectController
        control={control}
        name="scholarship_personal_skills_id"
        label={tScholarshipKey('¿Porqué tiene este nivel de satisfacción?')}
        defaultValue={scholarship_personal_skills_id}
        size="100"
        disabled={readOnly}
        onClick={e => handleSelectChange(e.target)}
        validator={!readOnly}
        schema={schema}
        errors={errors}
      >
        {studentSatisfactions.map((element: IdNamePair) => (
          <MenuItem key={element.id} value={element.id}>
            {element.name}
          </MenuItem>
        ))}
      </SelectController>
      {scholarship_personal_skills_id === 9 && (
        <TextInputController
          control={control}
          name="scholarship_personal_skills_reason_others"
          label={tScholarshipKey('follow_up.personal_skills_reason_others')}
          defaultValue={scholarship_personal_skills_reason_others}
          size="100"
          schema={schema}
          disabled={readOnly}
          validator={!scholarship_personal_skills_reason_others}
          errors={errors}
          multiline
          rows={3}
          rowsMax={3}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
            setDataFollow('scholarship_personal_skills_reason_others', e.target.value)
          }
        />
      )}
      {renderMentoringQuestions()}
    </FormContainer>
  );
};

export default SocialImpact;
