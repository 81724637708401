import React from 'react';
import TextInput from '../TextInput';
import { InputLabel } from '@material-ui/core';
import styles from '../inputs.module.scss';

interface LabeledTextInputProps extends React.ComponentProps<typeof TextInput> {
  label: string;
}

const LabeledTextInput: React.FC<LabeledTextInputProps> = ({ label, ...inputProps }) => {
  return (
    <div
      className={inputProps.size && styles[`size_${inputProps.size}`]}
      style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
    >
      <InputLabel disabled={inputProps.disabled}>{label}</InputLabel>
      <TextInput {...inputProps} size={undefined} />
    </div>
  );
};

export default LabeledTextInput;
