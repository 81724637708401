import React from 'react';
import * as yup from 'yup';
import Modal from '../../../../components/Modal/Modal';
import { tErrorKey, tScholarshipKey } from '../../../../helpers/translate';
import formatString from '../../../../helpers/formatString';
import styles from './scholarshipRemainderModal.module.scss';
import { ButtonProps } from '@material-ui/core';
import TextInputController from '../../../../components/Inputs/TextInputController/TextInputController';
import { useForm } from 'react-hook-form';

interface ScholarshipRemainderModalProps {
  show: boolean;
  remainingAmount: number;
  onClose: () => void;
  onConfirm: (remainderRemarks: string) => void;
}

const ScholarshipRemainderSchema = yup.object().shape({
  remainder_remarks: yup.string().required(tErrorKey('remainder_remarks_required')),
});

const ScholarshipRemainderModal: React.FC<ScholarshipRemainderModalProps> = ({
  show,
  remainingAmount,
  onConfirm,
  onClose,
}) => {
  const { errors, control, handleSubmit } = useForm<{ remainder_remarks: string }>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: ScholarshipRemainderSchema,
  });

  const onSubmit = (data: { remainder_remarks: string }) => {
    onConfirm(data.remainder_remarks);
  };

  const buttons: ButtonProps[] = [
    {
      children: tScholarshipKey('remainder_ko'),
      onClick: onClose,
      color: 'secondary',
      variant: 'contained',
      disableElevation: true,
    },
    {
      onClick: handleSubmit(onSubmit),
      children: tScholarshipKey('remainder_ok'),
      variant: 'contained',
      color: 'primary',
      disableElevation: true,
    },
  ];

  return (
    <Modal title={tScholarshipKey('remainder_title')} onClose={onClose} buttons={buttons} open={show}>
      <form className={styles.form_container}>
        <span className={styles.message}>
          {formatString(tScholarshipKey('remainder_message'), remainingAmount.toLocaleString())}
        </span>
        <TextInputController
          errors={errors}
          fullWidth
          multiline
          rows="4"
          rowsMax="10"
          label={tScholarshipKey('remainder_remarks')}
          name="remainder_remarks"
          control={control}
          schema={ScholarshipRemainderSchema}
        />
      </form>
    </Modal>
  );
};

export default ScholarshipRemainderModal;
