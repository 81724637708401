import { ButtonProps } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import DayMonthSelector from '../../../components/DayMonthSelector/dayMonthSelector';
import FormContainer from '../../../components/Forms/FormContainer';
import LayoutForm from '../../../components/Layout/LayoutForm';
import ActionsMenu from '../../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import Loading from '../../../components/Loading/Loading';
import SwitchController from '../../../components/SwitchController/SwitchController';
import Title from '../../../components/Title/Title';
import { tFormKey, tKey } from '../../../helpers/translate';
import {
  getScholarshipSetting,
  scholarshipSetDataSettings,
  updateScholarshipSettingAction,
} from '../../../redux/accounting/actions';
import { ScholarshipSetting } from '../../../redux/accounting/definitions';
import { selectScholarshipSetting } from '../../../redux/accounting/selectors';
import { selectUserCan } from '../../../redux/permissions/selectors';
import { AppState } from '../../../redux/root-reducer';
import { AccoutingDefaultCenterCostSchema } from '../../../validations/formSchema';
import SubmenuConfiguration from '../SubmenuConfiguration';
import styles from './enableScholarship.module.scss';

interface EnableScholarshipFields {
  scholarship_entity: boolean;
  scholarship_institute: boolean;
}

interface ConfigEnableScholarshipProps {
  loading: boolean;
  userCan: boolean;
  scholarshipSetting: ScholarshipSetting;
  setData: (key: string, value: string | number) => void;
  updateScholarshipsetting: (value: ScholarshipSetting) => void;
  getScholarshipsetting: () => void;
}

const ConfigEnableScholarship: React.FC<ConfigEnableScholarshipProps> = ({
  userCan,
  scholarshipSetting,
  loading,
  updateScholarshipsetting,
  getScholarshipsetting,
  setData,
}) => {
  const { errors, control, handleSubmit, setValue, watch } = useForm<ScholarshipSetting>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: AccoutingDefaultCenterCostSchema,
  });

  const {
    allow_create_entity_scholarships,
    allow_create_institute_scholarships,
    allow_renew_entity_scholarships,
    allow_renew_institute_scholarships,
    start_course_day,
    start_course_month,
  } = scholarshipSetting;

  useEffect(() => {
    getScholarshipsetting();
  }, [getScholarshipsetting]);

  const onSave = (data: ScholarshipSetting) => {
    updateScholarshipsetting({
      allow_create_entity_scholarships: Number(data.allow_create_entity_scholarships),
      allow_create_institute_scholarships: Number(data.allow_create_institute_scholarships),
      allow_renew_entity_scholarships: Number(data.allow_renew_entity_scholarships),
      allow_renew_institute_scholarships: Number(data.allow_renew_institute_scholarships),
      start_course_day: Number(data.start_course_day),
      start_course_month: Number(data.start_course_month),
    } as ScholarshipSetting);
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setData(event.target.name, Number(event.target.checked));
    setValue(event.target.name, event.target.checked);
  };

  const buttons: ButtonProps[] = [
    {
      children: tKey('Guardar'),
      disableElevation: true,
      fullWidth: true,
      color: 'primary',
      variant: 'contained',
      disabled: !userCan,
      onClick: handleSubmit(onSave),
    },
  ];

  return (
    <LayoutForm
      leftSubmenu={<SubmenuConfiguration selected="/configuracion/becas" submenu={tKey('Activar/desactivar becas')} />}
      rightSubmenu={<ActionsMenu actionsButtons={buttons} />}
    >
      <Title>{tKey('Activar/desactivar altas de creación de becas')}</Title>
      {!loading ? (
        <div className={styles.generalContainer}>
          <div className={styles.firstRow}>
            <div className={styles.boxContainer}>
              <FormContainer title={tFormKey('Becas entidad')}>
                <div className={styles.containerWithPadding}>
                  <SwitchController
                    key={1}
                    control={control}
                    name={'allow_create_entity_scholarships'}
                    label={tKey('Crear beca entidad')}
                    defaultValue={
                      watch('allow_create_entity_scholarships')
                        ? !!watch('allow_create_entity_scholarships')
                        : allow_create_entity_scholarships === 1
                    }
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSelectChange(e)}
                  />
                  <SwitchController
                    key={2}
                    control={control}
                    name={'allow_renew_entity_scholarships'}
                    label={tKey('Renovar beca entidad')}
                    defaultValue={
                      watch('allow_renew_entity_scholarships')
                        ? !!watch('allow_renew_entity_scholarships')
                        : allow_renew_entity_scholarships === 1
                    }
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSelectChange(e)}
                  />
                </div>
              </FormContainer>
            </div>
            <div className={styles.boxContainer}>
              <FormContainer title={tFormKey('Becas instituto')}>
                <div className={styles.containerWithPadding}>
                  <SwitchController
                    key={3}
                    control={control}
                    name={'allow_create_institute_scholarships'}
                    label={tKey('Crear beca instituto')}
                    defaultValue={
                      watch('allow_create_institute_scholarships')
                        ? !!watch('allow_create_institute_scholarships')
                        : allow_create_institute_scholarships === 1
                    }
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSelectChange(e)}
                  />
                  <SwitchController
                    key={4}
                    control={control}
                    name={'allow_renew_institute_scholarships'}
                    label={tKey('Renovar beca instituto')}
                    defaultValue={
                      watch('allow_renew_institute_scholarships')
                        ? !!watch('allow_renew_institute_scholarships')
                        : allow_renew_institute_scholarships === 1
                    }
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSelectChange(e)}
                  />
                </div>
              </FormContainer>
            </div>
          </div>
          <DayMonthSelector
            dayMonthSchema={AccoutingDefaultCenterCostSchema}
            control={control}
            errors={errors}
            day={start_course_day}
            month={start_course_month}
            title={'Inicio de convocatoria'}
            nameDay={'start_course_day'}
            nameMonth={'start_course_month'}
            subTitle={''}
          />
        </div>
      ) : (
        <Loading big />
      )}
    </LayoutForm>
  );
};

const mapStateToProps = (state: AppState) => ({
  userCan: selectUserCan(state)('action_scholarships_permissions'),
  loading: state.accountingReducer.loading,
  scholarshipSetting: selectScholarshipSetting(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  setData: (key: string, value: string | number) => dispatch(scholarshipSetDataSettings(key, value)),
  updateScholarshipsetting: (value: ScholarshipSetting): void => dispatch(updateScholarshipSettingAction(value)),
  getScholarshipsetting: (): void => dispatch(getScholarshipSetting()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfigEnableScholarship);
