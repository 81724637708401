import { roundNumber } from '../../../helpers/roundNumber';
import PaymentStatus from '../../../types/Payments/PaymentStatus';
import { AppState } from '../../root-reducer';
import { ScholarshipPaymentAdjustmentData, ScholarshipPaymentAdjustmentItem } from './PaymentAdjustment';
import { ScholarshipPaymentAdjustmentDataState } from './definitions';

const selectScholarshipPaymentAdjustment = (state: AppState): ScholarshipPaymentAdjustmentDataState =>
  state.scholarshipPaymentAdjustmentsReducer;

export const selectPaymentsLoading = (state: AppState): boolean => selectScholarshipPaymentAdjustment(state).loading;
export const selectPaymentsError = (state: AppState): string | null => selectScholarshipPaymentAdjustment(state).error;
export const selectAdjustmentsLoading = (state: AppState): boolean =>
  selectScholarshipPaymentAdjustment(state).loadingAdjustments;
export const selectScholarshipPaymentAdjustmentData = (state: AppState): ScholarshipPaymentAdjustmentData =>
  selectScholarshipPaymentAdjustment(state).scholarshipPaymentAdjustmentData;

export const selectScholarshipAmount = (state: AppState): number =>
  selectScholarshipPaymentAdjustment(state).scholarshipPaymentAdjustmentData.scholarship_amount;

export const selectScholarshipTotalAdjustments = (state: AppState): number => {
  const scholarship_adjustments = selectScholarshipPaymentAdjustment(state).scholarshipPaymentAdjustmentData
    .scholarship_adjustments;
  const total_adjustments = scholarship_adjustments.reduce(
    (acc, payment: ScholarshipPaymentAdjustmentItem) => acc + Number(payment.payment_amount),
    0,
  );
  return roundNumber(total_adjustments);
};

export const selectTotalScholarship = (state: AppState): number => {
  const total_adjustments = selectScholarshipTotalAdjustments(state);
  const scholarship_amount = selectScholarshipAmount(state);
  return roundNumber(total_adjustments + scholarship_amount);
};

export const selectActiveScholarshipPayments = (state: AppState): ScholarshipPaymentAdjustmentItem[] => {
  const scholarship_payments = selectScholarshipPaymentAdjustment(state).scholarshipPaymentAdjustmentData
    .scholarship_payments;
  return scholarship_payments.filter(
    (payment: ScholarshipPaymentAdjustmentItem) => payment.status !== PaymentStatus.CANCELED,
  );
};

export const selectScholarshipPaymentDiffs = (state: AppState): number => {
  const scholarship_payments = selectActiveScholarshipPayments(state);
  const scholarship_amount = selectScholarshipAmount(state);
  const current_amount = scholarship_payments.reduce(
    (acc, payment: ScholarshipPaymentAdjustmentItem) => acc + Number(payment.payment_amount),
    0,
  );
  return roundNumber(scholarship_amount - current_amount);
};

export const selectScholarshipPaymentsHasSomePaymentDone = (state: AppState): boolean => {
  const scholarship_payments = selectScholarshipPaymentAdjustment(state).scholarshipPaymentAdjustmentData
    .scholarship_payments;
  const scholarship_adjustments = selectScholarshipPaymentAdjustment(state).scholarshipPaymentAdjustmentData
    .scholarship_adjustments;
  const paymentsDone = scholarship_payments.some(
    (payment: ScholarshipPaymentAdjustmentItem) => payment.status === PaymentStatus.PAID,
  );
  const adjustmentsDone = scholarship_adjustments.some(
    (payment: ScholarshipPaymentAdjustmentItem) => payment.status === PaymentStatus.PAID,
  );
  return paymentsDone || adjustmentsDone;
};
