import { connect } from 'react-redux';
import { selectIsNousCims } from '../../../../redux/auth/selectors';
import { selectGenericMetadata } from '../../../../redux/metadata/selectors';
import { selectUserCan } from '../../../../redux/permissions/selectors';
import { AppState } from '../../../../redux/root-reducer';
import {
  saveFollowUp,
  scholarshipSetDataFollowAction,
  uploadDocumentTracking,
} from '../../../../redux/scholarship/actions';
import { ScholarshipFollow } from '../../../../redux/scholarship/definitions';
import {
  selectGetScholarshipDocumentationPerCode,
  selectScholarshipFollowUp3,
} from '../../../../redux/scholarship/selectors';
import FollowForm from './FollowForm';

const mapStateToProps = (state: AppState) => ({
  scholarshipFollow: selectScholarshipFollowUp3(state),
  isNousCims: selectIsNousCims(state),
  canTrackingTransition: selectUserCan(state)('allow_tracking_transition'),
  genericMetadata: selectGenericMetadata(state),
  documentationWork: selectGetScholarshipDocumentationPerCode(state, ['NOM']),
});

const mapDispatchToProps = (dispatch: any) => ({
  setDataFollow: (subKey: string, value: string | number | null): void =>
    dispatch(scholarshipSetDataFollowAction('follow_t3', subKey, value)),
  saveFollowUp: (follow_up: ScholarshipFollow) => dispatch(saveFollowUp(follow_up)),
  uploadDocumentTracking: (documentation_id: number, data: File, scholarship_id: number, tracking_id: number) =>
    dispatch(uploadDocumentTracking(documentation_id, data, scholarship_id, tracking_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FollowForm);
