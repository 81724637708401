import { ButtonProps } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import * as yup from 'yup';
import TextInputController from '../../../components/Inputs/TextInputController/TextInputController';
import LayoutForm from '../../../components/Layout/LayoutForm';
import ActionsMenu from '../../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import Loading from '../../../components/Loading/Loading';
import Title from '../../../components/Title/Title';
import { tFormKey, tKey } from '../../../helpers/translate';
import { getScholarshipSetting, updateScholarshipSettingAction } from '../../../redux/accounting/actions';
import { ScholarshipSetting } from '../../../redux/accounting/definitions';
import { selectScholarshipSetting } from '../../../redux/accounting/selectors';
import { AppState } from '../../../redux/root-reducer';
import SubmenuConfiguration from '../SubmenuConfiguration';
import styles from './followUpSettings.module.scss';
import SubTitle from '../../../components/SubTitle/Subtitle';

const followUpSettingSchema = yup.object().shape({
  first_institute_followup_survey_url: yup.string().nullable(),
  last_institute_followup_survey_url: yup.string().nullable(),
  first_entity_followup_survey_url: yup.string().nullable(),
  last_entity_followup_survey_url: yup.string().nullable(),
});

interface FollowUpSettingsProps {
  loading: boolean;
  scholarshipSetting: ScholarshipSetting;
  updateScholarshipsetting: (value: ScholarshipSetting) => void;
  getScholarshipsetting: () => void;
}

const FollowUpSettings: React.FC<FollowUpSettingsProps> = ({
  scholarshipSetting,
  loading,
  updateScholarshipsetting,
  getScholarshipsetting,
}) => {
  const { errors, control, handleSubmit } = useForm<ScholarshipSetting>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: followUpSettingSchema,
  });

  const {
    first_institute_followup_survey_url,
    last_institute_followup_survey_url,
    first_entity_followup_survey_url,
    last_entity_followup_survey_url,
  } = scholarshipSetting;

  useEffect(() => {
    getScholarshipsetting();
  }, [getScholarshipsetting]);

  const onSave = (data: ScholarshipSetting) => {
    updateScholarshipsetting(data);
  };

  const buttons: ButtonProps[] = [
    {
      children: tKey('Guardar'),
      disableElevation: true,
      fullWidth: true,
      color: 'primary',
      variant: 'contained',
      onClick: handleSubmit(onSave),
    },
  ];

  return (
    <LayoutForm
      leftSubmenu={
        <SubmenuConfiguration selected="/configuracion/becas" submenu={tFormKey('formulario-seguimientos')} />
      }
      rightSubmenu={<ActionsMenu actionsButtons={buttons} />}
    >
      <Title>{tFormKey('formulario-seguimientos')}</Title>
      {!loading ? (
        <>
          <div className={styles.generalContainer}>
            <SubTitle>{tFormKey('seguimentos-instituto')}</SubTitle>
            <TextInputController
              control={control}
              errors={errors}
              defaultValue={first_institute_followup_survey_url}
              schema={followUpSettingSchema}
              label={tFormKey('follow_up_first_form_url')}
              name="first_institute_followup_survey_url"
            />
            <TextInputController
              control={control}
              errors={errors}
              defaultValue={last_institute_followup_survey_url}
              schema={followUpSettingSchema}
              label={tFormKey('follow_up_last_form_url')}
              name="last_institute_followup_survey_url"
            />
          </div>
          <div className={styles.generalContainer}>
            <SubTitle>{tFormKey('seguimentos-entidad')}</SubTitle>
            <TextInputController
              control={control}
              errors={errors}
              defaultValue={first_entity_followup_survey_url}
              schema={followUpSettingSchema}
              label={tFormKey('follow_up_first_form_url')}
              name="first_entity_followup_survey_url"
            />
            <TextInputController
              control={control}
              errors={errors}
              defaultValue={last_entity_followup_survey_url}
              schema={followUpSettingSchema}
              label={tFormKey('follow_up_last_form_url')}
              name="last_entity_followup_survey_url"
            />
          </div>
        </>
      ) : (
        <Loading big />
      )}
    </LayoutForm>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: state.accountingReducer.loading,
  scholarshipSetting: selectScholarshipSetting(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  updateScholarshipsetting: (value: ScholarshipSetting): void => dispatch(updateScholarshipSettingAction(value)),
  getScholarshipsetting: (): void => dispatch(getScholarshipSetting()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FollowUpSettings);
