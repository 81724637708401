import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { permissionType } from '../../../constants/permissions_definitions';
import { selectPermissionsList } from '../../../redux/permissions/selectors';
import { AppState } from '../../../redux/root-reducer';

interface UsersMenuProps {
  permissions?: permissionType[];
}

const UsersMenu: React.FC<UsersMenuProps> = ({ permissions }) => {
  if (permissions && permissions.indexOf('action_responsible') >= 0) {
    return <Redirect to="/entidad/usuarios" />;
  }
  if (permissions && permissions.indexOf('get_staff_users') >= 0) {
    return <Redirect to="/usuarios/staff" />;
  }
  if (permissions && permissions.indexOf('get_student_users') >= 0) {
    return <Redirect to="/usuarios/student" />;
  }
  if (permissions && permissions.indexOf('get_entities_users') >= 0) {
    return <Redirect to="/usuarios/entity" />;
  }
  if (permissions && permissions.indexOf('get_external_users') >= 0) {
    return <Redirect to="/usuarios/ext" />;
  }
  if (permissions && permissions.indexOf('get_academy_users') >= 0) {
    return <Redirect to="/usuarios/academy" />;
  }
  return <></>;
};

const mapStateToProps = (state: AppState) => ({
  permissions: selectPermissionsList(state),
});

export default connect(mapStateToProps, null)(UsersMenu);
