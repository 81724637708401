import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { tScholarshipKey } from '../../../../helpers/translate';
import { AppState } from '../../../../redux/root-reducer';
import ScholarshipPaymentsTable from './ScholarshipPaymentsTable/ScholarshipPaymentsTable';

import TransitionModal from '../../../../components/Modal/TransitionModal';
import { ScholarshipPaymentAdjustmentData } from '../../../../redux/scholarship/paymentAdjustments/PaymentAdjustment';
import { getScholarshipPaymentAdjustmentAction } from '../../../../redux/scholarship/paymentAdjustments/actions';
import {
  selectScholarshipAmount,
  selectScholarshipPaymentAdjustmentData,
  selectScholarshipTotalAdjustments,
  selectTotalScholarship,
} from '../../../../redux/scholarship/paymentAdjustments/selectors';
import ScholarshipAdjustmentsTable from './ScholarshipAdjustmentsTable/ScholarshipAdjustmentsTable';
import ScholarshipPaymentsAdjustmentTotals from './ScholarshipPaymentsAdjustmentTotals';
import ScholarshipPaymentsAdjustmentWidget from './ScholarshipPaymentsAdjustmentWidget';

interface ScholarshipPaymentAdjustmentModalProps {
  show: boolean;
  scholarshipId: number;
  entityId: number | null;
  studentName: string;
  paymentAdjustmentData: ScholarshipPaymentAdjustmentData;
  scholarshipAmount: number;
  totalAdjustments: number;
  totalScholarship: number;
  handleClose: () => void;
  getScholarshipPaymentData: (scholarshipId: number) => void;
}

const ScholarshipPaymentAdjustmentModal: React.FC<ScholarshipPaymentAdjustmentModalProps> = ({
  show,
  scholarshipId,
  entityId,
  studentName,
  paymentAdjustmentData,
  scholarshipAmount,
  totalAdjustments,
  totalScholarship,
  handleClose,
  getScholarshipPaymentData,
}) => {
  useEffect(() => {
    if (scholarshipId > 0) {
      getScholarshipPaymentData(scholarshipId);
    }
  }, [getScholarshipPaymentData, scholarshipId]);

  const { scholarship_adjustments } = paymentAdjustmentData;

  return (
    <TransitionModal
      view={show}
      handleClose={() => {
        handleClose();
      }}
      title={`${tScholarshipKey('Pagos beca')} ${studentName}`}
    >
      <>
        <ScholarshipPaymentsAdjustmentTotals
          scholarshipAmount={scholarshipAmount}
          totalAdjustments={totalAdjustments}
          totalScholarship={totalScholarship}
        />
        <ScholarshipPaymentsTable scholarshipId={scholarshipId} editable />
        <ScholarshipPaymentsAdjustmentWidget scholarshipId={scholarshipId} entityId={entityId} />
        <ScholarshipAdjustmentsTable scholarshipAdjustments={scholarship_adjustments} editable />
      </>
    </TransitionModal>
  );
};

const mapStateToProps = (state: AppState) => ({
  scholarshipAmount: selectScholarshipAmount(state),
  totalAdjustments: selectScholarshipTotalAdjustments(state),
  totalScholarship: selectTotalScholarship(state),
  paymentAdjustmentData: selectScholarshipPaymentAdjustmentData(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  getScholarshipPaymentData: (scholarshipId: number): void =>
    dispatch(getScholarshipPaymentAdjustmentAction(scholarshipId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScholarshipPaymentAdjustmentModal);
