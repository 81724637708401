import { UUID } from '../../../types/Shared/standard';
import PaymentStatus from '../../../types/Payments/PaymentStatus';
import { ScholarshipPaymentList, ScholarshipPaymentListItem, ScholarshipPaymentListSummary } from './definitions';

export type PaymentId = UUID;
export type ScholarshipPaymentId = UUID;
export type ScholarshipId = number;
export type PaymentType = 'Pago' | 'Ajuste';
export const justificationTypesArray = (): PaymentType[] => [
  'Matrícula',
  'Mensualidad',
  'Materiales',
  'Transporte',
  'Otros',
];

export type PaymentDatesReplace = {
  academic_year: string;
  is_entity: boolean;
  date_search: string;
  date_replace: string;
  entity_id: number | null;
};

export type EntityPaymentSendSageData = {
  account_number: string;
  academic_year: string;
  payment_date: string;
  payment_amount: number;
  to_pay_amount: number;
};

export const PaymentsSummaryInitialState = {
  next_payment_date: null,
  next_payment_amount: 0.0,
  total_paid: 0.0,
  total_pending: 0.0,
  remainder: 0.0,
};

export const ScholarshipPaymentsInitialState = {
  loading: false as boolean,
  reload: false as boolean,
  error: null as string | null,
  dataScholarshipPayment: {} as ScholarshipPaymentData,
  selectedEntityId: null as number | null,
  selectedAcademicYear: null as string | null,
  selectedPaymentStatus: PaymentStatus.PENDING as string,
  selectedPaymentDate: null as string | null,
  entityScholarshipPayments: {
    payments_summary: PaymentsSummaryInitialState as ScholarshipPaymentListSummary,
    payments: [] as ScholarshipPaymentListItem[],
  } as ScholarshipPaymentList,
  instituteScholarshipPayments: {
    payments_summary: PaymentsSummaryInitialState as ScholarshipPaymentListSummary,
    payments: [] as ScholarshipPaymentListItem[],
  } as ScholarshipPaymentList,
};
