import { IdTypeCodeNamePair } from '../../redux/common/definitions';
import { ScholarshipFutureActionsType } from '../../redux/scholarship/definitions';

class ScholarshipFutureActionCode {
  static SI_CONTINUA = 'SIC';
  static SI_TERMINA = 'SIF';
  static SI_Y_ABANDONA = 'SIA';
  static SI = 'SI';
  static NO_CONTINUA = 'NCO';
  static NO_Y_FALTAN_ASIGNATURAS = 'NFA';
  static NO_REPITE = 'NRE';
  static NO_Y_ABANDONA = 'NAB';

  constructor(private code: string) {}

  public value(): string {
    return this.code;
  }

  public isNoContinuaYAbandona() {
    return this.code === ScholarshipFutureActionCode.NO_Y_ABANDONA;
  }

  public static findCode(
    future_actions: IdTypeCodeNamePair<ScholarshipFutureActionsType>[],
    id?: number,
  ): ScholarshipFutureActionCode | undefined {
    if (!id) {
      return undefined;
    }
    const futureActionFound = future_actions.find(
      (futureAction: IdTypeCodeNamePair<ScholarshipFutureActionsType>) =>
        String(futureAction.id).trim() === String(id).trim(),
    );
    if (!futureActionFound) {
      return undefined;
    }
    return new ScholarshipFutureActionCode(futureActionFound.code);
  }
}

export default ScholarshipFutureActionCode;
