import assertUnreachable from '../../../helpers/assertUnreachable';
import { ScholarshipPaymentsConst } from './action_types';
import { ScholarshipPaymentDataState, ScholarshipPaymentListItem, ScholarshipPaymentsDataAction } from './definitions';

import { PaymentId, PaymentsSummaryInitialState, ScholarshipPaymentsInitialState } from './Payment.d';

const updateScholarshipPaymentsDate = (
  scholarshipPayments: ScholarshipPaymentListItem[],
  payment_id: PaymentId,
  payment_date: string,
): ScholarshipPaymentListItem[] => {
  return scholarshipPayments.map((payment: ScholarshipPaymentListItem) => {
    if (payment.payment_id === payment_id) {
      payment.payment_date = payment_date;
      return payment;
    }
    return payment;
  });
};

const scholarshipPaymentsReducer = (
  state = ScholarshipPaymentsInitialState,
  action: ScholarshipPaymentsDataAction,
): ScholarshipPaymentDataState => {
  switch (action.type) {
    case ScholarshipPaymentsConst.CHANGE_SCHOLARSHIP_PAYMENT_DATE:
    case ScholarshipPaymentsConst.REPLACE_SCHOLARSHIPS_PAYMENT_DATES:
    case ScholarshipPaymentsConst.ENTITY_SCHOLARSHIPS_PAYMENT_SEND_SAGE:
      return {
        ...state,
        error: null,
      };
    case ScholarshipPaymentsConst.SET_SCHOLARSHIPS_PAYMENTS_RELOAD:
      return {
        ...state,
        reload: true,
        error: null,
      };
    case ScholarshipPaymentsConst.GET_INSTITUTE_SCHOLARSHIP_PAYMENTS:
    case ScholarshipPaymentsConst.GET_ENTITY_SCHOLARSHIP_PAYMENTS:
      return {
        ...state,
        loading: true,
        error: null,
        reload: false,
        instituteScholarshipPayments: {
          ...state.instituteScholarshipPayments,
          payments_summary: PaymentsSummaryInitialState,
          payments: [],
        },
        entityScholarshipPayments: {
          ...state.entityScholarshipPayments,
          payments_summary: PaymentsSummaryInitialState,
          payments: [],
        },
      };
    case ScholarshipPaymentsConst.GET_INSTITUTE_SCHOLARSHIP_PAYMENTS_OK:
      return {
        ...state,
        loading: false,
        instituteScholarshipPayments: action.scholarshipPaymentList,
      };
    case ScholarshipPaymentsConst.GET_ENTITY_SCHOLARSHIP_PAYMENTS_OK:
      return {
        ...state,
        loading: false,
        entityScholarshipPayments: action.scholarshipPaymentList,
      };
    case ScholarshipPaymentsConst.SET_PAYMENTS_SELECTED_ACADEMIC_YEAR:
      return {
        ...state,
        selectedAcademicYear: action.academic_year,
      };
    case ScholarshipPaymentsConst.SET_PAYMENTS_SELECTED_STATUS:
      return {
        ...state,
        selectedPaymentStatus: action.payment_status,
      };
    case ScholarshipPaymentsConst.SET_PAYMENTS_PAYMENT_DATE:
      return {
        ...state,
        selectedPaymentDate: action.payment_date,
      };
    case ScholarshipPaymentsConst.SET_PAYMENTS_SELECTED_ENTITY_ID:
      return {
        ...state,
        selectedEntityId: action.entity_id,
      };
    case ScholarshipPaymentsConst.CHANGE_SCHOLARSHIP_PAYMENT_DATE_OK:
      const { payment_date, payment_id, is_entity } = action;
      const payments = is_entity
        ? state.entityScholarshipPayments.payments
        : state.instituteScholarshipPayments.payments;
      const paymentsUpdated = updateScholarshipPaymentsDate(payments, payment_id, payment_date);

      if (is_entity) {
        return {
          ...state,
          loading: false,
          entityScholarshipPayments: {
            ...state.entityScholarshipPayments,
            payments: paymentsUpdated,
          },
        };
      }
      return {
        ...state,
        loading: false,
        instituteScholarshipPayments: {
          ...state.instituteScholarshipPayments,
          payments: paymentsUpdated,
        },
      };
    case ScholarshipPaymentsConst.ENTITY_SCHOLARSHIPS_PAYMENT_SEND_SAGE_OK:
    case ScholarshipPaymentsConst.REPLACE_SCHOLARSHIPS_PAYMENT_DATES_OK:
      return {
        ...state,
        reload: true,
      };
    case ScholarshipPaymentsConst.GET_INSTITUTE_SCHOLARSHIP_PAYMENTS_KO:
    case ScholarshipPaymentsConst.GET_ENTITY_SCHOLARSHIP_PAYMENTS_KO:
    case ScholarshipPaymentsConst.CHANGE_SCHOLARSHIP_PAYMENT_DATE_KO:
    case ScholarshipPaymentsConst.REPLACE_SCHOLARSHIPS_PAYMENT_DATES_KO:
    case ScholarshipPaymentsConst.ENTITY_SCHOLARSHIPS_PAYMENT_SEND_SAGE_KO:
      return {
        ...state,
        loading: false,
        reload: false,
        error: action.error,
      };
    default:
      assertUnreachable(action);
      return state;
  }
};

export default scholarshipPaymentsReducer;
