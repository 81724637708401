import { MaterialTableProps } from 'material-table';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { getUsersByTypeFromApi } from '../../../../api/user';
import Submenu from '../../../../components/Layout/Menus/Submenu/Submenu';
import TableLayout from '../../../../components/Layout/TableLayout';
import TableWithEdit from '../../../../components/Tables/TableWithEdit';
import { permissionType } from '../../../../constants/permissions_definitions';
import { exportToExcel } from '../../../../helpers/exportToXslx';
import { tKey } from '../../../../helpers/translate';
import { selectIsNousCims } from '../../../../redux/auth/selectors';
import { showSnackbar } from '../../../../redux/FeedbackAPI/actions';
import { SeveritySnackbar } from '../../../../redux/FeedbackAPI/definitions';
import { selectPermissionsList } from '../../../../redux/permissions/selectors';
import { AppState } from '../../../../redux/root-reducer';
import { RoleGroups, UserItemList, UserType } from '../../../../redux/user/definitions';
import { userOptionsSubmenu } from '../userOptionsMenu';

interface UsersStudentProps extends RouteComponentProps<{ type?: UserType }> {
  permissions: permissionType[];
  isNousCims: boolean;
  showSnackbar: (message: string, severity: SeveritySnackbar, route?: string, time?: number) => void;
}

const UsersStudent: React.FC<UsersStudentProps> = ({ permissions, isNousCims, showSnackbar }) => {
  const usersType = 'student';
  const groupType = RoleGroups.JOVEN;
  const history = useHistory();

  const tableColumns = [
    { title: tKey('Nombre'), field: 'name', width: '200px' },
    { title: tKey('Apellidos'), field: 'surname', width: '260px' },
    { title: tKey('ip.rol'), field: 'role', width: '250px' },
    { title: tKey('Correo electrónico'), field: 'email', width: '300px' },
    { title: tKey('users.document_number'), field: 'document_number', width: '150px' },
    { title: tKey('Entidad'), field: 'entity', width: '300px' },
  ];

  const [table, setTable] = useState<MaterialTableProps<UserItemList>>({
    title: tKey('users.student'),
    columns: tableColumns,
    options: {
      tableLayout: 'fixed',
      pageSize: 100,
      pageSizeOptions: [20, 50, 100],
      exportButton: true,
      exportCsv: (columns, data) => {
        exportToExcel(columns, data, `Usuarios_Becados`);
      },
    },
    data: [],
  });

  const optionsSubmenu = userOptionsSubmenu({
    currentType: usersType,
    permissions,
    isNousCims,
  });

  useEffect(() => {
    (async function fetchData() {
      setTable(previousData => ({ ...previousData, isLoading: true }));
      try {
        const { data } = await getUsersByTypeFromApi(usersType);
        setTable(previousData => ({
          ...previousData,
          data,
          isLoading: false,
        }));
      } catch (e) {
        setTable(previousData => ({
          ...previousData,
          data: [],
          isLoading: false,
        }));
        showSnackbar(tKey('No se han podido traer los usuarios'), 'error');
      }
    })();
  }, [showSnackbar]);

  const onEditOne = (
    event: React.MouseEvent<Element, globalThis.MouseEvent>,
    rowData: UserItemList | UserItemList[],
  ) => {
    if (!Array.isArray(rowData) && rowData) {
      history.push({
        pathname: `/usuario/editar/${rowData.id}`,
        state: { id: rowData.id, type: groupType },
      });
    }
  };

  const details = (rowData: UserItemList) => {
    history.push({
      pathname: `/usuario/detalles/${rowData.id}`,
      state: { id: rowData.id, type: groupType },
    });
  };

  return (
    <>
      <TableLayout leftSubmenu={<Submenu titleMenu={tKey('Usuarios')} optionsMenu={optionsSubmenu} defaultOpen />}>
        <TableWithEdit
          tableData={table}
          exportButton
          filtering
          onRowClick={(rowData: UserItemList) => details(rowData)}
          key={JSON.stringify(table.data)}
          onEditOne={(event: React.MouseEvent, rowData: UserItemList | UserItemList[]) => onEditOne(event, rowData)}
        />
      </TableLayout>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  permissions: selectPermissionsList(state),
  isNousCims: selectIsNousCims(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  showSnackbar: (message: string, severity: SeveritySnackbar, route?: string, time?: number): void =>
    dispatch(showSnackbar(message, severity, route, time)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersStudent);
