import { Button, InputAdornment, MenuItem, TextField } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import FormContainer from '../../../components/Forms/FormContainer';
import TextInput from '../../../components/Inputs/TextInput';
import Title from '../../../components/Title/Title';
import * as DateConstants from '../../../constants/date';
import { dbDateToDate } from '../../../helpers/dateHelper';
import { tFormKey, tKey, tScholarshipKey } from '../../../helpers/translate';
import { IdNamePair } from '../../../redux/common/definitions';
import { selectAcademicYears } from '../../../redux/metadata/selectors';
import { AppState } from '../../../redux/root-reducer';
import {
  setAcademicYearAction,
  setPaymentDateAction,
  setPaymentEntityIdAction,
  setPaymentStatusAction,
} from '../../../redux/scholarship/payments/actions';
import { ScholarshipPaymentListSummary } from '../../../redux/scholarship/payments/definitions';
import {
  selectSelectedAcademicYear,
  selectSelectedEntityId,
  selectSelectedPaymentDate,
  selectSelectedPaymentStatus,
} from '../../../redux/scholarship/payments/selectors';
import PaymentStatus from '../../../types/Payments/PaymentStatus';
import useScholarshipPaymentReplaceDates from './hooks/useScholarshipPaymentReplaceDates';
import ReplaceDatesModal from './modals/ReplaceDatesModal';
import styles from './scholarshipsPaymentFilters.module.scss';
import { selectUserCan } from '../../../redux/permissions/selectors';
import moment from 'moment';

interface ScholarshipsPaymentFiltersProps {
  title?: string;
  loading: boolean;
  isEntity?: boolean;
  academicYearsList: string[];
  entities?: IdNamePair[];
  selectedEntityId?: number | null;
  scholarshipPaymentListSummary: ScholarshipPaymentListSummary;
  selectedAcademicYear: string;
  selectedStatus: string;
  selectedPaymentDate: string | null;
  allowScholarshipPayments: boolean;
  setSelectedEntityId: (entity_id: number | null) => void;
  setSelectedAcademicYear: (academic_year: string) => void;
  setSelectedStatus: (status: string) => void;
  setSelectedPaymentDate: (payment_date: string | null) => void;
}

const ScholarshipsPaymentFilters: React.FC<ScholarshipsPaymentFiltersProps> = ({
  title,
  loading,
  isEntity = false,
  entities,
  scholarshipPaymentListSummary,
  academicYearsList,
  selectedAcademicYear,
  selectedStatus,
  selectedPaymentDate,
  selectedEntityId,
  allowScholarshipPayments,
  setSelectedEntityId,
  setSelectedAcademicYear,
  setSelectedStatus,
  setSelectedPaymentDate,
}) => {
  const {
    total_paid,
    total_pending,
    next_payment_amount,
    next_payment_date,
    remainder,
  } = scholarshipPaymentListSummary;
  const {
    replaceModal,
    replaceDates,
    openReplaceModal,
    closeReplaceModal,
    setFindDate,
    setReplaceDate,
  } = useScholarshipPaymentReplaceDates();

  const [paymentDate, setPaymentDate] = useState<moment.Moment | null>(
    selectedPaymentDate ? moment.utc(selectedPaymentDate, DateConstants.DATE_FORMAT_DB) : null,
  );

  const onChangeEntitySelect = (entity_id: string) => {
    setSelectedEntityId(entity_id !== '0' ? +entity_id : null);
  };

  const onChangeStatusSelect = (status: string) => {
    setSelectedStatus(status);
  };

  const onChangeAcademciYearSelect = (academic_year: string) => {
    setSelectedAcademicYear(academic_year);
  };

  const onFilterPaymentDate = () => {
    setSelectedPaymentDate(paymentDate ? paymentDate.format(DateConstants.DATE_FORMAT_DB) : null);
  };

  const renderTitle = (): JSX.Element | undefined => {
    if (!title) {
      return undefined;
    }
    return <Title>{title}</Title>;
  };
  const renderAcademicYearSelector = (): JSX.Element => {
    const label = tFormKey('Curso académico');
    return (
      <TextField
        name={'academic_year'}
        label={label}
        className={styles.academic_year_selector}
        value={selectedAcademicYear}
        onChange={e => onChangeAcademciYearSelect(e.target.value as string)}
        variant="outlined"
        select
      >
        {academicYearsList.map(element => (
          <MenuItem key={element} value={element}>
            {element}
          </MenuItem>
        ))}
      </TextField>
    );
  };

  const renderPaymentStatusSelector = (): JSX.Element => {
    const label = tFormKey('Estado del pago');
    return (
      <TextField
        className={styles.status_selector}
        name={'payment_status'}
        label={label}
        value={selectedStatus ? selectedStatus : PaymentStatus.EMPTY_STATUS}
        onChange={e => onChangeStatusSelect(e.target.value as string)}
        variant="outlined"
        select
      >
        {PaymentStatus.toArray().map((element: string) => (
          <MenuItem key={element} value={element}>
            {tKey(PaymentStatus.translationName(element))}
          </MenuItem>
        ))}
      </TextField>
    );
  };

  const renderFilterSelectors = (): JSX.Element => {
    return (
      <div className={styles.filter_selectors}>
        {renderAcademicYearSelector()}
        {renderPaymentStatusSelector()}
      </div>
    );
  };
  const renderTotalPaid = (): JSX.Element => {
    const name = `total_paid${loading ? 'loading' : ''}`;
    return (
      <TextInput
        name={name}
        type="number"
        disabled
        fullWidth
        label={tFormKey('Total pagado')}
        value={total_paid}
        InputProps={{
          endAdornment: <InputAdornment position="end">€</InputAdornment>,
        }}
      />
    );
  };

  const renderTotalPending = (): JSX.Element => {
    const name = `total_pending${loading ? 'loading' : ''}`;
    return (
      <TextInput
        type="number"
        name={name}
        disabled
        fullWidth
        label={tFormKey('Total pendiente')}
        value={total_pending}
        InputProps={{
          endAdornment: <InputAdornment position="end">€</InputAdornment>,
        }}
      />
    );
  };

  const entitiesMenuItems = (entitiesArray: IdNamePair[]): JSX.Element[] => {
    const allEntities = {
      id: 0,
      name: tKey('Todas'),
    } as IdNamePair;
    const menuItems = [allEntities, ...entitiesArray];
    return menuItems.map(element => (
      <MenuItem key={element.id} value={element.id}>
        {element.name}
      </MenuItem>
    ));
  };

  const renderEntitySelector = (): JSX.Element => {
    if (!isEntity || !entities || !entities.length) {
      return <div className={styles.entity_selector}></div>;
    }
    return (
      <div className={styles.entity_selector}>
        <TextField
          label={tFormKey('Seleccionar entidad')}
          onChange={e => onChangeEntitySelect(e.target.value)}
          value={selectedEntityId || '0'}
          className={styles.select_entidades}
          variant="outlined"
          fullWidth
          select
        >
          {entitiesMenuItems(entities)}
        </TextField>
      </div>
    );
  };

  const renderNextPaymentAmount = (): JSX.Element => {
    const name = `next_payment_amount${loading ? 'loading' : ''}`;
    return (
      <TextInput
        name={name}
        type="number"
        disabled
        fullWidth
        label={tFormKey('Próximo pago')}
        value={next_payment_amount}
        InputProps={{
          endAdornment: <InputAdornment position="end">€</InputAdornment>,
        }}
      />
    );
  };

  const renderNextPaymentDate = (): JSX.Element => {
    const next_payment_date_formatted = next_payment_date ? dbDateToDate(next_payment_date) : ' ';
    const name = `next_payment_date${loading ? 'loading' : ''}`;
    return (
      <TextInput
        name={name}
        style={{ textAlign: 'end' }}
        disabled
        fullWidth
        label={tScholarshipKey('Fecha próximo pago')}
        value={next_payment_date_formatted}
      />
    );
  };

  const renderRemainder = (): JSX.Element | undefined => {
    if (!isEntity) {
      return undefined;
    }
    const name = `remainder${loading ? 'loading' : ''}`;
    return (
      <TextInput
        className={styles.summary_remainder}
        name={name}
        type="number"
        disabled
        fullWidth
        label={tFormKey('Remanente entidad')}
        value={remainder}
        InputProps={{
          endAdornment: <InputAdornment position="end">€</InputAdornment>,
        }}
      />
    );
  };

  const renderSearchAndReplace = (): JSX.Element => {
    return (
      <div className={styles.search_replace_container}>
        <KeyboardDatePicker
          name="find_date"
          label={tFormKey('Buscar')}
          format={DateConstants.DATE_FORMAT}
          variant="inline"
          inputVariant="outlined"
          placeholder="" // Prevent google to detect this input as a credit card number
          helperText={replaceDates.find_error}
          value={replaceDates.find}
          className={styles.datePickers}
          onChange={(date: MaterialUiPickersDate) => {
            setFindDate(date);
          }}
          autoOk
        />
        <KeyboardDatePicker
          name="replace_date"
          label={tFormKey('Remplazar por')}
          format={DateConstants.DATE_FORMAT}
          variant="inline"
          inputVariant="outlined"
          placeholder="" // Prevent google to detect this input as a credit card number
          helperText={replaceDates.replace_error}
          value={replaceDates.replace}
          className={styles.datePickers}
          onChange={(date: MaterialUiPickersDate) => {
            setReplaceDate(date);
          }}
          autoOk
        />
        <Button
          className={styles.buttonReplace}
          color="primary"
          variant="contained"
          onClick={() => openReplaceModal()}
          disabled={
            !replaceDates.find || !replaceDates.replace || !!replaceDates.find_error || !!replaceDates.replace_error
          }
        >
          {tKey('Reemplazar')}
        </Button>
      </div>
    );
  };

  const renderDateFilter = (): JSX.Element => {
    return (
      <div className={styles.search_replace_container}>
        <KeyboardDatePicker
          name="payment_date"
          label={tFormKey('Fecha de pago')}
          format={DateConstants.DATE_FORMAT}
          variant="inline"
          inputVariant="outlined"
          placeholder="" // Prevent google to detect this input as a credit card number
          helperText={replaceDates.find_error}
          value={paymentDate}
          className={styles.datePickers}
          onChange={(date: MaterialUiPickersDate) => {
            setPaymentDate(date);
          }}
          autoOk
        />
        <Button
          className={styles.buttonReplace}
          color="primary"
          variant="contained"
          onClick={() => onFilterPaymentDate()}
        >
          {tKey('Filtrar')}
        </Button>
      </div>
    );
  };

  return (
    <>
      <FormContainer className={styles.form_payments} title="" lowMargin>
        {renderTitle()}
        <div className={styles.filter_grid}>
          {renderFilterSelectors()}
          {allowScholarshipPayments && renderTotalPaid()}
          {allowScholarshipPayments && renderTotalPending()}
          {renderEntitySelector()}
          {allowScholarshipPayments && renderNextPaymentAmount()}
          {allowScholarshipPayments && renderNextPaymentDate()}
          {allowScholarshipPayments && renderRemainder()}
        </div>
        {allowScholarshipPayments && renderSearchAndReplace()}
        {allowScholarshipPayments && renderDateFilter()}
      </FormContainer>
      <ReplaceDatesModal
        is_entity={isEntity}
        entity_id={selectedEntityId}
        show={replaceModal}
        replaceDates={replaceDates}
        closeReplaceModal={closeReplaceModal}
      />
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  academicYearsList: selectAcademicYears(state),
  selectedAcademicYear: selectSelectedAcademicYear(state),
  selectedStatus: selectSelectedPaymentStatus(state),
  selectedPaymentDate: selectSelectedPaymentDate(state),
  selectedEntityId: selectSelectedEntityId(state),
  allowScholarshipPayments: selectUserCan(state)('allow_scholarship_payments'),
});

const mapDispatchToProps = (dispatch: any) => ({
  setSelectedEntityId: (entity_id: number | null): void => dispatch(setPaymentEntityIdAction(entity_id)),
  setSelectedAcademicYear: (academic_year: string): void => dispatch(setAcademicYearAction(academic_year)),
  setSelectedStatus: (status: string): void => dispatch(setPaymentStatusAction(status)),
  setSelectedPaymentDate: (payment_date: string | null): void => dispatch(setPaymentDateAction(payment_date)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScholarshipsPaymentFilters);
