import { MenuItem } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import FormContainer from '../../../../components/Forms/FormContainer';
import TextInputController from '../../../../components/Inputs/TextInputController/TextInputController';
import RadioButtonGroup from '../../../../components/RadioButtons/RadioButtonGroup';
import { yesNoOptions } from '../../../../components/RadioButtons/RadioButtonOptions';
import SelectController from '../../../../components/Select/SelectController';
import FncAddDocuments from '../../../../components/UploadFile/FncAddDocuments';
import { startMonth, startYear, workdayType } from '../../../../constants/laboralImpactConstants';
import { tScholarshipKey } from '../../../../helpers/translate';
import { DocumentationItem } from '../../../../redux/common/definitions';
import { GenericMetadata } from '../../../../redux/metadata/definitions';
import { ScholarshipData, ScholarshipFollow, ScholarshipMetadata } from '../../../../redux/scholarship/definitions';
import FollowUpType from '../../../../types/Scholarships/followUpType';
import { schoolCurrentWorkingSituation } from '../../../../validations/scholarshipSchema';
import styles from './followForm.module.scss';

interface LaboralImpactProps {
  scholarshipFollow: ScholarshipFollow;
  scholarshipData: ScholarshipData;
  scholarshipMetadata: ScholarshipMetadata;
  genericMetadata: GenericMetadata;
  documentationWork: DocumentationItem[];
  scholarshipType: string;
  readOnly?: boolean;
  errors: any;
  control: ReturnType<typeof useForm>['control'];
  register?: ReturnType<typeof useForm>['register'];
  setDataFollow: (subKey: string, value: string | number | null) => void;
  deleteDocument?: (documentation_id: number, scholarship_id: number) => void;
  downloadDocument?: (documentation_id: number, name: string, scholarship_id: number) => void;
  getFile?: (documentation_id: number, name: string, scholarship_id: number) => void;
  upload: (documentation_id: number, data: File, scholarshipId: number) => void;
  addDoc: (document: File, description: string, genericId?: number, documentation_id?: number) => void;
}

const LaboralImpact = ({
  readOnly,
  scholarshipData,
  scholarshipFollow,
  genericMetadata,
  scholarshipType,
  documentationWork,
  errors,
  control,
  addDoc,
  upload,
  register,
  setDataFollow,
  downloadDocument,
  getFile,
  deleteDocument,
}: LaboralImpactProps): ReactElement => {
  const {
    has_quaterly_internship,
    has_work_quaterly,
    has_work_relation_study,
    company_name,
    employee_role,
    current_incomes,
    contract_type,
    working_start_month,
    working_start_year,
    workday_type,
  } = scholarshipFollow;

  const followUpType = new FollowUpType(scholarshipData.follow_up_type);

  const { incomesTypes, contractTypes } = genericMetadata;

  const labelTranslation = (field: string): string => {
    return tScholarshipKey(`follow_up.${scholarshipType}.${followUpType.value().toLowerCase()}.${field}`);
  };

  const renderDocumentsInfo = () => {
    if (has_work_quaterly) {
      const actions = ['checked', 'upload', 'download', 'view', 'remove'];
      return (
        <FncAddDocuments
          title={tScholarshipKey('Documentos')}
          documents={documentationWork}
          getFile={getFile}
          actions={actions}
          disabled={readOnly}
          upload={upload}
          genericId={scholarshipData.id}
          download={downloadDocument}
          remove={deleteDocument}
          multidocument
          addDoc={addDoc}
          required
        />
      );
    }
    return undefined;
  };

  const renderWorkDetails = () => {
    if (has_work_quaterly) {
      return (
        <div className={styles.container}>
          <TextInputController
            control={control}
            errors={errors}
            defaultValue={company_name}
            schema={schoolCurrentWorkingSituation}
            label={tScholarshipKey('follow_up.company_name')}
            name="company_name"
            disabled={readOnly}
            onBlur={e => {
              setDataFollow('company_name', e.target.value);
            }}
          />

          <TextInputController
            control={control}
            errors={errors}
            defaultValue={employee_role}
            schema={schoolCurrentWorkingSituation}
            label={tScholarshipKey(`follow_up.${scholarshipType}.employee_role`)}
            name="employee_role"
            disabled={readOnly}
            onBlur={e => {
              setDataFollow('employee_role', e.target.value);
            }}
          />

          <SelectController
            control={control}
            name="contract_type"
            label={tScholarshipKey('follow_up.contract_type')}
            defaultValue={contract_type}
            schema={schoolCurrentWorkingSituation}
            errors={errors}
            disabled={readOnly}
            onClick={(e: React.MouseEvent<HTMLInputElement>) =>
              setDataFollow('contract_type', (e.target as HTMLInputElement).value)
            }
          >
            {contractTypes.map((element: string, index: number) => (
              <MenuItem key={`${element}_${index}`} value={element}>
                {tScholarshipKey(element)}
              </MenuItem>
            ))}
          </SelectController>
          <SelectController
            control={control}
            name="workday_type"
            label={tScholarshipKey('follow_up.workday_type')}
            defaultValue={workday_type || scholarshipData.workday_type}
            schema={schoolCurrentWorkingSituation}
            errors={errors}
            disabled={readOnly}
            onClick={(e: React.MouseEvent<HTMLInputElement>) =>
              setDataFollow('workday_type', (e.target as HTMLInputElement).value)
            }
          >
            {workdayType.map((element: string, index: number) => (
              <MenuItem key={`${element}_${index}`} value={element}>
                {tScholarshipKey(element)}
              </MenuItem>
            ))}
          </SelectController>
          <div className={styles.containerWorkingStart}>
            <SelectController
              control={control}
              name="working_start_month"
              size="50"
              label={tScholarshipKey('follow_up.working_start_month')}
              defaultValue={working_start_month}
              schema={schoolCurrentWorkingSituation}
              errors={errors}
              disabled={readOnly}
              onClick={(e: React.MouseEvent<HTMLInputElement>) =>
                setDataFollow('working_start_month', (e.target as HTMLInputElement).value)
              }
            >
              {startMonth.map((element: string, index: number) => (
                <MenuItem key={`${element}_${index}`} value={element}>
                  {element}
                </MenuItem>
              ))}
            </SelectController>
            <SelectController
              control={control}
              name="working_start_year"
              size="50"
              label={tScholarshipKey('follow_up.working_start_year')}
              defaultValue={working_start_year}
              schema={schoolCurrentWorkingSituation}
              errors={errors}
              disabled={readOnly}
              onClick={(e: React.MouseEvent<HTMLInputElement>) =>
                setDataFollow('working_start_year', (e.target as HTMLInputElement).value)
              }
            >
              {startYear().map((element: string, index: number) => (
                <MenuItem key={`${element}_${index}`} value={element}>
                  {element}
                </MenuItem>
              ))}
            </SelectController>
          </div>
          <SelectController
            control={control}
            name="current_incomes"
            label={tScholarshipKey('follow_up.current_incomes')}
            defaultValue={current_incomes || scholarshipData.current_incomes}
            schema={schoolCurrentWorkingSituation}
            errors={errors}
            disabled={readOnly}
            onClick={(e: React.MouseEvent<HTMLInputElement>) =>
              setDataFollow('current_incomes', (e.target as HTMLInputElement).value)
            }
          >
            {incomesTypes.map((element: string, index: number) => (
              <MenuItem key={`${element}_${index}`} value={element}>
                {tScholarshipKey(element)}
              </MenuItem>
            ))}
          </SelectController>
        </div>
      );
    }
    return undefined;
  };

  if (has_work_quaterly === 0 && company_name) {
    setDataFollow('company_name', '');
    setDataFollow('employee_role', '');
    setDataFollow('contract_type', '');
    setDataFollow('workday_type', '');
    setDataFollow('working_start_month', '');
    setDataFollow('working_start_year', '');
    setDataFollow('current_incomes', '');
  }

  return (
    <FormContainer space title={tScholarshipKey('Impacto laboral')}>
      <div className={styles.containerRadioButtons}>
        <RadioButtonGroup
          name="has_quaterly_internship"
          errors={errors.has_quaterly_internship}
          disabled={readOnly}
          questionText={labelTranslation('has_quaterly_internship')}
          options={yesNoOptions}
          value={has_quaterly_internship?.toString() || '0'}
          register={register}
          onChange={e => {
            setDataFollow('has_quaterly_internship', +e.target.value);
          }}
        />
        <RadioButtonGroup
          name="has_work_relation_study"
          errors={errors.has_work_relation_study}
          disabled={readOnly}
          questionText={tScholarshipKey(`follow_up.${scholarshipType}.has_work_relation_study`)}
          options={yesNoOptions}
          value={has_work_relation_study?.toString() || '0'}
          register={register}
          onChange={e => {
            setDataFollow('has_work_relation_study', +e.target.value);
          }}
        />
        <RadioButtonGroup
          name="has_work_quaterly"
          errors={errors.has_work_quaterly}
          disabled={readOnly}
          questionText={labelTranslation('has_work_quaterly')}
          options={yesNoOptions}
          value={has_work_quaterly?.toString() || '0'}
          register={register}
          onChange={e => {
            setDataFollow('has_work_quaterly', +e.target.value);
          }}
        />
      </div>
      {renderWorkDetails()}
      {renderDocumentsInfo()}
    </FormContainer>
  );
};

export default LaboralImpact;
