import { ButtonProps } from '@material-ui/core';
import { Delete, Save, Search } from '@material-ui/icons';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import * as yup from 'yup';
import FormContainer from '../../../../components/Forms/FormContainer';
import LabeledTextInput from '../../../../components/Inputs/LabeledTextInput/LabeledTextInput';
import TextInput from '../../../../components/Inputs/TextInput';
import TextInputController from '../../../../components/Inputs/TextInputController/TextInputController';
import LayoutForm from '../../../../components/Layout/LayoutForm';
import ActionsMenu from '../../../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import Submenu from '../../../../components/Layout/Menus/Submenu/Submenu';
import Title from '../../../../components/Title/Title';
import { permissionType } from '../../../../constants/permissions_definitions';
import { tErrorKey, tFormKey, tKey } from '../../../../helpers/translate';
import { selectIsNousCims } from '../../../../redux/auth/selectors';
import { selectPermissionsList } from '../../../../redux/permissions/selectors';
import { AppState } from '../../../../redux/root-reducer';
import { SearchUserData } from '../../../../redux/user/definitions';
import { userOptionsSubmenu } from '../userOptionsMenu';
import ButtonSearch from './ButtonSearch';
import styles from './searchUserForm.module.scss';
import useSearchUser from './useSearchUser';
import { dbDateToDate } from '../../../../helpers/dateHelper';

interface SearchUsersFormProps {
  permissions: permissionType[];
  isNousCims: boolean;
}

const searchUserSchema = yup.object().shape({
  email: yup
    .string()
    .required(tErrorKey('La dirección de correo es obligatoria'))
    .email(tErrorKey('Debe ser una dirección de correo válida')),
});

const SearchUserForm: React.FC<SearchUsersFormProps> = ({ permissions, isNousCims }) => {
  const optionsSubmenu = userOptionsSubmenu({
    currentType: 'search',
    permissions,
    isNousCims,
  });

  const [searchEmail, setSearchEmail] = useState<string | undefined>(undefined);

  const {
    userData: { id, name, surname, surname2, email, entity, active_role, state, created_at },
    loading,
    message,
    findUser,
    updateUser,
    deleteUser,
  } = useSearchUser();

  const { handleSubmit, clearError, errors, control } = useForm<SearchUserData>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: searchUserSchema,
  });

  const onSearchEmail = () => {
    if (searchEmail) {
      findUser(searchEmail);
    }
  };

  const onUpdate = (data: SearchUserData) => {
    clearError();
    updateUser({ ...data, id });
  };

  const onRemove = () => {
    deleteUser(id);
  };

  const buttonActions: ButtonProps[] = [
    {
      children: tKey('Actualizar'),
      onClick: handleSubmit(onUpdate),
      startIcon: <Save />,
      disableElevation: true,
      variant: 'contained',
      color: 'primary',
      fullWidth: true,
      disabled: !email,
    },
    {
      children: tKey('Eliminar'),
      onClick: onRemove,
      startIcon: <Delete />,
      disableElevation: true,
      variant: 'contained',
      color: 'primary',
      fullWidth: true,
      disabled: !email || id === 0,
    },
  ];

  const surnames = (): string => {
    if (!surname && !surname2) {
      return '';
    }
    return `${surname || ''} ${surname2 || ''}`;
  };

  const createdAt = (): string => {
    if (!created_at) {
      return '';
    }
    return dbDateToDate(created_at);
  };

  const renderUserData = (): JSX.Element => {
    return (
      <section className={styles.section}>
        <LabeledTextInput value={name || ''} name="name" label={tFormKey('users.search.name')} size={'50'} disabled />
        <LabeledTextInput
          value={surnames()}
          name="surname"
          label={tFormKey('users.search.surnames')}
          size={'50'}
          disabled
        />
        <LabeledTextInput
          value={active_role || ''}
          name="active_role"
          label={tFormKey('users.search.active_role')}
          size={'50'}
          disabled
        />
        <LabeledTextInput
          value={entity || ''}
          name="entity"
          label={tFormKey('users.search.organization')}
          size={'50'}
          disabled
        />
        <LabeledTextInput
          value={state || ''}
          name="state"
          label={tFormKey('users.search.state')}
          size={'50'}
          disabled
        />
        <LabeledTextInput
          name="created_at"
          value={createdAt()}
          label={tFormKey('users.search.created_at')}
          size={'50'}
          disabled
        />
      </section>
    );
  };

  return (
    <LayoutForm
      leftSubmenu={<Submenu titleMenu={tKey('Usuarios')} optionsMenu={optionsSubmenu} defaultOpen />}
      rightSubmenu={<ActionsMenu actionsButtons={buttonActions} />}
    >
      <Title>{tFormKey('Buscar usuario')}</Title>
      <FormContainer className={styles.form_container} title="">
        <section className={styles.searchSection}>
          <TextInput
            className={styles.searchEmail}
            defaultValue={searchEmail}
            name="search_email"
            label={tFormKey('users.search.email')}
            onChange={e => setSearchEmail(e.target.value)}
          />
          <ButtonSearch color="primary" startIcon={<Search />} variant="contained" onClick={() => onSearchEmail()}>
            {tKey('Buscar')}
          </ButtonSearch>
        </section>
        <p className={styles.message}>{message}</p>
        {renderUserData()}
      </FormContainer>
      <FormContainer className={styles.form_container} title={tFormKey('users.search.update_mail')}>
        {!loading ? (
          <TextInputController
            control={control}
            errors={errors}
            defaultValue={email}
            schema={searchUserSchema}
            label={tFormKey('users.search.new_mail')}
            name="email"
            size="50"
          />
        ) : (
          undefined
        )}
      </FormContainer>
    </LayoutForm>
  );
};

const mapStateToProps = (state: AppState) => ({
  permissions: selectPermissionsList(state),
  isNousCims: selectIsNousCims(state),
});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SearchUserForm);
