import { MaterialTableProps } from 'material-table';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { Edit, PersonAdd } from '@material-ui/icons';
import BlockIcon from '@material-ui/icons/Block';
import { getUsersByTypeFromApi } from '../../../../api/user';
import Submenu from '../../../../components/Layout/Menus/Submenu/Submenu';
import TableLayout from '../../../../components/Layout/TableLayout';
import TableWithEdit from '../../../../components/Tables/TableWithEdit';
import { permissionType } from '../../../../constants/permissions_definitions';
import { exportToExcel } from '../../../../helpers/exportToXslx';
import { tKey } from '../../../../helpers/translate';
import { selectIsNousCims } from '../../../../redux/auth/selectors';
import { showSnackbar } from '../../../../redux/FeedbackAPI/actions';
import { SeveritySnackbar } from '../../../../redux/FeedbackAPI/definitions';
import { selectPermissionsList } from '../../../../redux/permissions/selectors';
import { AppState } from '../../../../redux/root-reducer';
import { RoleGroups, UserItemList, UserType } from '../../../../redux/user/definitions';
import { userOptionsSubmenu } from '../userOptionsMenu';
import { UserStates } from '../../../../redux/common/definitions';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { disableUserAction, enableUserAction } from '../../../../redux/user/actions';
import colorPalette from '../../../../helpers/colorPalette';
import { FormControlLabel, Switch } from '@material-ui/core';
import FormContainer from '../../../../components/Forms/FormContainer';
import styles from './usersList.module.scss';
import { selectUsersListReload } from '../../../../redux/user/selectors';

interface UsersExternalProps extends RouteComponentProps<{ type?: UserType }> {
  permissions: permissionType[];
  isNousCims: boolean;
  reload: boolean;
  disableUser: (id: number) => void;
  enableUser: (id: number) => void;
  showSnackbar: (message: string, severity: SeveritySnackbar, route?: string, time?: number) => void;
}

const actionIcons = {
  BlockIcon: () => <BlockIcon style={{ color: colorPalette.secondary80 }} />,
  Edit: () => <Edit style={{ color: colorPalette.secondary80 }} />,
  EnableIcon: () => <PersonAdd style={{ color: colorPalette.secondary80 }} />,
};

const UsersExternal: React.FC<UsersExternalProps> = ({
  permissions,
  isNousCims,
  reload,
  disableUser,
  enableUser,
  showSnackbar,
}) => {
  const usersType = 'ext';
  const groupType = RoleGroups.EXTERNOS;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [showDisabled, setShowDisabled] = useState(false);
  const [userListData, setUserListData] = useState<UserItemList[]>([]);

  const onEditOne = (rowData: UserItemList | UserItemList[]) => {
    if (!Array.isArray(rowData) && rowData) {
      history.push({
        pathname: `/usuario/editar/${rowData.id}`,
        state: { id: rowData.id, type: groupType },
      });
    }
  };

  const onDisableOne = (rowData: UserItemList) => {
    if (!Array.isArray(rowData) && rowData) {
      disableUser(rowData.id);
    }
  };

  const onEnableUser = (rowData: UserItemList) => {
    enableUser(rowData.id);
  };

  const details = (rowData: UserItemList) => {
    history.push({
      pathname: `/usuario/detalles/${rowData.id}`,
      state: { id: rowData.id, type: groupType },
    });
  };

  const selectDisabledCellCSS = (rowData: UserItemList): CSSProperties => {
    if (rowData.state === UserStates.DISABLED) {
      return {
        color: '#00000074',
      } as CSSProperties;
    }
    return {} as CSSProperties;
  };

  const tableColumns = [
    {
      title: tKey('Nombre'),
      field: 'name',
      width: '200px',
      cellStyle: (data: UserItemList[], rowData: UserItemList) => selectDisabledCellCSS(rowData),
    },
    {
      title: tKey('Apellidos'),
      field: 'surname',
      cellStyle: (data: UserItemList[], rowData: UserItemList) => selectDisabledCellCSS(rowData),
    },
    {
      title: tKey('ip.rol'),
      field: 'role',
      width: '250px',
      cellStyle: (data: UserItemList[], rowData: UserItemList) => selectDisabledCellCSS(rowData),
    },
    {
      title: tKey('Correo electrónico'),
      field: 'email',
      cellStyle: (data: UserItemList[], rowData: UserItemList) => selectDisabledCellCSS(rowData),
    },
    {
      title: tKey('users.document_number'),
      field: 'document_number',
      width: '150px',
      cellStyle: (data: UserItemList[], rowData: UserItemList) => selectDisabledCellCSS(rowData),
    },
  ];

  const externalUsersList = (): UserItemList[] => {
    if (showDisabled) {
      return userListData;
    }
    return userListData.filter((userItemList: UserItemList) => userItemList.state !== UserStates.DISABLED);
  };

  const tableData: MaterialTableProps<UserItemList> = {
    title: tKey('users.external'),
    columns: tableColumns,
    options: {
      tableLayout: 'auto',
      pageSize: 100,
      pageSizeOptions: [20, 50, 100],
      exportButton: true,
      exportCsv: (columns, data) => {
        exportToExcel(columns, data, `Usuarios_Externos`);
      },
    },
    data: externalUsersList(),
    isLoading: loading,
    actions: [
      (rowData: UserItemList) => ({
        icon: actionIcons.Edit,
        tooltip: tKey('Editar'),
        onClick: () => onEditOne(rowData),
        hidden: rowData.state === UserStates.DISABLED,
      }),
      (rowData: UserItemList) =>
        rowData.state === UserStates.DISABLED
          ? {
              icon: actionIcons.EnableIcon,
              tooltip: tKey('Habilitar'),
              onClick: () => onEnableUser(rowData),
            }
          : {
              icon: actionIcons.BlockIcon,
              tooltip: tKey('Deshabilitar'),
              onClick: () => onDisableOne(rowData),
            },
    ],
  };

  const optionsSubmenu = userOptionsSubmenu({
    currentType: usersType,
    permissions,
    isNousCims,
  });

  useEffect(() => {
    if (reload) {
      (async function fetchData() {
        setLoading(true);
        setUserListData([]);
        try {
          const { data } = await getUsersByTypeFromApi(usersType);
          console.log(data);
          setUserListData(data);
        } catch (e) {
          showSnackbar(tKey('No se han podido traer los usuarios'), 'error');
        }
        setLoading(false);
      })();
    }
  }, [reload, showSnackbar]);

  return (
    <TableLayout leftSubmenu={<Submenu titleMenu={tKey('Usuarios')} optionsMenu={optionsSubmenu} defaultOpen />}>
      <FormContainer title="" className={styles.switch}>
        <FormControlLabel
          control={
            <Switch checked={showDisabled} onChange={event => setShowDisabled(event.target.checked)} color="primary" />
          }
          labelPlacement="start"
          label={'Mostrar deshabilitados'}
        />
      </FormContainer>
      <FormContainer title="" className={styles.usersTable}>
        <TableWithEdit
          tableData={tableData}
          exportButton
          filtering
          onRowClick={(rowData: UserItemList) => details(rowData)}
          key={JSON.stringify(tableData.data)}
        />
      </FormContainer>
    </TableLayout>
  );
};

const mapStateToProps = (state: AppState) => ({
  permissions: selectPermissionsList(state),
  isNousCims: selectIsNousCims(state),
  reload: selectUsersListReload(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  disableUser: (id: number): void => dispatch(disableUserAction(id)),
  enableUser: (id: number): void => dispatch(enableUserAction(id)),
  showSnackbar: (message: string, severity: SeveritySnackbar, route?: string, time?: number): void =>
    dispatch(showSnackbar(message, severity, route, time)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersExternal);
